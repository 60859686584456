import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { AuthService } from '../services';
import { map } from 'rxjs/operators';
import { IResult } from '../interfaces';

export const validationResetPasswordGuard = (next: ActivatedRouteSnapshot): Observable<boolean> => {
    const router = inject(Router);
    const authService = inject(AuthService);
    const uidb64: string | null = next.paramMap.get('uidb64');
    const token: string | null = next.paramMap.get('token');

    return authService.checkNewPasswordToken(uidb64!, token!)
        .pipe(
            map((result: IResult) => {
                if (result.isSuccess) {
                    sessionStorage.setItem('newPasswordParams', JSON.stringify({ uidb64, token }));
                    router.navigate(['/password/new']);

                    return true;
                }

                router.navigate(['/auth/login']);

                return false;
            }),
        );
};
