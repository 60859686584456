<div class="content">
    <div class="d-flex justify-content-between">
        <h4>Користувачі</h4>
    </div>
    <div class="users-container mt-2">
        <div class="filter">
            @if (userAdditional) {
                <ng-select class="select-container" bindLabel="name" #statusSelect
                           [items]="userAdditional.statuses"
                           [placeholder]="'Статус'"
                           [clearable]="false"
                           [(ngModel)]="selectedStatus"
                           [bindLabel]="'value'"
                           [bindValue]="'key'"
                           (change)="onStatusSelect()">
                    <ng-template ng-header-tmp>
                        <div class="ng-option-name">
                            <div class="ng-option-name">
                                <button class="btn-transparent w-100 py-0 px-1" (click)="statusSelect.close(); resetStatus()">
                                    Скинути
                                </button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        <div class="ng-option-name">
                            {{ item.value }}
                        </div>
                    </ng-template>
                </ng-select>
                <ng-select class="select-container" bindLabel="name" #levelSelect
                           [items]="userAdditional.levels"
                           [placeholder]="'Рівень'"
                           [clearable]="false"
                           [(ngModel)]="selectedLevel"
                           [bindLabel]="'value'"
                           [bindValue]="'key'"
                           (change)="onLevelSelect()">
                    <ng-template ng-header-tmp>
                        <div class="ng-option-name">
                            <div class="ng-option-name">
                                <button class="btn-transparent w-100 py-0 px-1" (click)="levelSelect.close(); resetLevel()">
                                    Скинути
                                </button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        <div class="ng-option-name">
                            {{ item.value }}
                        </div>
                    </ng-template>
                </ng-select>
                <app-search-input [minLength]="2" (onSearch)="fetchSearchData($event)"></app-search-input>
                <div class="d-flex">
                    @if (roleId) {
                        <div class="invite-block">
                            <div class="btn-group" ngbDropdown #dropdown="ngbDropdown" (openChange)="toggleRoleUsersDropdown($event)">
                                <button class="btn-default"
                                        data-bs-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false" ngbDropdownToggle>
                                    <i class="las la-plus la-1x"></i> Додати користувача
                                </button>
                                <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                                    <div class="users-wrapper">
                                        <app-search-input (onSearch)="fetchSearchRoleData($event)"
                                                          [borderNone]="true" [minLength]="2"
                                        ></app-search-input>

                                        <div class="user-list mt-2" [class.mt-2]="roleUsers().length > 0">
                                            @for (user of roleUsers(); track user.id) {
                                                <a class="dropdown-item" (click)="onAddedUserToRole.emit(user.id)">
                                                    <img src="assets/images/user-dummy-img.jpg" alt="avatar">
                                                    <span>{{ user.username }}</span>
                                                </a>
                                            }
                                        </div>

                                        @if (roleUsers().length === 0) {
                                            <div class="no-users">Користувачі не знайдені</div>
                                        }

                                        <a class="dropdown-item add" (click)="toggleModal('invite-user', 'bg', { projectId, roleId })">
                                            <i class="las la-plus-circle la-lg"></i> Запросити користувачів
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    } @else {
                        <button class="btn-default" (click)="toggleModal('invite-user', 'bg')">
                            <i class="las la-plus la-1x"></i>Запросити користувача
                        </button>
                    }
                </div>
            }
        </div>

        <div class="table-responsive mt-4">
            <table class="table settings-table">
                <thead>
                <tr [ngClass]="{ 'selected': checkedUserIds().length > 0 }">
                    <th [colSpan]="checkedUserIds().length > 0 ? 7 : 1">
                        <div class="toggled-all-block">
                            <input class="form-check-input head-checkbox" type="checkbox" value="" id="all-users"
                                   (change)="toggleAll()" [(ngModel)]="isToggledAllState">
                            <label class="form-check-label" for="all-users"></label>

                            @if (checkedUserIds().length > 0 && !roleId) {
                                <div>
                                    <button class="btn transparent text-decoration-underline py-0"
                                            (click)="toggleModal('change-user-access', 'bg', { actionType: 'block' })">
                                        Заблокувати користувачів
                                    </button>
                                    <button class="btn transparent text-decoration-underline py-0"
                                            (click)="toggleModal('change-user-access', 'bg', { actionType: 'delete' })">
                                        Видалити користувачів
                                    </button>
                                    <button class="btn transparent text-decoration-underline py-0"
                                            (click)="toggleModal('change-user-access', 'bg', { actionType: 'unblock' })">
                                        Розблокувати користувачів
                                    </button>
                                </div>
                            }

                            @if (checkedUserIds().length > 0 && roleId) {
                                <div>
                                    <button class="btn transparent text-decoration-underline py-0"
                                            (click)="toggleModal('confirm-delete-user-role', 'bg', { ids: checkedUserIds() })">
                                        Видалити користувачів з ролі
                                    </button>
                                </div>
                            }
                        </div>
                    </th>
                    @if (checkedUserIds().length === 0) {
                        <th>Користувач</th>
                        <th>Email</th>
                        <th>Рівень</th>
                        <th>Час останнього входу</th>
                        <th>Статус</th>
                        <th class="text-center w-1">Дії</th>
                    }
                </tr>
                </thead>
                <tbody>
                    @for (user of users(); track user.id) {
                        <tr [ngClass]="{ 'selected': checkedUserIds().includes(user.id) }">
                            <td>
                                <div class="form-check form-check-outline form-check-info">
                                    <input class="form-check-input" type="checkbox"
                                           (change)="toggleUserCheckbox(user.id)"
                                           [checked]="checkedUserIds().includes(user.id)" id="user-{{ user.id }}">
                                    <label class="form-check-label" for="user-{{ user.id }}"></label>
                                </div>
                            </td>
                            <td>
                                <div class="d-flex">
                                    <img [src]="user.avatar" alt="avatar" class="avatar-xs rounded-circle"/>
                                    <b class="p-2">{{ user.username }}</b>
                                </div>
                            </td>
                            <td>
                                @if (user.email) {
                                    <a class="email" [copyToClipboard]="user.email">
                                        {{ user.email }} <i class='bx bx-copy'></i>
                                    </a>
                                }
                            </td>
                            <td (mouseleave)="dropdown.close()">
                                <div class="level">
                                    @if (user.isAdmin) {
                                        {{ userAdditional.levelsMap['is_admin'].value }}
                                    } @else {
                                        {{ userAdditional.levelsMap['is_user'].value }}
                                    }

                                    <div class="btn-group" ngbDropdown #dropdown="ngbDropdown">
                                        @if (!roleId) {
                                            <button type="button" class="btn d-flex" data-bs-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false" ngbDropdownToggle>
                                                <i class='bx bx-pencil'></i>
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-start" ngbDropdownMenu>
                                                <a class="dropdown-item" [class.selected]="user.isAdmin"
                                                   (click)="toggleModal('confirm-change-role', 'md', { user, newLevel: 'is_admin' })"
                                                >
                                                    {{ userAdditional.levelsMap['is_admin'].value }}
                                                </a>
                                                <a class="dropdown-item" [class.selected]="!user.isAdmin"
                                                   (click)="toggleModal('confirm-change-role', 'md', { user, newLevel: 'is_user' })"
                                                >
                                                    {{ userAdditional.levelsMap['is_user'].value }}
                                                </a>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </td>
                            <td>{{ user.lastLogin ?? 'Ніколи' }}</td>
                            <td>
                                @if (!user.isBlocked && !user.isActive) {
                                    <span class="badge bg-warning">Неактивний</span>
                                } @else if (user.isBlocked) {
                                    <span class="badge bg-danger">Заблокований</span>
                                } @else {
                                    <span class="badge bg-success">Активний</span>
                                }
                            </td>
                            <td class="text-center w-1">
                                @if (roleId) {
                                    <button class="btn btn-transparent" (click)="toggleModal('confirm-delete-user-role', 'bg', { username: user.username, ids: [user.id] })">
                                        <i class='bx bx-trash-alt fs-20'></i>
                                    </button>
                                } @else {
                                    <div class="btn-group" ngbDropdown>
                                        <button type="button" class="btn d-flex"
                                                data-bs-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false" ngbDropdownToggle>
                                            <i class="bx bx-dots-vertical-rounded fs-22 d-flex align-items-center"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                                            <a class="dropdown-item" [routerLink]="['/system-settings/users', user.id]">Редагувати</a>
                                            @if (!user.isBlocked) {
                                                <a class="dropdown-item" (click)="toggleUserCheckbox(user.id); toggleModal('change-user-access', 'bg', { actionType: 'block' })">
                                                    Заблокувати
                                                </a>
                                            } @else {
                                                <a class="dropdown-item" (click)="toggleUserCheckbox(user.id); toggleModal('change-user-access', 'bg', { actionType: 'unblock' })">
                                                    Розблокувати
                                                </a>
                                            }

                                            <a class="dropdown-item" (click)="toggleUserCheckbox(user.id); toggleModal('change-user-access', 'bg', { actionType: 'delete' })">
                                                Видалити
                                            </a>
                                        </div>
                                    </div>
                                }
                            </td>
                        </tr>
                    }

                    @if (users().length === 0) {
                        <tr>
                            <td colspan="7" class="text-center">Немає даних</td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>

        @if (users().length > 0) {
            <div class="row justify-content-md-between align-items-md-center">
                <div class="row justify-content-md-between align-items-md-center">
                    <app-paginator [totalSize]="totalSize" [data]="users" (onFetchData)="fetchPageData($event)">
                    </app-paginator>
                </div>
            </div>
        }
    </div>
</div>
