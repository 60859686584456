import { IDashboard, IDashboardAiStats, IDashboardFilterStats, IDashboardUserStats } from '../../interfaces';

export class DashboardModel implements IDashboard {
    private readonly _totalProcessed: number;
    private readonly _aiStats: IDashboardAiStats;
    private readonly _filterStats: IDashboardFilterStats[];
    private readonly _userStats: IDashboardUserStats[];

    constructor(data: any) {
        this._totalProcessed = data['total_processed'] ?? null;
        this._filterStats = data['filter_stats'] ?? null;

        this._aiStats = {
            notVerifiedCount: data['ai_stats']?.['not_verified_count'],
            verifiedCount: data['ai_stats']?.['verified_count']
        };

        this._userStats = data['user_stats']?.map((item: any) => ({
            typeName: item['type_name'],
            processedPostsCount: item['processed_posts_count'],
        })) ?? null;

    }

    get totalProcessed() {
        return this._totalProcessed;
    }

    get filterStats() {
        return this._filterStats;
    }

    get aiStats() {
        return this._aiStats;
    }

    get userStats() {
        return this._userStats;
    }
}
