import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IReport, IResult } from 'src/app/interfaces';
import { HttpService } from '../http/http.service';
import { DataListModel, ReportModel } from '../../models';

@Injectable({
    providedIn: 'root'
})
export class ReportsService {
    readonly reportCoreName = 'reports/';
    readonly createReportName = 'create/';
    readonly deleteReportName = 'delete/';
    readonly updateReportName = 'update/';

    constructor(
        private http: HttpService
    ) { }

    public getReports<T>(page: number, page_size: number, date_from?: string, date_to?: string ,  q?: string): Observable<IResult<T>> {
        return this.http.request('GET', `${this.reportCoreName}`, { page, page_size, date_from, date_to, q }, this.getReportsModel);
    }

    private getReportsModel(data: object): DataListModel<IReport> {
        return new DataListModel<IReport>(data, ReportModel);
    }

    public createRule(data: any): Observable<IResult> {
        return this.http.request('POST', `${this.reportCoreName}${this.createReportName}`, data);
    }

    public deleteRule(id: number): Observable<IResult> {
        return this.http.request('POST', `${this.reportCoreName}${id}/${this.deleteReportName}`, { });
    }

    public updateRule(id: number, data: any): Observable<IResult> {
        return this.http.request('POST', `${this.reportCoreName}${id}/${this.updateReportName}`, data);
    }
}
