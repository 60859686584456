<div #lightGalleryContainer style="display: none;">
    @for (item of media; track $index) {
        @if(item.fileType === 'video') {
            <a [attr.data-video]='item.url | buildVideoJson' [attr.data-download-url]="item.url">
                <img [src]="item.thumbUrl" />
            </a>
        } @else {
            <a [href]="item.url">
                <img [src]="item.thumbUrl ?? item.url" />
            </a>
        }
    }
</div>
