import { ISourceKeywordCategory } from '../../interfaces';

export class KeywordCategoryModel implements ISourceKeywordCategory {
    private readonly _id: number;
    private readonly _name: string;
    private readonly _total: number;
    private readonly _boardIds: number[];

    constructor(data: { value: any }) {
        this._id = data['value']['id'] ?? null;
        this._name = data['value']['name'] ?? null;
        this._total = data['value']['word_count'] ?? null;
        this._total = data['value']['word_count'] ?? null;
        this._boardIds = data['value']['board_ids'] ?? null;
    }

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    get total() {
        return this._total;
    }

    get boardIds() {
        return this._boardIds;
    }
}
