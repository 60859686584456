import {
    IBreadcrumb, IBreadcrumbs,
    IGeoAdditional,
    IGeoSuggestion,
    IGeoSuggestionDetails,
} from '../../interfaces';
import { GeoAdditionalModel } from './geo-additional.model';
import { GeoSuggestionModel } from './geo-suggestion.model';
import { DataListModel } from '../general/data-list.model';
import { BreadcrumbModel } from '../general/breadcrumb.model';

export class GeoSuggestionDetailsModel implements IGeoSuggestionDetails {
    private readonly _details: IGeoSuggestion;
    private readonly _additional: IGeoAdditional;
    private readonly _breadcrumbs: IBreadcrumb[];

    constructor(data: any) {
        const additionalData = {
            teams: [data.team],
            users: data.team.users,
            statuses: [data.status]
        };

        this._additional = new GeoAdditionalModel(additionalData);
        this._details = new GeoSuggestionModel({ value: data.details, additional: this._additional });
        this._breadcrumbs = new DataListModel<IBreadcrumb>({ results: data['breadcrumbs'] }, BreadcrumbModel).data;
    }
    get details() {
        return this._details;
    }

    get additional() {
        return this._additional;
    }

    get breadcrumbs() {
        return this._breadcrumbs;
    }
}
