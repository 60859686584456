import { IProjectRole } from '../../interfaces';

export class ProjectRoleModel implements IProjectRole {
    private readonly _id: number;
    private readonly _name: string;
    private readonly _textColor: string;
    private readonly _backgroundColor: string;
    private readonly _userCount: number;

    constructor(data: { value: any }) {
        this._id = data['value']['id'] ?? null;
        this._name = data['value']['name'] ?? null;
        this._textColor = data['value']['text_color'] ?? null;
        this._backgroundColor = data['value']['background_color'] ?? null;
        this._userCount = data['value']['user_count'] ?? null;
    }

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    get textColor() {
        return this._textColor;
    }

    get backgroundColor() {
        return this._backgroundColor;
    }

    get userCount() {
        return this._userCount;
    }
}
