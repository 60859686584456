import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, effect, EventEmitter,
    Input,
    OnInit,
    Output,
    WritableSignal
} from '@angular/core';
import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormArray, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastsService } from '../../services';
import { IDragSortableItem } from '../../interfaces';

@Component({
    selector: 'app-drag-sortable-list',
    templateUrl: './drag-sortable-list.component.html',
    styleUrls: ['./drag-sortable-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        CdkDrag,
        CdkDragHandle,
        CdkDropList,
        FormsModule,
        ReactiveFormsModule,
    ],
})
export class DragSortableListComponent implements OnInit {
    @Input() public items!: WritableSignal<any[]>;
    @Input() public isFocusOutSubmit: boolean = false;
    @Input() public minElements: number = 1;

    @Output() onSubmit: EventEmitter<number> = new EventEmitter<number>();
    @Output() onSort: EventEmitter<number[]> = new EventEmitter<number[]>();
    @Output() onError: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onDelete: EventEmitter<{ id: number }> = new EventEmitter<{ id: number }>();

    public formArray!: FormArray<FormControl>;

    constructor(
        private cd: ChangeDetectorRef,
        private toastsService: ToastsService,
    ) {
        effect(() => {
            this.formArray = new FormArray<FormControl>(
                this.items().map((item: any) => new FormControl(item['name'], Validators.required))
            );

            const hasInvalidControl = this.formArray.controls.some(control => control.invalid);

            this.onError.emit(hasInvalidControl || this.items().length < this.minElements);

            this.cd.detectChanges();
        })
    }

    ngOnInit(): void {
        if (this.items().length === 0) {
            this.addItem();
        }
    }

    public drop(event: CdkDragDrop<any[]>) {
        moveItemInArray(this.items(), event.previousIndex, event.currentIndex);
        this.syncFormArray();
        this.onSort.emit(this.items().map((item: any) => item['id']));
    }

    public addItem() {
        this.items.update((items: any[]) => [...items, { name: '' }]);
        this.onError.emit(true);
    }

    public removeItem(index: number) {
        this.items.update((items: any[]) => items.filter((item: any, i: number) => i !== index));
    }

    public removeFocus(event: Event) {
        (event.target as HTMLInputElement).blur();
    }

    public submitChange(index: number) {
        const existItem = this.items().find((item: any, i: number) =>
            item['name'] !== '' && item['name'] === this.formArray.controls[index].value && i !== index
        );

        if (existItem) {
            this.toastsService.show('Така назва вже існує', { classname: 'bg-danger' });
            this.onError.emit(true);
            return;
        }

        this.items.update((values: IDragSortableItem[]) => {
            values[index].name = this.formArray.controls[index].value;
            return values;
        });

        const hasInvalidControl = this.formArray.controls.some(control => control.invalid);

        if (this.formArray.controls[index].invalid || hasInvalidControl) {
            this.formArray.controls.some(control => control.markAsTouched());
            this.toastsService.show('Введіть коректну назву', { classname: 'bg-secondary' });
            this.onError.emit(true);
            return;
        }

        this.onSubmit.emit(index);
    }

    private syncFormArray() {
        this.formArray.clear();
        this.items().forEach((item: any) => this.formArray.push(new FormControl(item['name'], Validators.required)));
    }
}
