import { Directive, ElementRef, EventEmitter, Output, OnDestroy } from '@angular/core';

@Directive({
    selector: '[visible]',
    standalone: true
})
export class VisibleDirective implements OnDestroy {
    @Output() fullyVisible = new EventEmitter<void>();

    private observer: IntersectionObserver;

    constructor(private el: ElementRef) {
        this.observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && entry.intersectionRatio === 1) {
                    this.fullyVisible.emit();
                }
            },
            {
                threshold: [1]
            }
        );

        this.observer.observe(this.el.nativeElement);
    }

    ngOnDestroy(): void {
        this.observer.disconnect();
    }
}
