import { ITaskCommentFile, ITaskNewComment } from '../interfaces';

export class NewCommentDto {
    static adapt(data: ITaskNewComment): any {
        const payload: any = {
            author_id: data.authorId,
            content: data.content,
            is_initial: data.isInitial,
            files: data.files.map((file: ITaskCommentFile) => ({
                download_name: file.downloadName,
                file: file.file
            })),
        };

        if (data.parentId) {
            payload.parent_post_id = data.parentId;
        } else {
            payload.is_initial = true;
        }

        if (data.hasOwnProperty('persistedFileIds')) {
            payload['file_ids'] = data.persistedFileIds;
        }

        return payload;
    }
}
