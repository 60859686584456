import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component, DestroyRef,
    effect, ElementRef, EventEmitter, inject,
    Input,
    OnInit, Output,
    QueryList,
    signal, ViewChild,
    ViewChildren
} from '@angular/core';
import { FilterArrayPipe } from '../../pipe';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchInputComponent } from '../search-input/search-input.component';
import { IProjectTag, IResult } from '../../interfaces';
import { TagComponent } from './tag/tag.component';
import { first, Subject } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ManageTagsModalComponent } from './manage-tags-modal/manage-tags-modal.component';
import { ProjectsService } from '../../services';
import { Utils } from '../../helpers';

export interface ITagAction {
    id: number;
    type:  'unselect' | 'select' | 'delete' | 'update';
    name?: string;
}

@Component({
    selector: 'app-tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        FilterArrayPipe,
        NgbDropdown,
        NgbDropdownMenu,
        NgbDropdownToggle,
        ReactiveFormsModule,
        SearchInputComponent,
        FormsModule,
        TagComponent,
    ],
})
export class TagsComponent implements OnInit, AfterViewInit {
    @Input() public projectId!: number;
    @Input() public taskId!: number;
    @Input() public selectedTags: IProjectTag[] = [];
    @Input() public tags: IProjectTag[] = [];
    @Input() public triggerCloseDropdowns$!: Subject<boolean>;

    @Output() onUpdate: EventEmitter<number[]> = new EventEmitter<number[]>();

    @ViewChildren(NgbDropdown) dropdowns!: QueryList<NgbDropdown>;
    @ViewChild('searchInput') public set searchEl(el: ElementRef<HTMLInputElement>) {
        /*if (el) {
            el.nativeElement.focus();
        }*/
    };

    public searchText = signal<string>('');
    public showCreateTagBtn = false;
    public selectedTagIds: number[] = [];

    private destroy = inject(DestroyRef);

    constructor(
        private modalService: NgbModal,
        private projectService: ProjectsService,
    ) {
        effect(() => {
            this.showCreateTagBtn = this.searchText().length > 0 &&
                !Boolean(this.tags.find((item: IProjectTag) => item.name === this.searchText()));
        });
    }

    ngOnInit(): void {
        this.selectedTagIds = Utils.pluck(this.selectedTags, 'id');

        if (this.triggerCloseDropdowns$) {
            this.triggerCloseDropdowns$
                .pipe(takeUntilDestroyed(this.destroy))
                .subscribe(() => {
                    this.closeAllDropdowns();
                });
        }
    }

    ngAfterViewInit() {

    }

    public selectTag(valueId: number): void {
        console.log('selectTag', valueId)
    }

    public clear(): void {
        this.searchText.set('');
    }

    public toggleDropdown(): void {
        this.searchText.set('');
    }

    public toggleModal(): void {
        const modalRef = this.modalService.open(ManageTagsModalComponent, {centered: true, size: 'md'});

        modalRef.componentInstance.projectId = this.projectId;

        return;
    }

    public closeAllDropdowns() {
        this.dropdowns.forEach((dropdown) => {
            if (dropdown.isOpen()) {
                dropdown.close();
            }
        });
    }

    public createTag(name: string): void {
        if (!this.showCreateTagBtn) return;

        this.projectService.createTag(this.projectId, name)
            .pipe(takeUntilDestroyed(this.destroy))
            .subscribe((result: IResult) => {
                if (result.isSuccess) {
                    this.onUpdate.emit([...this.selectedTagIds, result.data.id]);
                }
            });

        this.clear();
        this.closeAllDropdowns();
    }

    public tagAction(action: ITagAction): void {
        if (action.type === 'select') {
            this.clear();
            this.closeAllDropdowns();
            this.onUpdate.emit([...this.selectedTagIds, action.id]);

        } else if (action.type === 'unselect') {
            const tagIds = this.selectedTagIds.filter((id: number) => id !== action.id);
            this.onUpdate.emit(tagIds);
        } else if (action.type === 'update') {
            this.projectService.updateTag(this.projectId, action.id, action.name!)
                .pipe(first())
                .subscribe((result: IResult) => {
                    if (result.isSuccess) {
                    }
                });
        } else if (action.type === 'delete') {
            this.projectService.deleteTag(this.projectId, action.id)
                .pipe(first())
                .subscribe((result: IResult) => {
                    if (result.isSuccess) {
                    }
                });
        }
    }
}
