export class CustomFieldOptionDto {
    static adapt(data: any): any {
        const payload: any = {};

        if (data.hasOwnProperty('showInBoard')) {
            payload['show_in_board'] = data['showInBoard'];
        }

        if (data.hasOwnProperty('showInTaskDetail')) {
            payload['show_in_task_detail'] = data['showInTaskDetail'];
        }

        if (data['projectOptionId']) {
            payload['option_id'] = data['projectOptionId'];
        }

        return payload;
    }
}
