import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[clickOutside]',
    standalone: true,
})
export class ClickOutsideDirective {
    @Output() onClickOutside: EventEmitter<Event> = new EventEmitter<Event>();

    constructor(private elementRef: ElementRef) {}

    @HostListener('document:click', ['$event'])
    public onClick(event: Event): void {
        const clickedInside = this.elementRef.nativeElement.contains(event.target);

        if (!clickedInside) {
            this.onClickOutside.emit(event);
        }
    }
}
