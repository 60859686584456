import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { filter, map, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState, selectUserIsAdmin } from '../store';
import { Utils } from '../helpers';

export const defaultRouteRedirectGuard = (): Observable<boolean> => {
    const store = inject(Store<AppState>);
    const router = inject(Router);

    return store.select(selectUserIsAdmin)
        .pipe(
            filter((isAdmin): isAdmin is boolean => !Utils.isNil(isAdmin)),
            map((isAdmin) => {
                if (isAdmin) {
                    router.navigate(['/dashboard']);
                } else {
                    router.navigate(['/project']);
                }

                return false;
            })
        );
};
