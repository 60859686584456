@if (this.searchIn()) {
    <form class="app-search d-none d-md-block" clickOutside (onClickOutside)="hideResults()">
        <div class="search-input-block">
            <input type="text" class="form-control" placeholder="Пошук..." name="search-text" autocomplete="off"
                    id="search-options" [(ngModel)]="searchText" (input)="search()" (focus)="onFocusSearch()"
                   (keyup)="onKeyUp($event)" (keydown)="onKeyDown($event)">
            <span class="mdi mdi-magnify search-widget-icon"></span>
            <span class="mdi mdi-close-circle search-widget-icon-close" id="search-close-options"
                  [ngClass]="{'d-none': !searchText()}" (click)="clear()"></span>
        </div>

        <div class="dropdown-menu dropdown-menu-lg" [ngClass]="{ 'show': showResults() }" id="search-dropdown">
            <div class="search-results-block custom-scrollbar">

                @if (results().length > 0) {
                    <div class="dropdown-header">
                        <h6 class="text-overflow text-muted mb-0 text-uppercase">
                            @if (searchIn() === 'board') {
                                Знайдені задачі
                            } @else if (searchIn() === 'project') {
                                Знайдені дошки
                            }
                        </h6>
                    </div>

                    <div class="notification-list">
                        @if (searchIn() === 'board') {
                            @for (task of results(); track $index) {
                                <a [routerLink]="['/task', task.id]" class="dropdown-item task-item py-2"
                                   [class.active]="$index === activeIndex()">
                                    @if (task!.media?.[0]) {
                                        <img [src]="task!.media?.[0].thumbUrl" alt="user-pic">
                                    }

                                    <div class="flex-grow-1 task-details">
                                        <h6 class="m-0">{{ task.title }}</h6>
                                        <span class="fs-12 mb-0 text-muted">{{ task.description | stripHtml }}</span>
                                    </div>
                                </a>
                            }
                        } @else if (searchIn() === 'project') {
                            @for (board of results(); track $index) {
                                <a [routerLink]="['/board', board.id]" class="dropdown-item board-item py-2"
                                   [class.active]="$index === activeIndex()">
                                    <img [src]="board.image" alt="user-pic">

                                    <div class="flex-grow-1 board-details">
                                        <h6 class="m-0">{{ board.name }}</h6>
                                        <div>
                                            <span class="badge" [ngStyle]="{ 'backgroundColor': board.roleColor }">{{ board.roleName }}</span>
                                        </div>
                                    </div>
                                </a>
                            }
                        }
                    </div>
                } @else {
                    <div class="text-center pt-3 pb-1">
                        <h6>
                            Нічого не знайдено
                        </h6>
                    </div>
                }
            </div>

            @if (results().length > 0) {
                <div class="show-all text-center pt-3 pb-1">
                    @if (searchIn() === 'board') {
                        <button type="submit" [routerLink]="this.searchParams['board_id'] ?
                        ['/board', this.searchParams['board_id']] : ['/board']" [queryParams]="{ q: searchText() }"
                           class="btn btn-primary btn-sm" (click)="hideResults()">
                            Дивитись результати <i class="ri-arrow-right-line ms-1"></i>
                        </button>
                    } @else if (searchIn() === 'project') {
                        <button type="submit" [routerLink]="['/project', this.searchParams['project_id']]" [queryParams]="{ q: searchText() }"
                           class="btn btn-primary btn-sm" (click)="hideResults()">
                            Дивитись результати <i class="ri-arrow-right-line ms-1"></i>
                        </button>
                    }
                </div>
            }
        </div>
    </form>
}
