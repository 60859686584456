import { NgClass, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef, EventEmitter,
    inject, Input,
    OnInit, Output, Signal, signal,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    FormsModule,
    ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { ICustomField, ICustomFieldType, ICustomFieldValue, IDragSortableItem, IResult } from '../../interfaces';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ProjectsService, ValidationLayerService } from '../../services';
import { TagsComponent } from '../tags/tags.component';
import { DragSortableListComponent } from '../drag-sortable-list/drag-sortable-list.component';
import { DragSortableListModel } from '../../models';

@Component({
    selector: 'app-manage-custom-field-modal',
    templateUrl: './manage-custom-field-modal.component.html',
    styleUrls: ['./manage-custom-field-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        TranslateModule,
        RouterLink,
        NgClass,
        NgSelectModule,
        TagsComponent,
        DragSortableListComponent,
    ]
})
export class ManageCustomFieldModalComponent implements OnInit {
    @Input() public data!: { projectId: number, fieldTypes: Signal<ICustomFieldType[]>, boardId: number | undefined , field?: ICustomField };
    @Output() public onUpdate: EventEmitter<boolean> = new EventEmitter();

    public fieldsRequiringValues = ['select', 'multiselect', 'radio'];
    public validationLayer = inject(ValidationLayerService);
    public fieldForm = new UntypedFormGroup({
        name: new UntypedFormControl(null, Validators.required),
        type: new UntypedFormControl(null, Validators.required),
        boardId: new UntypedFormControl(null),
    });
    public valuesIsInvalid: boolean = false;
    public dragSortableList = signal<IDragSortableItem[]>([]);
    public loadingBtn = signal<boolean>(false);

    private destroy = inject(DestroyRef);

    constructor (
        public activeModal: NgbActiveModal,
        private cd: ChangeDetectorRef,
        private projectsService: ProjectsService,
    ) { }

    ngOnInit(): void {
        if (this.data.field) {
            this.fieldForm.patchValue({
                name: this.data.field.name,
                type: this.data.field.fieldType.key,
            });

            this.updateValuesControl(this.data.field.values)
        } else {
            this.fieldForm.controls['boardId'].setValue(this.data.boardId);
        }

        this.cd.detectChanges();
    }

    get f() {
        return this.fieldForm.controls;
    }

    public updateValuesControl(values?: ICustomFieldValue[]): void {
        if (values) {
            this.dragSortableList.set(new DragSortableListModel(values, 'value').data);
        }

        this.valuesIsInvalid = false;
        this.fieldForm.removeControl('values');
        this.fieldForm.removeControl('dateType');
        this.fieldForm.removeControl('isDecimalNumber');

        if (this.fieldsRequiringValues.includes(this.fieldForm.controls['type'].value)) {
            this.fieldForm.addControl('values', new UntypedFormControl(this.dragSortableList(), this.validationLayer.get('atLeastTwoItemRequired')));
        } else if (this.fieldForm.controls['type'].value === 'date') {
            this.fieldForm.addControl('dateType', new UntypedFormControl('single'));
        } else if (this.fieldForm.controls['type'].value === 'number') {
            this.fieldForm.addControl('isDecimalNumber', new UntypedFormControl(null));
        }
    }

    public submitValuesChange(index: number): void {
        this.valuesIsInvalid = false;

        this.setFieldValues(this.dragSortableList());

        this.cd.detectChanges();
    }

    public deleteValue(data: { id: number }): void {

    }

    public onSubmit() {
        this.loadingBtn.set(true);

        if (this.data.field?.id) {
            this.projectsService.updateCustomField(this.data.projectId, this.data.field!.id, this.fieldForm.value)
                .pipe(takeUntilDestroyed(this.destroy))
                .subscribe((result: IResult) => {
                    if (result.isSuccess) {
                        this.onUpdate.emit(true);
                        this.activeModal.close();
                    }

                    this.loadingBtn.set(false);
                });
        } else {
            this.projectsService.createCustomField(this.data.projectId, this.fieldForm.value)
                .pipe(takeUntilDestroyed(this.destroy))
                .subscribe((result: IResult) => {
                    if (result.isSuccess) {
                        this.onUpdate.emit(true);
                        this.activeModal.close();
                    }

                    this.loadingBtn.set(false);
                });
        }
    }

    private setFieldValues(items: IDragSortableItem[]): void {
        this.fieldForm.controls['values'].setValue(items);
    }
}
