import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResult } from 'src/app/interfaces';
import { AuthTokenModel } from 'src/app/models';
import { HttpService } from '../http/http.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    readonly loginName = 'account/token/';
    readonly logoutName = 'account/logout/';
    readonly twoFaConfirmName = 'account/token/2fa-confirm/';
    readonly registrationName = 'client/registration';
    readonly resetPasswordName = 'account/reset-password/';
    readonly newPasswordName = 'account/new-password/';
    readonly newPasswordConfirmName = 'account/new-password-confirm/';

    constructor(
        private http: HttpService
    ) { }

    public login<T>(username: string, password: string): Observable<IResult<T>> {
        return this.http.request('POST', this.loginName, { username, password }, this.authTokenCreateModel);
    }

    private authTokenCreateModel(data: object): AuthTokenModel {
        return new AuthTokenModel(data);
    }

    public logout(): Observable<IResult> {
        return this.http.request('POST', this.logoutName, { });
    }

    public twoFaConfirm<T>(otpCode: string, twoFaToken: string): Observable<IResult<T>> {
        return this.http.request('POST', this.twoFaConfirmName, { otp_code: otpCode, '2fa_token': twoFaToken }, this.authTokenCreateModel);
    }

    public resetPassword(email: string): Observable<IResult> {
        return this.http.request('POST', this.resetPasswordName, { email });
    }

    public checkNewPasswordToken(uuid: string, token: string): Observable<IResult> {
        return this.http.request('POST', `${this.newPasswordName}${uuid}/${token}/`, { });
    }

    public newPasswordConfirm(uidb64: string, token: string, newPassword: string): Observable<IResult> {
        return this.http.request('POST', `${this.newPasswordConfirmName}`, { uidb64, token, new_password: newPassword });
    }

    public registration(data: string): Observable<IResult> {
        return this.http.request('POST', this.registrationName, { data }, this.authTokenCreateModel);
    }
}
