import { IBoardPreview } from '../../interfaces';

export class BoardPreviewModel implements IBoardPreview {
    private readonly _id: number;
    private readonly _name: string;
    private readonly _isFavorite: boolean;

    constructor(data: { value: any }) {
        this._id = data['value']['id'] ?? null;
        this._name = data['value']['name'] ?? null;
        this._isFavorite = data['value']['is_favorite'] ?? null;
    }

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    get isFavorite() {
        return this._isFavorite;
    }
}
