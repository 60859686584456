import { IUserUpdateRequest } from '../interfaces';

export class UserDto {
    static adapt(data: IUserUpdateRequest): any {
        const payload: any = {
            first_name: data.firstName,
            last_name: data.lastName,
            public_name: data.publicName,
            messenger: data.messenger,
            phone: data.phone,
            is_active: data.isActive,
            is_blocked: data.isBlocked,
            is_deleted: data.isDeleted,
            is_admin: data.isAdmin,
            password: data.password,
        };

        if (data.newAvatar) {
            payload['avatar_base64'] = data.newAvatar;
        }

        return payload;
    }
}
