import { enableProdMode, importProvidersFrom } from '@angular/core';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { MetaReducer, provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { provideRouter } from '@angular/router';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { APP_ROUTES } from './app/app.routes';
import { JWTInterceptor } from './app/services/http/http-interceptor';
import { WEBSOCKET_CONFIG } from './app/websoket';
import { IConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { clearStateOnLogout, effects, reducers } from './app/store';
import { NavigationService } from './app/services/navigation/navigation.service';

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

if (environment.production) {
  enableProdMode();
}

const maskConfig: Partial<IConfig> = {
  validation: false,
};

const metaReducers: MetaReducer[] = [clearStateOnLogout];

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(APP_ROUTES),
    importProvidersFrom(
        TranslateModule.forRoot({
          defaultLanguage: 'ua',
          loader: {
            provide: TranslateLoader,
            useFactory: (createTranslateLoader),
            deps: [HttpClient]
          }
        }),
    ),
    BrowserAnimationsModule,
    BrowserModule,
    NavigationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JWTInterceptor,
      multi: true
    },
    {
      provide: WEBSOCKET_CONFIG,
      useValue: {
        url: environment.ws.url,
        reconnectInterval: environment.ws.reconnectInterval,
        reconnectAttempts: environment.ws.reconnectAttempts
      }
    },
    provideEnvironmentNgxMask(maskConfig),
    provideStore(reducers, { metaReducers }),
    provideEffects(effects),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi())
  ]})
  .catch(err => console.error(err));
