import { inject } from '@angular/core';
import { Router } from '@angular/router';

export const existNewPasswordParamsGuard = () => {
    const router = inject(Router);
    const newPasswordParams = JSON.parse(<any>sessionStorage.getItem('newPasswordParams'));

    if (newPasswordParams && 'uidb64' in newPasswordParams && 'token' in newPasswordParams) {
        return true;
    }

    router.navigate(['/auth/login']);

    return false;
};
