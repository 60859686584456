import { IError } from 'src/app/interfaces/';

export class ErrorModel implements IError {
    private readonly _code: string;
    private readonly _message: string;

    constructor(message: string, code: string) {
        this._message = message;
        this._code = code;
    }

    get code(): string {
        return this._code;
    }

    get message(): string {
        return this._message;
    }
}
