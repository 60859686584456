import { IReport } from '../../interfaces';

export class ReportModel implements IReport {
    private readonly _id: number;
    private readonly _name: string;
    private readonly _date: string;
    private readonly _creator: string;

    constructor(data: { value: any }) {
        this._id = data['value']['id'] ?? null;
        this._name = data['value']['name'] ?? null;
        this._date = data['value']['data'] ?? null;
        this._creator = data['value']['creator'] ?? null;
    }

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    get date() {
        return this._date;
    }

    get creator() {
        return this._creator;
    }
}
