import { INotification, IPublicUser } from '../../interfaces';
import { TNotificationType } from '../../helpers';
import * as moment from 'moment';

export class NotificationModel implements INotification {
    private readonly _id: number;
    private readonly _title: string;
    private readonly _content: string;
    private readonly _isReviewed: boolean;
    private readonly _sentBy: IPublicUser;
    private readonly _date: string;
    private readonly _typeKey: TNotificationType;
    private readonly _url: string;
    private readonly _reviewedAt: string;

    constructor(data: { value: any }) {
        this._id = data['value']['id'] ?? null;
        this._title = data['value']['title'] ?? null;
        this._content = data['value']['content'] ?? null;
        this._isReviewed = data['value']['reviewed'] ?? null;
        this._sentBy = data['value']['sent_by'] ?? null;
        this._date = moment(data['value']['display_timestamp']).format('DD.MM.YYYY HH:mm:ss');
        this._typeKey = data['value']['type_key'] ?? null;
        this._url = data['value']['url'] ?? null;
        this._reviewedAt = data['value']['reviewed_at'] ?? null;

        if (this._sentBy) {
            this._sentBy['avatar'] = this._sentBy['avatar'] ?? '/assets/images/user-dummy-img.jpg';
        }
    }

    get id() {
        return this._id;
    }

    get title() {
        return this._title;
    }

    get content() {
        return this._content;
    }

    get isReviewed() {
        return this._isReviewed;
    }

    get sentBy() {
        return this._sentBy;
    }

    get date() {
        return this._date;
    }

    get typeKey() {
        return this._typeKey;
    }

    get url() {
        return this._url;
    }

    get reviewedAt() {
        return this._reviewedAt;
    }
}
