import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResult, IUserLog, IUserUpdateRequest } from 'src/app/interfaces';
import { HttpService } from '../http/http.service';
import { DataListModel, TwoFaSetupModel, UserLogModel, UserModel } from '../../models';
import { UserDto } from '../../request-dto';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    readonly set2FaDisableName = 'account/2fa/disable/';
    readonly get2FaSetupName = 'account/2fa/setup/';
    readonly confirm2FaVerifyName = 'account/2fa/verify/';
    readonly getUserDetailsName = 'account/details/';
    readonly changeUserDetailsName = 'account/change/';
    readonly changePasswordName = 'account/change-password/';
    readonly getUserLogsName = 'account/activity/';
    readonly blockAccountName = 'account/block/';

    constructor(
        private http: HttpService
    ) { }

    public set2FaDisable(): Observable<IResult> {
        return this.http.request('POST', this.set2FaDisableName, { });
    }

    public get2FaSetup<T>(): Observable<IResult<T>> {
        return this.http.request('GET', this.get2FaSetupName, { }, this.get2FaSetupModel);
    }

    private get2FaSetupModel(data: object): TwoFaSetupModel {
        return new TwoFaSetupModel(data);
    }

    public confirm2FaVerify(otpCode: string): Observable<IResult> {
        return this.http.request('POST', this.confirm2FaVerifyName, { otp_code: otpCode });
    }

    public blockAccount(): Observable<IResult> {
        return this.http.request('POST', this.blockAccountName, { });
    }

    public getUserDetails<T>(): Observable<IResult<T>> {
        return this.http.request('GET', this.getUserDetailsName, { }, this.getUserDetailsModel);
    }

    private getUserDetailsModel(data: object): UserModel {
        return new UserModel({ value: data });
    }

    public changeUserDetails(user: IUserUpdateRequest): Observable<IResult> {
        const payload = UserDto.adapt(user);

        return this.http.request('POST', this.changeUserDetailsName, payload);
    }

    public changePassword(oldPassword: string, newPassword: string): Observable<IResult> {
        return this.http.request('POST', this.changePasswordName, { old_password: oldPassword, new_password: newPassword });
    }

    public getUserLogs<T>(page: number, pageSize: number, q: string = ''): Observable<IResult<T>> {
        return this.http.request('GET', this.getUserLogsName, { page, page_size: pageSize, q }, this.getUserLogsModel);
    }

    private getUserLogsModel(data: object): DataListModel<IUserLog> {
        return new DataListModel<IUserLog>(data, UserLogModel);
    }
}
