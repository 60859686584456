import { IUserLog } from '../../interfaces';
import * as moment from 'moment';

export class UserLogModel implements IUserLog {
    private readonly _id: number;
    private readonly _action: string;
    private readonly _actionType: string;
    private readonly _deviceInfo!: string;
    private readonly _description: string;
    private readonly _ip: string;
    private readonly _date!: string;

    constructor(data: { value: any }) {
        this._id = data['value']['id'] ?? null;
        this._action = data['value']['action'] ?? null;
        this._actionType = data['value']['action_type'] ?? null;
        this._description = data['value']['description'] ?? null;
        this._ip = data['value']['ip_address'] ?? null;

        if (data['value']['data']) {
            this._deviceInfo = Object.values(data['value']['data']).join(', ');
        }

        if (data['value']['timestamp']) {
            this._date = moment(data['value']['timestamp']).format('DD.MM.YYYY HH:mm:ss');
        }
    }

    get id() {
        return this._id;
    }

    get action() {
        return this._action;
    }

    get actionType() {
        return this._actionType;
    }

    get deviceInfo() {
        return this._deviceInfo;
    }

    get description() {
        return this._description;
    }

    get ip() {
        return this._ip;
    }

    get date() {
        return this._date;
    }
}
