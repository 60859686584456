import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    IKeyword,
    IKeywordAdditional, IKeywordFilter,
    IParsingSource,
    IParsingSourceAdditional,
    IResult,
    ISourceKeywordCategory
} from 'src/app/interfaces';
import { HttpService } from '../http/http.service';
import {
    DataListModel,
    KeywordCategoryModel,
    KeywordModel,
    KeywordAdditionalModel,
    ParsingSourceModel,
    ParsingSourceCategoryModel, KeywordFilterModel
} from '../../models';
import { ParsingSourceAdditionalModel } from '../../models/parsing-source/parsing-source-additional.model';

@Injectable({
    providedIn: 'root'
})
export class SystemSettingsService {
    // parsing sources
    readonly sourceCoreName = 'sources/';
    readonly createSourceName = 'create/';
    readonly updateSourceName = 'update/';
    readonly deleteSourceName = 'bulk/delete/';
    readonly getSourceCategoriesName = 'categories/';
    readonly updateSourceCategoryName = 'update/';
    readonly deleteSourceCategoryName = 'delete/';
    readonly createSourceCategoryName = 'create/';

    // keywords
    readonly keywordCoreName = 'filters/';
    readonly createKeywordName = 'create/';
    readonly updateKeywordName = 'update/';
    readonly deleteKeywordName = 'bulk/delete/';
    readonly getKeywordCategoriesName = 'categories/';
    readonly updateKeywordCategoryName = 'update/';
    readonly deleteKeywordCategoryName = 'delete/';
    readonly createKeywordCategoryName = 'create/';

    constructor(
        private http: HttpService
    ) { }


    // parsing sources
    public getSources<T>(category_ids: number[] | null, page: number, page_size: number, q?: string): Observable<IResult<T>> {
        return this.http.request('GET', this.sourceCoreName, { category_ids, page, page_size, q }, this.getSourcesModel);
    }

    private getSourcesModel(data: object): DataListModel<IParsingSource, IParsingSourceAdditional> {
        return new DataListModel<IParsingSource, IParsingSourceAdditional>(data, ParsingSourceModel, ParsingSourceAdditionalModel);
    }

    public sourceCreate(data: any): Observable<IResult> {
        return this.http.request('POST', `${this.sourceCoreName}${this.createSourceName}`, data);
    }

    public sourceUpdate(id: number, data: any): Observable<IResult> {
        return this.http.request('POST', `${this.sourceCoreName}${id}/${this.updateSourceName}`, data);
    }

    public deleteSources(ids: number[]): Observable<IResult> {
        return this.http.request('POST', `${this.sourceCoreName}${this.deleteSourceName}`, { ids });
    }

    public getSourceCategories<T>(page: number, page_size: number, q?: string): Observable<IResult<T>> {
        return this.http.request('GET', `${this.sourceCoreName}${this.getSourceCategoriesName}` , { page, page_size, q }, this.getSourceCategoriesModel);
    }

    private getSourceCategoriesModel(data: object): DataListModel<ISourceKeywordCategory> {
        return new DataListModel<ISourceKeywordCategory>(data, ParsingSourceCategoryModel);
    }

    public updateSourceCategory(id: number, name: string): Observable<IResult> {
        return this.http.request('POST', `${this.sourceCoreName}${this.getSourceCategoriesName}${id}/${this.updateSourceCategoryName}`, { name });
    }

    public deleteSourceCategory(id: number): Observable<IResult> {
        return this.http.request('POST', `${this.sourceCoreName}${this.getSourceCategoriesName}${id}/${this.deleteSourceCategoryName}`);
    }

    public createSourceCategory(name: string): Observable<IResult> {
        return this.http.request('POST', `${this.sourceCoreName}${this.getSourceCategoriesName}${this.createSourceCategoryName}`, { name });
    }


    // keywords
    public getFilters<T>(): Observable<IResult<T>> {
        return this.http.request('GET', this.keywordCoreName, { }, this.getFiltersModel);
    }

    private getFiltersModel(data: object): DataListModel<IKeywordFilter> {
        return new DataListModel<IKeywordFilter>(data, KeywordFilterModel);
    }


    public getKeywords<T>(id: number, category_ids: number[] | null, page: number, page_size: number, q?: string): Observable<IResult<T>> {
        return this.http.request('GET', `${this.keywordCoreName}${id}/`, { category_ids, page, page_size, q }, this.getKeywordsModel);
    }

    private getKeywordsModel(data: object): DataListModel<IKeyword, IKeywordAdditional> {
        return new DataListModel<IKeyword, IKeywordAdditional>(data, KeywordModel, KeywordAdditionalModel);
    }

    public createKeywords(filterId: number, value: string, category_ids: number[]): Observable<IResult> {
        return this.http.request('POST', `${this.keywordCoreName}${filterId}/${this.createKeywordName}`, { value, category_ids });
    }

    public updateKeyword(filterId: number, id: number, data: any): Observable<IResult> {
        return this.http.request('POST', `${this.keywordCoreName}${filterId}/${id}/${this.updateKeywordName}`, data);
    }

    public deleteKeywords(ids: number[]): Observable<IResult> {
        return this.http.request('POST', `${this.keywordCoreName}${this.deleteKeywordName}`, { ids });
    }

    public getKeywordCategories<T>(filterId: number, page: number, page_size: number, q?: string): Observable<IResult<T>> {
        return this.http.request('GET', `${this.keywordCoreName}${filterId}/${this.getKeywordCategoriesName}` , { page, page_size, q }, this.getKeywordCategoriesModel);
    }

    private getKeywordCategoriesModel(data: object): DataListModel<ISourceKeywordCategory> {
        return new DataListModel<ISourceKeywordCategory>(data, KeywordCategoryModel);
    }

    public createKeywordCategory(filterId: number, name: string, board_ids: number[]): Observable<IResult> {
        return this.http.request('POST', `${this.keywordCoreName}${filterId}/${this.getKeywordCategoriesName}${this.createKeywordCategoryName}`, { name, board_ids });
    }

    public updateKeywordCategory(id: number, filterId: number, name: string, board_ids: number[]): Observable<IResult> {
        return this.http.request('POST', `${this.keywordCoreName}${filterId}/${this.getKeywordCategoriesName}${id}/${this.updateKeywordCategoryName}`, { name, board_ids });
    }

    public deleteKeywordCategory(id: number): Observable<IResult> {
        return this.http.request('POST', `${this.keywordCoreName}${this.getKeywordCategoriesName}${id}/${this.deleteKeywordCategoryName}`);
    }
}
