<form [formGroup]="newProjectForm" [class.is-modal]="isModal">
    <label class="form-label mb-3">
        поля, помічені зірочкою <span class="required">*</span>, є обовʼязковими для заповнення
    </label>
    <h3 class="mb-3">Загальне</h3>

    <div class="field mb-3">
        <label for="name" class="form-label">Назва проєкту</label>
        <input type="text" class="form-control" id="name"
               [placeholder]="'Введіть назву задачі'"
               required formControlName="name"
               [ngClass]="{ 'is-invalid': f['name'].touched && f['name'].errors }">
        @if (f['name'].touched && f['name'].errors) {
            <div class="invalid-feedback">
                Введіть коректну назву задачі
            </div>
        }
    </div>

    <div class="field mb-3">
        <label class="form-label">Назви дошок для парсингу</label>
        <label class="form-label descr">
            Проєкт повинен містити щонайменше одну дошку
        </label>
        <div class="boards-block">
            @for (board of boards; track $index) {
                <div class="board">
                    <div>
                        <input type="text" class="form-control"
                               [placeholder]="'Введіть назву дошки'"
                               required [formControl]="board.name"
                               [ngClass]="{ 'is-invalid': board.name.touched && board.name.errors }">
                        @if (board.name.touched && board.name.errors) {
                            <div class="invalid-feedback">
                                Введіть коректну назву дошки
                            </div>
                        }
                    </div>

                    <div>
                        <select class="form-select" [formControl]="board.role"
                                [ngClass]="{ 'is-invalid': board.role.touched && board.role.errors }">
                            <option [value]="null" disabled selected>Рівень доступу</option>

                            <option [value]="'1'">Великий</option>
                            <option [value]="'1'">Середній</option>
                            <option [value]="'1'">Маленький</option>
                        </select>
                        @if (board.role.touched && board.role.errors) {
                            <div class="invalid-feedback">
                                Оберіть рівень доступу
                            </div>
                        }
                    </div>
                    <button class="badge bg-light" [disabled]="boards.length === 1" (click)="deleteBoard($index)">
                        <i class='bx bx-trash-alt'></i>
                    </button>
                </div>
            }

            <div>
                <button class="btn btn-add-board" (click)="addBoard()">
                    <i class="las la-plus la-lg"></i> Додати дошку
                </button>
            </div>
        </div>

    </div>

    <div class="field mb-3">
        <label class="form-label">Категорії каналів для парсингу</label>

        <ng-select
                class="default-list"
                [items]="entitiesToNotify()"
                [multiple]="true"
                [placeholder]="'Оберіть юзерів або категорії'"
                [bindLabel]="'name'"
                [bindValue]="'id'"
                [addTag]="addNewCategory"
                [addTagText]="'Додати нову категорію'"
                [ngClass]="{ 'is-invalid': f['categories'].touched && f['categories'].errors }"
                formControlName="categories"
        >
            <ng-template let-item="item" ng-option-tmp>
                {{ item.name || 'Unnamed group' }}
            </ng-template>
        </ng-select>

        @if (f['categories'].touched && f['categories'].errors) {
            <div class="invalid-feedback">
                Оберіть або додайте категорії
            </div>
        }
    </div>

    <div class="buttons d-flex mt-4 justify-content-center">
        <button class="btn btn-settings ms-3 d-flex align-items-center" (click)="onSubmit()" [ngClass]="{ 'loading': loadingBtn() }"
                [disabled]="newProjectForm.invalid || boards[0].name.invalid || boards[0].role.invalid">
            <i class="las la-check-circle la-lg me-2 d-block"></i> Створити проєкт
        </button>
    </div>
</form>
