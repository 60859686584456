import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component, DestroyRef, effect, EventEmitter, inject,
    Input,
    OnInit, Output, Signal
} from '@angular/core';
import { IBoard, IResult } from '../../interfaces';
import { NgClass, NgStyle } from '@angular/common';
import { RouterLink } from '@angular/router';
import { BoardsService } from '../../services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-board-card',
    templateUrl: './board-card.component.html',
    styleUrls: ['./board-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        NgStyle,
        RouterLink,
        NgbDropdown,
        NgbDropdownToggle,
        NgbDropdownMenu,
    ],
})
export class BoardCardComponent implements OnInit {
    @Input() board!: IBoard;
    @Input() loadingFavourite!: Signal<boolean>;
    @Output() public onToggledFavoriteBoard = new EventEmitter<boolean>();

    public loading: boolean = false;

    private destroy = inject(DestroyRef);

    constructor(
        private cd: ChangeDetectorRef,
        private boardsService:BoardsService
    ) {
        effect(() => {
            if (!this.loadingFavourite()) {
                this.loading = false;
                this.cd.detectChanges();
            }
        });
    }

    ngOnInit(): void { }

    public toggleFavoriteBoard(): void {
        this.loading = true;
        this.cd.detectChanges();

        this.boardsService.toggleBoardFavorite(this.board.id)
            .pipe(takeUntilDestroyed(this.destroy))
            .subscribe((result: IResult) => {
                if (result.isSuccess) {
                    this.onToggledFavoriteBoard.emit(true);
                    this.board.isFavorite = !this.board.isFavorite;
                    this.cd.detectChanges();
                }
            });
    }
}
