import { IProject } from '../../interfaces';

export class ProjectModel implements IProject {
    private readonly _id: number;
    private readonly _name: string;
    private readonly _description: string;
    private readonly _teamId: number;
    private readonly _image: string;
    private readonly _isPublic: boolean;
    private readonly _isDefault: boolean;

    constructor(data: { value: any }) {
        this._id = data['value']['id'] ?? null;
        this._name = data['value']['name'] ?? null;
        this._description = data['value']['description'] ?? null;
        this._teamId = data['value']['team'] ?? null;
        this._image = data['value']['image'] ?? '';
        this._isDefault = data['value']['is_default'] ?? null;
        this._isPublic = data['value']['is_public'] ?? null;
    }

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    get description() {
        return this._description;
    }

    get teamId() {
        return this._teamId;
    }

    get image() {
        return this._image;
    }

    get isDefault() {
        return this._isDefault;
    }

    get isPublic() {
        return this._isPublic;
    }
}
