import { NgClass, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    EventEmitter,
    inject, Input,
    OnInit,
    Output,
    signal,
} from '@angular/core';
import {
    FormsModule,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { ProjectsService, ToastsService, ValidationLayerService } from 'src/app/services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
    selector: 'app-create-project-form',
    templateUrl: './create-project-form.component.html',
    styleUrls: ['./create-project-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        TranslateModule,
        RouterLink,
        NgClass,
        NgSelectModule,
    ],
})
export class CreateProjectFormComponent implements OnInit {
    @Input() public isModal: boolean = false;
    @Output() public onCreatedProject: EventEmitter<boolean> = new EventEmitter();

    public entitiesToNotify = signal<any>([
        { id: 1, name: 'Юзер 1' },
        { id: 1, name: 'Юзер 2' },
        { id: 1, name: 'Юзер 3' },
        { id: 1, name: 'Юзер 4' },
        { id: 1, name: 'Юзер 5' },
        { id: 2, name: '@Борошнюк' },
        { id: 2, name: '@Рембазнюк' },
        { id: 2, name: '@Девелоперюк' },
    ]);
    public loadingBtn = signal<boolean>(false);

    public newProjectForm!: UntypedFormGroup;
    public boards: { name: UntypedFormControl, role: UntypedFormControl }[] = [];
    public config = {
        autoProcessQueue: false,
        addRemoveLinks: true,
        dictDefaultMessage: 'Перетягніть файли або натисніть для завантаження.'
    }
    public newCategories: { id: string, name: string }[] = [];

    private destroy = inject(DestroyRef);

    constructor (
        private cd: ChangeDetectorRef,
        private fb: UntypedFormBuilder,
        private projectsService: ProjectsService,
        private toastsService: ToastsService,
        private validationLayer: ValidationLayerService,
    ) {
        this.addNewCategory = this.addNewCategory.bind(this);
    }

    ngOnInit(): void {
        this.newProjectForm = this.fb.group({
            name: [null, Validators.required],
            categories: [[], this.validationLayer.get('notEmptyArray')],
        });

        this.addBoard();

        this.cd.detectChanges();
    }

    public addBoard(): void {
        this.boards.push(
            {
                name: new UntypedFormControl(null, Validators.required),
                role: new UntypedFormControl(null, Validators.required),
            }
        );
    }

    public deleteBoard(index: number): void {
        if (this.boards.length === 1) return;

        this.boards.splice(index, 1);
    }

    get f() {
        return this.newProjectForm.controls;
    }

    public addNewCategory(name: string): any {
        const newCategory = {
            id: Math.random().toString(36).substring(2, 8),
            name: name,
        };

        this.newCategories.push(newCategory);

        return newCategory;
    }

    public onSubmit(): void {
        if (this.newProjectForm.valid || this.boards[0].name.invalid || this.boards[0].role.invalid) {
            this.loadingBtn.set(true);

            const categories = this.newProjectForm.value['categories'].map((value: any) => {
                const newCategory = this.newCategories.find((category) => category.id === value);

                return newCategory ? newCategory.name : value;
            });

            const boards = this.boards.map((board) => {
                return {
                    name: board.name.value,
                    role: board.role.value,
                }
            })

            this.projectsService.createProject({ ...this.newProjectForm.value, categories, boards })
                .pipe(takeUntilDestroyed(this.destroy))
                .subscribe((result) => {
                    if (result.isSuccess) {
                        this.toastsService.show('Задачу створено', { classname: 'bg-success text-light', delay: 3000 });
                        this.onCreatedProject.emit(true);
                    }

                    this.loadingBtn.set(false);
                });

            return;
        }

        this.toastsService.show('Введено некоректні дані', { classname: 'bg-danger text-light', delay: 3000 });
    }
}
