import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs';
import {
    loadUser,
    loadUserSuccess,
    loadUserFailure,
    loadImpersonatedUserSuccess,
    loadImpersonatedUserFailure, loadImpersonatedUser
} from './user.actions';
import { SettingsService, UsersService } from '../../services';
import { IResult, IUser } from '../../interfaces';

@Injectable()
export class UserEffects {
    loadUser$: any;
    loadImpersonatedUser$: any;

    constructor(
        private actions$: Actions,
        private settingsService: SettingsService,
        private usersService: UsersService,
    ) {

        this.loadUser$ = createEffect(() =>
            this.actions$.pipe(
                ofType(loadUser),
                mergeMap(() =>
                    this.settingsService.getUserDetails<IUser>().pipe(
                        map((result: IResult<IUser>) => {
                            if (result.isSuccess) {
                                return loadUserSuccess({ user: result.data })
                            } else {
                                return loadUserFailure({ error: result.error })
                            }
                        }),
                    )
                )
            )
        );

        this.loadImpersonatedUser$ = createEffect(() =>
            this.actions$.pipe(
                ofType(loadImpersonatedUser),
                mergeMap(({ id }) => this.usersService.getUser<IUser>(id).pipe(
                        map((result: IResult<IUser>) => {
                            if (result.isSuccess) {
                                return loadImpersonatedUserSuccess({ impersonatedUser: result.data })
                            } else {
                                return loadImpersonatedUserFailure({ error: result.error })
                            }
                        })
                    )
                )
            )
        );
    }
}
