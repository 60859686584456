<div ngbDropdown [autoClose]="false" class="dropdown topbar-head-dropdown ms-1 header-item"
     clickOutside (onClickOutside)="closeAllDropdowns()"
     (openChange)="onDropdownToggle($event)">
    <button type="button" ngbDropdownToggle class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
            id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
        <i class='bx bx-bell fs-22'></i>
        @if (unreviewedTotal() > 0) {
            <span class="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
                {{ unreviewedTotal() }} <span class="visually-hidden">unread messages</span>
            </span>
        }
    </button>
    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
         aria-labelledby="page-header-notifications-dropdown">
        <div class="dropdown-head bg-primary  bg-pattern rounded-top">
            <div class="p-3">
                <div class="row align-items-center">
                    <div class="col">
                        <h6 class="m-0 fs-16 fw-semibold text-white"> Сповіщення </h6>
                    </div>
                    @if (unreviewedTotal() > 0) {
                        <div class="col-auto dropdown-tabs">
                            <button (click)="updateNotification([], true)">
                                <span class="badge bg-light-subtle text-body fs-13">Прочитати всі ({{ unreviewedTotal() }})</span>
                            </button>
                        </div>
                    }
                </div>
            </div>

            <div class="px-2 pt-2">
                <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav nav-tabs dropdown-tabs nav-tabs-custom"
                    id="notificationItemsTab">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink>
                            Всі ({{ total() }})
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade show active py-2 ps-2" id="all-noti-tab-1" role="tabpanel">
                                <div class="notifications-container custom-scrollbar" appScrollTracker
                                     (scrolledToBottom)="onScrolledToBottom()">
                                    <div class="pe-2">
                                        @for (item of allNotifications(); track $index) {
                                            <a class="text-reset notification-item d-block dropdown-item position-relative"
                                               visible (fullyVisible)="saveVisibleId(item)" (click)="navigateToNotification(item.url)">
                                                <div class="position-relative d-flex align-items-center">

                                                    @if (item.sentBy) {
                                                        <ng-container *ngTemplateOutlet="triggeredByUserRef; context: { notification: item }"></ng-container>
                                                    } @else {
                                                        <ng-container *ngTemplateOutlet="triggeredBySystemRef; context: { notification: item }"></ng-container>
                                                    }

                                                    @if (!item.isReviewed) {
                                                        <span class="dot-red"></span>
                                                    }
                                                </div>
                                            </a>
                                        } @empty {
                                            <div [ngClass]="total() !== 0 || loading() ?'d-none':''" class="tab-pane p-4"
                                                 id="alerts-tab-1" role="tabpanel" aria-labelledby="alerts-tab-1">
                                                <div class="text-center py-3">
                                                    <h6 class="fs-18 m-0 fw-semibold lh-base">Ви не маєте сповіщень! </h6>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    @if (loading()) {
                                        <div class="d-flex filter-loader py-3">
                                            <app-preloader [class]="'small'"></app-preloader>
                                        </div>
                                    }
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <!--<li [ngbNavItem]="2">
                        <a ngbNavLink>
                            Згадки
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade show active py-2 ps-2" id="all-noti-tab-2" role="tabpanel">
                                <div class="notifications-container" appScrollTracker
                                     (scrolledToBottom)="onScrolledToBottom()">
                                    <div class="pe-2">
                                        @for (item of mentions(); track $index) {
                                            <a class="text-reset notification-item d-block dropdown-item position-relative"
                                               [href]="item.url" visible (fullyVisible)="saveVisibleId(item)">
                                                <div class=" d-flex">
                                                    @if (item.typeKey === 'mentioned') {
                                                        <ng-container *ngTemplateOutlet="mentionedRef; context: { notification: item }"></ng-container>
                                                    }
                                                </div>
                                            </a>
                                        } @empty {
                                            <div [ngClass]="mentions().length !== 0 || loading() ? 'd-none':''" class="tab-pane p-4"
                                                 id="alerts-tab-2" role="tabpanel" aria-labelledby="alerts-tab-2">
                                                <div class="text-center py-3">
                                                    <h6 class="fs-18 m-0 fw-semibold lh-base">Ви не маєте згадок! </h6>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    @if (loading()) {
                                        <div class="d-flex filter-loader py-3">
                                            <app-preloader [class]="'small'"></app-preloader>
                                        </div>
                                    }
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="3">
                        <a ngbNavLink>
                            Alerts
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane p-4" id="alerts-tab-3" role="tabpanel" aria-labelledby="alerts-tab-3">
                                <div class="w-25 w-sm-50 pt-3 mx-auto">
                                    <img src="" class="img-fluid" alt="user-pic">
                                </div>
                                <div class="text-center pb-5 mt-2">
                                    <h6 class="fs-18 fw-semibold lh-base">Hey! You have no any
                                        notifications </h6>
                                </div>
                            </div>
                        </ng-template>
                    </li>-->
                </ul>
            </div>

        </div>

        <div class="tab-content" id="notificationItemsTabContent">
            <div class="tab-content text-muted">
                <div [ngbNavOutlet]="nav"></div>
            </div>
        </div>

        <div class="notification-actions" id="notification-actions">
            <div class=" d-flex text-muted justify-content-center">
                Select
                <div id="select-content" class="text-body fw-semibold px-1">21323</div>
                Result
                <button type="button" class="btn btn-link link-danger p-0 ms-3" data-bs-toggle="modal"
                        data-bs-target="#removeNotificationModal">Remove
                </button>
            </div>
        </div>
    </div>
</div>


<ng-template #triggeredByUserRef let-notification="notification">
    <img src="{{notification.sentBy.avatar}}"
         class="me-3 rounded-circle avatar-xs" alt="user-pic">

    <div class="flex-grow-1">
        <div>
            <h6 class="mt-0 mb-1 fs-13 fw-semibold">{{ notification.title }}</h6>
        </div>
        <div class="fs-13 text-muted">
            <p class="mb-1"
               [innerHTML]="notification.content | markdownSafeHtml"></p>
        </div>
        <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
            <span><i class="mdi mdi-clock-outline"></i> {{ notification.date }}</span>
        </p>
    </div>
</ng-template>

<ng-template #triggeredBySystemRef let-notification="notification">
    <div class="flex-grow-1">
        <div>
            <h6 class="mt-0 mb-1 fs-13 fw-semibold">{{ notification.title }}</h6>
        </div>
        <div class="fs-13 text-muted">
            <p class="mb-1"
               [innerHTML]="notification.content | markdownSafeHtml"></p>
        </div>
        <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
            <span><i class="mdi mdi-clock-outline"></i> {{ notification.date }}</span>
        </p>
    </div>
</ng-template>

