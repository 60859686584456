import { ITaskCommentAdditional } from '../../interfaces';

export class TaskCommentAdditionalModel implements ITaskCommentAdditional {
    private readonly _hasAfter: boolean;
    private readonly _hasBefore: boolean;
    private readonly _commentsIds: number[] = [];

    constructor(data: any) {
        this._hasAfter = data['has_after'] ?? null;
        this._hasBefore = data['has_before'] ?? null;

        Object.keys(data['results']).map((key: string) => {
            this._commentsIds.push(data['results'][key].id);
        });
    }

    get hasAfter() {
        return this._hasAfter;
    }

    get hasBefore() {
        return this._hasBefore;
    }

    get commentsIds() {
        return this._commentsIds;
    }
}
