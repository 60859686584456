import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'filterArray'
})
export class FilterArrayPipe implements PipeTransform {

    transform(items: any[] | Record<string, any>, searchText: string, fieldName: string): any[] {
        if (!items) return [];
        if (!searchText) return Array.isArray(items) ? items : Object.values(items);

        searchText = searchText.toLowerCase();

        if (Array.isArray(items)) {
            return items.filter(item =>
                item[fieldName]?.toLowerCase().includes(searchText)
            );
        }

        return Object.values(items).filter(item =>
            item[fieldName]?.toLowerCase().includes(searchText)
        );
    }
}
