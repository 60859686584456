import { NgClass, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef, EventEmitter,
    inject, Input,
    OnInit, Output, signal,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    FormsModule,
} from '@angular/forms';
import { RouterLink } from '@angular/router';
import { SearchInputComponent } from '../../search-input/search-input.component';
import { Subject, switchMap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IDataList, IPagination, IProjectTag, IResult } from '../../../interfaces';
import { ProjectsService } from '../../../services';
import { TagComponent } from '../tag/tag.component';
import { FilterArrayPipe } from '../../../pipe';

@Component({
    selector: 'app-manage-tags-modal',
    templateUrl: './manage-tags-modal.component.html',
    styleUrls: ['./manage-tags-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        NgIf,
        RouterLink,
        NgClass,
        SearchInputComponent,
        TagComponent,
        FilterArrayPipe,
    ]
})
export class ManageTagsModalComponent implements OnInit {
    @Input() public projectId!: number;

    @Output() public onUpdate: EventEmitter<boolean> = new EventEmitter();

    public tags = signal<IProjectTag[]>([]);
    public totalSize = signal<number>(0);

    private searchText: string | undefined;
    private destroy = inject(DestroyRef);
    private loading: boolean = false;
    private currentPage: number = 1;
    private pageSize = 30;
    private getTagsTrigger$ = new Subject<IPagination>();

    constructor (
        public activeModal: NgbActiveModal,
        private projectsService: ProjectsService,
    ) { }

    ngOnInit(): void {
        this.getTagsTrigger$
            .pipe(
                switchMap((pagination: IPagination) => {
                    this.currentPage = pagination.page;

                    return this.projectsService.getTags<IDataList<IProjectTag>>(
                        this.projectId,
                        pagination.page,
                        pagination.pageSize,
                        this.searchText
                    )
                }),
                takeUntilDestroyed(this.destroy)
            )
            .subscribe((result: IResult<IDataList<IProjectTag>>) => {
                if (result.isSuccess) {
                    this.tags.set(result.data.data);
                    this.totalSize.set(result.data.total);
                }
            });

        this.getTagsTrigger$.next({ page: this.currentPage, pageSize: this.pageSize });
    }

    public onScrolledToBottom(): void {
        if (!this.loading && this.tags().length < this.totalSize()) {
            this.loading = true;
            this.currentPage++;
            this.getTagsTrigger$.next({ page: this.currentPage, pageSize: this.pageSize });
        }
    }

    public fetchSearchData(searchTerm: string): void {
        this.searchText = searchTerm;
        this.getTagsTrigger$.next({ page: 1, pageSize: this.pageSize });
    }
}
