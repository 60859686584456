import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    IBoard,
    IBoardAdditional,
    ICustomField,
    ICustomFieldAdditional, IProject, IProjectRole, IProjectRule,
    IProjectStatus, IProjectTag,
    IResult, IUser
} from 'src/app/interfaces';
import { HttpService } from '../http/http.service';
import {
    DataListModel,
    CustomFieldAdditionalModel,
    CustomFieldModel,
    BoardModel,
    BoardAdditionalModel,
    ProjectStatusModel,
    ProjectTagModel,
    ProjectModel, ProjectRoleModel, ProjectRuleModel, UserModel
} from '../../models';
import { BoardDto, CustomFieldDto, RoleDto } from '../../request-dto';

@Injectable({
    providedIn: 'root'
})
export class ProjectsService {
    readonly projectsCoreName = 'projects/';
    readonly createBoardName = 'create-board/';
    readonly createProjectName = 'create/';
    readonly updateProjectName = 'update/';
    readonly getCategoriesName = 'categories/';

    readonly getCustomFieldsName = 'options/';
    readonly createCustomFieldName = 'create/';
    readonly updateCustomFieldPositionsName = 'position/';
    readonly updateCustomFieldName = 'update/';
    readonly deleteCustomFieldName = 'delete/';

    readonly statusCoreName = 'statuses/';
    readonly createStatusName = 'create/';
    readonly deleteStatusName = 'delete/';
    readonly updateStatusName = 'update/';
    readonly sortStatusName = 'sort/';

    readonly tagCoreName = 'tags/';
    readonly createTagName = 'create/';
    readonly deleteTagName = 'delete/';
    readonly updateTagName = 'update/';

    readonly userGroupCoreName = 'usergroups/';
    readonly createRoleName = 'create/';
    readonly deleteRoleName = 'delete/';
    readonly updateRoleName = 'update/';
    readonly getRoleUsersName = 'users/';
    readonly addUserToRoleName = 'create/';
    readonly deleteUsersFromRoleName = 'delete/';
    readonly inviteUsersToRoleName = 'invite/';

    readonly ruleCoreName = 'rules/';
    readonly createRuleName = 'create/';
    readonly deleteRuleName = 'delete/';
    readonly updateRuleName = 'update/';

    constructor(
        private http: HttpService
    ) { }

    public getProjects<T>(): Observable<IResult<T>> {
        return this.http.request('GET', this.projectsCoreName, { }, this.getProjectsModel);
    }

    private getProjectsModel(data: object): DataListModel<IProject> {
        return new DataListModel<IProject>(data, ProjectModel);
    }

    public getProjectBoards<T>(id: number, page: number, page_size: number, q?: string): Observable<IResult<T>> {
        return this.http.request('GET', `${this.projectsCoreName}${id}/` , { page, page_size, q }, this.getProjectBoardsModel);
    }

    private getProjectBoardsModel(data: object): DataListModel<IBoard, IBoardAdditional> {
        return new DataListModel<IBoard, IBoardAdditional>(data, BoardModel, BoardAdditionalModel);
    }

    public createBoard(id: number, data: any): Observable<IResult> {
        const payload = BoardDto.adapt(data);

        return this.http.request('POST', `${this.projectsCoreName}${id}/${this.createBoardName}`, payload);
    }

    public createProject(data: any): Observable<IResult> {
        // const payload = NewBoardDto.adapt(data);
        const payload = data;

        return this.http.request('POST', `${this.projectsCoreName}/${this.createProjectName}`, payload);
    }

    public updateProject(id: number, data: any): Observable<IResult> {
        // const payload = NewBoardDto.adapt(data);
        const payload = data;

        return this.http.request('POST', `${this.projectsCoreName}${id}/${this.updateProjectName}`, payload);
    }

    public getCustomFields<T>(id: number, page: number, page_size: number, field_types: string[] | null, q?: string): Observable<IResult<T>> {
        return this.http.request('GET', `${this.projectsCoreName}${id}/${this.getCustomFieldsName}`, { page, page_size, field_types, q }, this.getCustomFieldsModel);
    }

    private getCustomFieldsModel(data: object): DataListModel<ICustomField, ICustomFieldAdditional> {
        return new DataListModel<ICustomField, ICustomFieldAdditional>(data, CustomFieldModel, CustomFieldAdditionalModel);
    }

    public createCustomField(id: number, data: any): Observable<IResult> {
        const payload = CustomFieldDto.adapt(data);

        return this.http.request('POST', `${this.projectsCoreName}${id}/${this.getCustomFieldsName}${this.createCustomFieldName}`, payload);
    }

    public updateCustomField(projectId: number, fieldId: number, data: any): Observable<IResult> {
        const payload = CustomFieldDto.adapt(data);

        return this.http.request('POST', `${this.projectsCoreName}${projectId}/${this.getCustomFieldsName}${fieldId}/${this.updateCustomFieldName}`, payload);
    }

    public deleteCustomField(projectId: number, fieldId: number): Observable<IResult> {
        return this.http.request('POST', `${this.projectsCoreName}${projectId}/${this.getCustomFieldsName}${fieldId}/${this.deleteCustomFieldName}`, { });
    }

    public updateCustomFieldPositions(id: number, positions: any[]): Observable<IResult> {
        return this.http.request('POST', `${this.projectsCoreName}${id}/${this.getCustomFieldsName}${this.updateCustomFieldPositionsName}`, { positions });
    }

    public getStatuses<T>(id: number, page: number, page_size: number, q?: string): Observable<IResult<T>> {
        return this.http.request('GET', `${this.projectsCoreName}${id}/${this.statusCoreName}`, { page, page_size, q }, this.getStatusesModel);
    }

    private getStatusesModel(data: object): DataListModel<IProjectStatus> {
        return new DataListModel<IProjectStatus>(data, ProjectStatusModel);
    }

    public createStatus(id: number, data: any): Observable<IResult> {
        return this.http.request('POST', `${this.projectsCoreName}${id}/${this.statusCoreName}${this.createStatusName}`, data);
    }

    public deleteStatus(projectId: number, statusId: number): Observable<IResult> {
        return this.http.request('POST', `${this.projectsCoreName}${projectId}/${this.statusCoreName}${statusId}/${this.deleteStatusName}`, { });
    }

    public updateStatus(projectId: number, statusId: number, data: any): Observable<IResult> {
        return this.http.request('POST', `${this.projectsCoreName}${projectId}/${this.statusCoreName}${statusId}/${this.updateStatusName}`, data);
    }

    public updateSort(projectId: number, status_ids: number[]): Observable<IResult> {
        return this.http.request('POST', `${this.projectsCoreName}${projectId}/${this.statusCoreName}${this.sortStatusName}`, { status_ids });
    }

    public getTags<T>(id: number, page: number, page_size: number, q?: string): Observable<IResult<T>> {
        return this.http.request('GET', `${this.projectsCoreName}${id}/${this.tagCoreName}`, { page, page_size, q }, this.getTagsModel);
    }

    private getTagsModel(data: object): DataListModel<IProjectTag> {
        return new DataListModel<IProjectTag>(data, ProjectTagModel);
    }

    public createTag<T>(id: number, name: string): Observable<IResult<T>> {
        return this.http.request('POST', `${this.projectsCoreName}${id}/${this.tagCoreName}${this.createTagName}`, { name });
    }

    public updateTag<T>(projectId: number, tagId: number, name: string): Observable<IResult<T>> {
        return this.http.request('POST', `${this.projectsCoreName}${projectId}/${this.tagCoreName}${tagId}/${this.updateTagName}`, { name });
    }

    public deleteTag<T>(projectId: number, tagId: number): Observable<IResult<T>> {
        return this.http.request('POST', `${this.projectsCoreName}${projectId}/${this.tagCoreName}${tagId}/${this.deleteTagName}`, { });
    }

    public getRoles<T>(id: number, page: number, page_size: number, q?: string): Observable<IResult<T>> {
        return this.http.request('GET', `${this.projectsCoreName}${id}/${this.userGroupCoreName}`, { page, page_size, q }, this.getRolesModel);
    }

    private getRolesModel(data: object): DataListModel<IProjectRole> {
        return new DataListModel<IProjectRole>(data, ProjectRoleModel);
    }

    public getRole<T>(projectId: number, userGroupId: number): Observable<IResult<T>> {
        return this.http.request('GET', `${this.projectsCoreName}${projectId}/${this.userGroupCoreName}${userGroupId}/`, { }, this.getRoleModel);
    }

    private getRoleModel(data: object): IProjectRole {
        return new ProjectRoleModel({ value: data });
    }

    public createRole(projectId: number, data: any): Observable<IResult> {
        const payload = RoleDto.adapt(data);

        return this.http.request('POST', `${this.projectsCoreName}${projectId}/${this.userGroupCoreName}${this.createRoleName}`, payload);
    }

    public deleteRole(projectId: number, userGroupId: number): Observable<IResult> {
        return this.http.request('POST', `${this.projectsCoreName}${projectId}/${this.userGroupCoreName}${userGroupId}/${this.deleteRoleName}`, { });
    }

    public updateRole(projectId: number, userGroupId: number, data: any): Observable<IResult> {
        const payload = RoleDto.adapt(data);

        return this.http.request('POST', `${this.projectsCoreName}${projectId}/${this.userGroupCoreName}${userGroupId}/${this.updateRoleName}`, payload);
    }

    public addUserToRole(projectId: number, userGroupId: number, user_id: number): Observable<IResult> {
        return this.http.request('POST', `${this.projectsCoreName}${projectId}/${this.userGroupCoreName}${userGroupId}/${this.getRoleUsersName}${this.addUserToRoleName}`, { user_id });
    }

    public deleteUsersFromRole(projectId: number, userGroupId: number, user_ids: number[]): Observable<IResult> {
        return this.http.request('POST', `${this.projectsCoreName}${projectId}/${this.userGroupCoreName}${userGroupId}/${this.getRoleUsersName}${this.deleteUsersFromRoleName}`, { user_ids });
    }

    public inviteUsersToRole(userGroupId: number, emails: string[]): Observable<IResult> {
        return this.http.request('POST', `${this.userGroupCoreName}${userGroupId}/${this.getRoleUsersName}${this.inviteUsersToRoleName}`, { emails });
    }

    public getRules<T>(id: number, page: number, page_size: number,  q?: string): Observable<IResult<T>> {
        return this.http.request('GET', `${this.projectsCoreName}${id}/${this.ruleCoreName}`, { page, page_size, q }, this.getRulesModel);
    }

    private getRulesModel(data: object): DataListModel<IProjectRule> {
        return new DataListModel<IProjectRule>(data, ProjectRuleModel);
    }

    public createRule(id: number, data: any): Observable<IResult> {
        return this.http.request('POST', `${this.projectsCoreName}${id}/${this.ruleCoreName}${this.createRuleName}`, data);
    }

    public deleteRule(projectId: number, ruleId: number): Observable<IResult> {
        return this.http.request('POST', `${this.projectsCoreName}${projectId}/${this.ruleCoreName}${ruleId}/${this.deleteRuleName}`, { });
    }

    public updateRule(projectId: number, ruleId: number, data: any): Observable<IResult> {
        return this.http.request('POST', `${this.projectsCoreName}${projectId}/${this.ruleCoreName}${ruleId}/${this.updateRuleName}`, data);
    }
}
