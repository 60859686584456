import {
    IGeoStatus,
    IGeoUser,
    IGeoAdditional,
    IGeoTeam, IBreadcrumb,
} from '../../interfaces';
import { DataListModel } from '../general/data-list.model';
import { BreadcrumbModel } from '../general/breadcrumb.model';
import { Utils } from '../../helpers';
import { GeoStatusModel } from './geo-status.model';

export class GeoAdditionalModel implements IGeoAdditional {
    private readonly _teamsMap: { [key: number]: IGeoTeam } = {};
    private readonly _usersMap: { [key: number]: IGeoUser } = {};
    private readonly _statusesMap: { [key: number]: IGeoStatus } = {};
    private readonly _breadcrumbs: IBreadcrumb[];

    constructor(data: any) {
        this._teamsMap = Utils.mapByField<IGeoTeam>(data['teams'], 'id');
        this._usersMap = Utils.mapByField<IGeoUser>(data['users'], 'id');
        this._statusesMap = Utils.mapByField<IGeoStatus>(data['statuses'], 'id', GeoStatusModel);
        this._breadcrumbs = new DataListModel<IBreadcrumb>({ results: data['breadcrumbs'] }, BreadcrumbModel).data;
    }

    get teamsMap() {
        return this._teamsMap;
    }

    get usersMap() {
        return this._usersMap;
    }

    get statusesMap() {
        return this._statusesMap;
    }

    get breadcrumbs() {
        return this._breadcrumbs;
    }
}
