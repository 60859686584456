import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component, DestroyRef,
    EventEmitter, inject,
    Input,
    OnInit, Output,
    QueryList,
    signal,
    ViewChildren
} from '@angular/core';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle, NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FilterArrayPipe } from '../../../pipe';
import { SearchInputComponent } from '../../search-input/search-input.component';
import { IProjectTag } from '../../../interfaces';
import { ConfirmModalComponent } from '../../confirm-modal/confirm-modal.component';
import { ITagAction } from '../tags.component';

@Component({
    selector: 'app-tag',
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        FilterArrayPipe,
        NgbDropdown,
        NgbDropdownMenu,
        NgbDropdownToggle,
        ReactiveFormsModule,
        SearchInputComponent,
        FormsModule,
        NgbTooltip,
    ],
})
export class TagComponent implements OnInit {
    @Input() public tag!: IProjectTag;
    @Input() public isSelected: boolean = false;
    @Output() onTagAction: EventEmitter<ITagAction> = new EventEmitter<ITagAction>();

    @ViewChildren(NgbDropdown) dropdowns!: QueryList<NgbDropdown>;

    public tagEditableId = signal<number | null>(null);

    private destroy = inject(DestroyRef);

    constructor(private modalService: NgbModal) {

    }

    ngOnInit(): void {
    }

    public closeAllDropdowns() {
        this.dropdowns.forEach((dropdown) => {
            if (dropdown.isOpen()) {
                dropdown.close();
            }
        });
    }

    public toggleModal(tag: IProjectTag): void {
        const modalRef = this.modalService.open(ConfirmModalComponent, {centered: true, size: 'sm'});

        modalRef.componentInstance.data = {
            title: `Видалити тег “${tag.name}”?`,
            content: 'Цей тег буде повністю видалено з усіх задач.',
            isIrreversibleAction: true,
        };

        modalRef.componentInstance.onConfirm
            .pipe(takeUntilDestroyed(this.destroy))
            .subscribe(() => {
                this.deleteTag(tag.id);
            });
    }

    public selectTag(id: number): void {
        this.onTagAction.emit({id, type: 'select'});
    }

    public unselectTag(id: number): void {
        this.onTagAction.emit({id, type: 'unselect'});
    }

    public updateTag(name: string, id: number): void {
        this.onTagAction.emit({id, name, type: 'update'});
        this.tagEditableId.set(null);
    }

    private deleteTag(id: number): void {
        this.onTagAction.emit({id, type: 'delete'});
    }

}
