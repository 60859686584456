import { IDragSortableItem } from '../../interfaces';

export class DragSortableListModel {
    private readonly _data: IDragSortableItem[] = [];

    constructor(data: any[], key: string) {
        data.forEach((item: any) => {
            this._data.push({ id: item['id'], name: item[key] });
        });
    }

    get data() {
        return this._data;
    }
}
