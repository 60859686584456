import { JsonPipe, NgClass, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef, EventEmitter,
    inject, Input,
    OnInit, Output, signal,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    FormsModule,
    ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { ProjectsService, ToastsService, ValidationLayerService } from '../../../services';
import { first } from 'rxjs';
import { IResult } from '../../../interfaces';
import { UserAdditionalModel } from '../../../models';


@Component({
    selector: 'app-invite-user-modal',
    templateUrl: './invite-user-modal.component.html',
    styleUrls: ['./invite-user-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        TranslateModule,
        RouterLink,
        NgClass,
        NgSelectModule,
        JsonPipe,
    ]
})
export class InviteUserModalComponent implements OnInit {
    @Input() public data!: { roleId: number };
    @Output() public onUpdate: EventEmitter<boolean> = new EventEmitter();

    public levels = new UserAdditionalModel().levels;
    public inviteUsersForm = new UntypedFormGroup({
        email: new UntypedFormControl([], Validators.required),
        levelId: new UntypedFormControl(null, Validators.required),
    })
    public loadingBtn = signal<boolean>(false);

    private destroy = inject(DestroyRef);

    constructor (
        public activeModal: NgbActiveModal,
        private validationLayer: ValidationLayerService,
        private toastsService: ToastsService,
        private projectService: ProjectsService,

    ) {
        this.addUser = this.addUser.bind(this);
    }

    ngOnInit(): void { }

    public addUser(email: string) {
        if (!this.validationLayer.isValidEmailByString(email)) {
            this.toastsService.show('Введена пошта не валідна', { classname: 'bg-danger' });

            return '';
        }

        this.inviteUsersForm.controls['email'].setValue([...this.inviteUsersForm.value['email'], email]);

        return email;
    }

    public onSubmit(): void {
        this.loadingBtn.set(true);

        this.projectService.inviteUsersToRole(this.data.roleId, this.inviteUsersForm.value)
            .pipe(first())
            .subscribe((result: IResult) => {
                if (result.isSuccess) {
                    this.toastsService.show('Користувачі успішно додані', { classname: 'bg-success' });
                    this.onUpdate.emit(true);
                    this.activeModal.close();
                }

                this.loadingBtn.set(false);
            })

    }

    get f() {
        return this.inviteUsersForm.controls;
    }
}
