import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, Observable, tap } from 'rxjs';
import { AppState, selectUserNeeds2Fa, loadUser } from '../store';
import { ToastsService } from '../services';
import { Utils } from '../helpers';

let isUserLoadNeeded = true;
let retryCount = 3;

export const check2faGuard = (childRoute: ActivatedRouteSnapshot): Observable<boolean> => {
    const store = inject(Store<AppState>);
    const router = inject(Router);
    const toastsService = inject(ToastsService);

    const nextUrl = router.getCurrentNavigation()?.finalUrl?.toString() ?? '';

    return store.select(selectUserNeeds2Fa)
        .pipe(
            tap((result) => {
                // TODO: відрефакторити це лайно
                if (Utils.isNil(result.is2FaActive) && retryCount > 0) {
                    if (isUserLoadNeeded) {
                        retryCount--;
                        isUserLoadNeeded = false;
                        store.dispatch(loadUser());
                    } else {
                        isUserLoadNeeded = true;
                    }
                }
            }),
            filter(({ is2FaActive, is2FaRequired }) => !Utils.isNil(is2FaActive) && !Utils.isNil(is2FaRequired)),
            map(({ is2FaActive, is2FaRequired }) => {
                isUserLoadNeeded = true;
                retryCount = 3;

                const isSecurityPage = nextUrl.includes('/user-settings/security');

                if (is2FaActive || !is2FaRequired || isSecurityPage) {
                    return true;
                }

                toastsService.show('Встановіть двофакторну автентифікацію!', {
                    classname: 'bg-danger text-light',
                    delay: 3000
                });

                router.navigate(['/user-settings/security']);

                return false;
            })
        );
};
