import { inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { WebsocketService } from '../websocket/websocket.service';
import { AuthService } from '../auth/auth.service';
import { first } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState, logout } from '../../store';

interface IAuthTokenService {
    accessToken: string | null;
    isTokenExpired(): boolean;
    userLogout(): void;
}

@Injectable({
    providedIn: 'root'
})
export class AuthTokenService implements IAuthTokenService {
    private _accessToken: string | null = null;
    private _refreshToken: string | null = null;
    private readonly preTime = 60;
    private readonly store = inject(Store<AppState>);

    constructor(
        private router: Router,
        private injector: Injector
    ) { }

    set accessToken(token: string | null) {
        this._accessToken = token;
    }

    get accessToken() {
        return this._accessToken;
    }

    set refreshToken(token: string | null) {
        this._refreshToken = token;
    }

    get refreshToken() {
        return this._refreshToken;
    }

    public isTokenExpired(): boolean {
        const authToken = JSON.parse(<string>localStorage.getItem('authToken'));
        const dateNow = Math.floor(new Date().getTime() / 1000);

        if (!authToken || (authToken && Object.keys(authToken).length === 0)) {
            return false;
        }

        this._accessToken = authToken.accessToken;
        this._refreshToken = authToken.refreshToken;

        return dateNow >= (authToken.expirationTime - this.preTime);
    }



    public userLogout(): void {
        if (this.wsService.isConnected) {
            this.wsService.disConnect();
        }

        if (this._accessToken) {
            this.authService.logout().pipe(first()).subscribe(() => {});
        }

        this.accessToken = null;
        this.refreshToken = null;
        this.store.dispatch(logout());
        localStorage.clear();
        this.router.navigate((['/auth/login']));
    }

    private get wsService(): WebsocketService {
        return this.injector.get(WebsocketService);
    }

    private get authService(): AuthService {
        return this.injector.get(AuthService);
    }
}
