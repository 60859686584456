import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'stripHtml',
    standalone: true
})
export class StripHtmlPipe implements PipeTransform {
    transform(value: string): string {
        if (!value) {
            return '';
        }

        const textArea = document.createElement('textarea');
        textArea.innerHTML = value;
        const decodedValue = textArea.value;

        return decodedValue.replace(/<\/?[^>]+(>|$)/g, '');
    }
}
