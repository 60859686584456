<div class="modal-header p-4 justify-content-between">
    <h6 class="modal-title">
        @if (boardDetails.details?.id) {
            Редагувати дошку
        } @else {
            Додати дошку
        }
    </h6>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span >&times;</span>
    </button>
</div>
<div class="modal-body pt-0 p-4">
    <form [formGroup]="boardForm" (ngSubmit)="onSubmit()">
        <label class="form-label">Іконка</label>
        <div class="icon-container mb-3">
            <div class="btn-group me-4" ngbDropdown>
                <button type="button" class="toggle-button after-none" [ngStyle]="{ 'backgroundColor': f['iconColor'].value }"
                        data-bs-toggle="dropdown"  aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                    @if (logoPreview) {
                        <img [src]="logoPreview" alt="logo">
                    } @else {
                        <i class="bx {{ f['iconClass'].value }}"></i>
                    }
                </button>
                <div class="dropdown-menu dropdown-menu-start py-3" ngbDropdownMenu>
                    <div class="icon-block d-flex flex-column">
                        <div class="upload px-3">
                            @if (f['image'].value) {
                                <div class="icon-wrp" (click)="selectLogo('old')">
                                    <img [src]="f['image'].value" alt="logo">
                                </div>
                            }

                            @if (f['file'].value) {
                                <div class="icon-wrp" (click)="selectLogo('new')">
                                    <img [src]="f['file'].value" alt="logo">
                                </div>
                            }

                            <label class="btn-gray justify-content-center" [class.uploaded]="f['image'].value"  for="board-icon-upload">
                                @if (f['image'].value) {
                                    <i class="las la-plus la-lg"></i>
                                } @else {
                                    <i class='bx bx-image-add fs-18'></i> Завантажити іконку
                                }
                            </label>

                            <input class="form-control d-none" value="" id="board-icon-upload"
                                   type="file" accept="image/*, image/gif, image/jpegf, image/webp, image/avif, image/svg+xml"
                                   (change)="fileChange($event)">
                        </div>
                        <div class="px-3 mt-3">
                            <p>Або створіть іконку</p>
                            <div class="d-flex flex-column">
                                <div class="colors">
                                    @for (color of colors; track $index) {
                                        <div [ngStyle]="{ 'backgroundColor': color }"
                                             [class.selected]="color === f['iconColor'].value"
                                             (click)="f['iconColor'].setValue(color); selectLogo('icon')"
                                        ></div>
                                    }
                                </div>
                                <div class="icons mt-3">
                                    @for (icon of icons; track $index) {
                                        <div [ngStyle]="{ 'backgroundColor': f['iconColor'].value }"
                                             [class.selected]="icon === f['iconClass'].value"
                                             (click)="f['iconClass'].setValue(icon); selectLogo('icon')">
                                            <i class='bx {{ icon }}'></i>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mb-3">
            <label for="name" class="form-label">Назва дошки</label>
            <input type="text" class="form-control" id="name"
                   [placeholder]="'Введіть назву дошки'"
                   required formControlName="name"
                   [ngClass]="{ 'is-invalid': f['name'].touched && f['name'].errors }">
            @if (f['name'].touched && f['name'].errors) {
                <div class="invalid-feedback">
                    Введіть коректну назву дошки
                </div>
            }
        </div>

        <div class="mb-3">
            <label class="form-label after-none">Рівень доступу</label>
            <ng-select class="default-list"
                       bindLabel="name"
                       [items]="userGroups['groups']"
                       [placeholder]="'Оберіть групи користувачів'"
                       [clearable]="false"
                       [multiple]="true"
                       [bindLabel]="'name'"
                       [bindValue]="'id'"
                       (open)="onUserGroupsSelectOpen()"
                       (search)="onUserGroupsSearch($event)"
                       formControlName="userGroupIds"
            >
                <ng-template ng-option-tmp let-item="item">
                    <div class="ng-option-name">
                        {{ item.name }}
                    </div>
                </ng-template>
            </ng-select>
        </div>

        <div class="d-flex align-items-center mb-3">
            <div class="form-check default form-check-outline form-check-info p-0">
                <input class="form-check-input" type="checkbox" formControlName="isParser" id="checkbox"
                (change)="isParserChange($event)">
                <label class="form-check-label ms-2" for="checkbox"><b>Дошка для парсингу</b></label>
            </div>
        </div>

        @if (f['isParser'].value) {
            <div class="mb-3">
                <label class="form-label after-none">Категорії каналів для парсингу</label>
                <ng-select class="default-list"
                           bindLabel="name"
                           [items]="sourceCategories['categories']"
                           [placeholder]="'Оберіть категорії'"
                           [clearable]="false"
                           [multiple]="true"
                           [bindLabel]="'name'"
                           [bindValue]="'id'"
                           (open)="onSourceCategorySelectOpen()"
                           (search)="onSourceCategorySearch($event)"
                           [loading]="sourceCategories['loading']"
                           formControlName="sourceCategories"
                >
                    <ng-template ng-option-tmp let-item="item">
                        <div class="ng-option-name">
                            {{ item.name }}
                        </div>
                    </ng-template>
                </ng-select>
            </div>

            @if (Utils.getObjectValues(filters).length > 0) {
                @for (item of Utils.getObjectValues(filters); track item.filter.id) {
                    <div class="mb-3">
                        <label class="form-label after-none">{{ item.filter.name }}</label>
                        <ng-select class="default-list"
                                   bindLabel="name"
                                   [items]="filters[item.filter.id]['categories']"
                                   [placeholder]="'Оберіть категорії'"
                                   [clearable]="false"
                                   [multiple]="true"
                                   [bindLabel]="'name'"
                                   [bindValue]="'id'"
                                   (open)="onFilterSelectOpen(item.filter.id)"
                                   (search)="onFilterSearch($event, item.filter.id)"
                                   [loading]="filters[item.filter.id]['loading']"
                                   formControlName="filter-{{item.filter.id}}"
                        >
                            <ng-template ng-option-tmp let-item="item">
                                <div class="ng-option-name">
                                    {{ item.name }}
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                }
            }
        }


        <div class="buttons d-flex mt-4 justify-content-end">
            <button class="btn btn-gray" (click)="activeModal.dismiss('Cross click')">Відміна</button>

            <button class="btn btn-settings ms-3" type="submit" [ngClass]="{ 'loading': loadingBtn() }" [disabled]="boardForm.invalid">
                @if (boardDetails.details?.id) {
                    Зберегти зміни
                } @else {
                    Створити дошку
                }
            </button>
        </div>
    </form>
</div>
