import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

export class Utils {
    static isNil(value: any): boolean {
        return Boolean(value === null || value === undefined);
    }

    static checkIfFormIsPristine(form: UntypedFormGroup, initialFormValues: any): boolean {
        const currentFormValues = form.getRawValue();

        return JSON.stringify(currentFormValues) === JSON.stringify(initialFormValues);
    }

    static getObjectValues(data: any): any[] {
        return Object.values(data);
    }

    static getChildRoute(route: ActivatedRoute): ActivatedRoute {
        while (route.firstChild) {
            route = route.firstChild;
        }

        return route;
    }

    static mapByField<T>(arr: T[], keyField: keyof T, modelClass?: { new (data: any): T }): { [key: string | number]: T } {
        if (!arr || !arr.length) return {};

        return arr.reduce((acc, item: T) => {
            acc[item[keyField] as any] = modelClass ? new modelClass({ value: item }) : item;
            return acc;
        }, {} as { [key: string | number]: T });
    }

    static pluck<T, K extends keyof T>(arr: T[], key: K): T[K][] {
        return arr.map((item: T) => item[key]);
    }
}
