<header id="page-topbar" data-scroll-header (window:scroll)="windowScroll()">
    <div class="layout-width">
        <div class="navbar-header">
            <div class="search-block" [ngClass]="{ 'project-page': user() && user()!.isAdmin && isProjectPage}">
                @if (user() && isProjectPage) {
                    <div>
                        <ng-select class="select-container project-select" bindLabel="name"
                                   [items]="projects"
                                   [clearable]="false"
                                   [(ngModel)]="selectedProject"
                                   (change)="changedSelectedProject($event)">
                            <ng-template ng-option-tmp let-item="item">
                                <div class="ng-option-name">
                                    <img [src]="item.image" alt="" style="width: 30px; border-radius: 4px; object-fit: contain; margin-right: 8px;">
                                    {{ item.name }}
                                </div>
                            </ng-template>

                            @if (user()!.isAdmin) {
                                <ng-template ng-footer-tmp>
                                    <div class="ng-option-name">
                                        <button class="btn-add-project" (click)="openModal(createProjectModal)">
                                            <i class="las la-plus-circle la-lg"></i> Створити новий проєкт
                                        </button>
                                    </div>
                                </ng-template>
                            }
                        </ng-select>
                    </div>

                    <ng-template #createProjectModal let-modal>
                        <div class="modal-header p-4 justify-content-between">
                            <h6 class="modal-title">Створити проєкт</h6>
                            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
                                <span >&times;</span>
                            </button>
                        </div>
                        <div class="modal-body pt-0 p-4">
                            <app-create-project-form [isModal]="true"></app-create-project-form>
                        </div>
                    </ng-template>
                }

                <app-search></app-search>
            </div>

            <div class="d-flex align-items-center">

                <div class="dropdown d-md-none topbar-head-dropdown header-item" ngbDropdown>
                    <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                        <i class="bx bx-search fs-22"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-search-dropdown" ngbDropdownMenu>
                        <form class="p-3">
                            <div class="form-group m-0">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                                    <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="ms-1 header-item  d-flex">
                    <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" data-toggle="fullscreen" (click)="fullscreen()">
                        <i class='bx bx-fullscreen fs-22'></i>
                    </button>
                </div>

                <div class="ms-1 header-item d-none d-flex">
                    <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode">
                        <i class='bx bx-moon fs-22' (click)="changeMode('dark')"></i>
                        <i class='bx bx-sun fs-22' (click)="changeMode('light')"></i>
                    </button>
                </div>

               <app-notifications></app-notifications>

                <div class="dropdown header-item topbar-user" ngbDropdown>
                    <button type="button" class="btn transparent" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                        <span class="d-flex align-items-center">
                            @if (user()) {
                                @if (user()!.avatar) {
                                    <img class="rounded-circle header-profile-user" [src]="user()!.avatar" alt="Header Avatar">
                                } @else {
                                    <div class="avatar-placeholder">
                                        {{ user()!.username.slice(0, 1) }}
                                    </div>
                                }

                                <span class="text-start ms-xl-2 d-flex flex-column">
                                    <span class=" d-xl-inline-block ms-1 fw-medium user-name-text">{{ user()!.username }}</span>
                                    <span class=" d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{{ user()!.email }}</span>
                                </span>
                            }
                        </span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                        <!-- item-->
                        <h6 class="dropdown-header">Вітаю, {{ user()?.firstName }}</h6>
                        <a class="dropdown-item" routerLink="/user-settings"><i class="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i> <span class="align-middle">Налаштування</span></a>

                        @if (user()?.isStaff) {
                            <a class="dropdown-item" href="/admin"><i class="mdi mdi-tune text-muted fs-16 align-middle me-1"></i> <span class="align-middle">Адміністративна панель</span></a>
                        }

                        <a class="dropdown-item" href="javascript: void(0);" (click)="logout()"><i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span class="align-middle" data-key="t-logout">Вихід</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>

<!-- removeNotificationModal -->
<ng-template #removenotification let-modal>
    <!-- <div class="modal-dialog modal-dialog-centered"> -->
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="NotificationModalbtn-close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="mt-2 text-center">
                <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                    <h4>Are you sure ?</h4>
                    <p class="text-muted mx-4 mb-0">Are you sure you want to remove this Notification ?</p>
                </div>
            </div>
            <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                <button type="button" class="btn w-sm btn-light" data-bs-dismiss="modal" (click)="modal.dismiss('Cross click')">Close</button>
                <button type="button" class="btn w-sm btn-danger" id="delete-notification" (click)="notificationDelete()">Yes, Delete It!</button>
            </div>
        </div>

    </div><!-- /.modal-content -->

</ng-template>
