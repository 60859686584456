import { createReducer, on } from '@ngrx/store';
import {
    loadUser, loadUserSuccess,  loadUserFailure,
    loadImpersonatedUser, loadImpersonatedUserSuccess, loadImpersonatedUserFailure, loadClearImpersonatedUser
} from './user.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { IUser } from '../../interfaces';

export interface UserState {
    user: IUser | null;
    impersonatedUser: IUser | null;
    loading: boolean;
    error: HttpErrorResponse | null;
}

export const initialState: UserState = {
    user: null,
    impersonatedUser: null,
    loading: false,
    error: null,
};

export const userReducer = createReducer(
    initialState,

    on(loadUser, (state) => ({ ...state, loading: true })),
    on(loadUserSuccess, (state, { user }) => ({ ...state, loading: false, user })),
    on(loadUserFailure, (state, { error }) => ({ ...state, loading: false, error })),

    on(loadImpersonatedUser, (state) => ({ ...state, loading: true })),
    on(loadImpersonatedUserSuccess, (state, { impersonatedUser }) => ({ ...state, loading: false, impersonatedUser })),
    on(loadImpersonatedUserFailure, (state, { error }) => ({ ...state, loading: false, error })),
    on(loadClearImpersonatedUser, (state) => ({ ...state, impersonatedUser: null, error: null })),
);
