import { ActionReducer, ActionReducerMap, createAction } from '@ngrx/store';
import { userReducer, UserState } from './user/user.reducer';
import { UserEffects } from './user/user.effects';
import { projectReducer, ProjectsState } from './project/project.reducer';
import { ProjectEffects } from './project/project.effects';
import { taskReducer, TasksState } from './task/task.reducer';
import { TaskEffects } from './task/task.effects';

export interface AppState {
    user: UserState;
    projects: ProjectsState;
    tasks: TasksState;
}

export const effects = [
    UserEffects,
    ProjectEffects,
    TaskEffects,
];

export const reducers: ActionReducerMap<AppState> = {
    user: userReducer,
    projects: projectReducer,
    tasks: taskReducer,
};

export const logout = createAction('[Auth] Logout');

export function clearStateOnLogout(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
    return (state, action) => {
        if (action.type === logout.type) {
            state = undefined;
        }

        return reducer(state, action);
    };
}
