import {
    IFile,
    IGeoAdditional,
    IGeoStatus,
    IGeoSuggestion,
    IGeoTeam,
    ITaskCard
} from '../../interfaces';
import * as moment from 'moment/moment';
import { TaskCardModel } from '../task/task-card.model';
import { TaskMediaModel } from '../task/task-media.model';

export class GeoSuggestionModel implements IGeoSuggestion {
    private readonly _id: number;
    private readonly _latitude: number;
    private readonly _longitude: number;
    private readonly _timeCode: string;
    private readonly _comment: string;
    private readonly _isPrivate: boolean;
    private readonly _date: string;
    private readonly _boardName: string;
    private readonly _userId: number;
    private readonly _teamId: number;
    private readonly _statusId: number;
    private readonly _status: IGeoStatus;
    private readonly _team: IGeoTeam;
    private readonly _files: IFile[] = [];
    private readonly _taskDetails: ITaskCard;

    constructor(data: { value: any, additional: IGeoAdditional }) {
        this._id = data['value']['id'] ?? null;
        this._latitude = data['value']['latitude'] ?? null;
        this._longitude = data['value']['longitude'] ?? null;
        this._timeCode = data['value']['timecode'] ?? null;
        this._comment = data['value']['comment'] ?? null;
        this._isPrivate = data['value']['is_private'] ?? null;
        this._userId = data['value']['user_id'] ?? null;
        this._statusId = data['value']['status_id'] ?? null;
        this._teamId = data['value']['team_id'] ?? null;
        this._date = moment(data['value']['suggested_at']).format('DD.MM.YYYY HH:mm:ss');
        this._boardName = data['value']['task']['board']['name'] ?? null;
        this._taskDetails = new TaskCardModel({ value: data['value']['task'] });
        this._team = data.additional.teamsMap[data['value']['team_id']] ?? null;
        this._status = data.additional.statusesMap[data['value']['status_id']] ?? null;

        Object.keys(data['value']['files']).map((key: string) => {
            this._files.push(new TaskMediaModel(data['value']['files'][key]));
        });
    }

    get id() {
        return this._id;
    }

    get latitude() {
        return this._latitude;
    }

    get longitude() {
        return this._longitude;
    }

    get timeCode() {
        return this._timeCode;
    }

    get date() {
        return this._date;
    }

    get comment() {
        return this._comment;
    }

    get isPrivate() {
        return this._isPrivate;
    }

    get userId() {
        return this._userId;
    }

    get statusId() {
        return this._statusId;
    }

    get status() {
        return this._status;
    }

    get team() {
        return this._team;
    }

    get teamId() {
        return this._teamId;
    }

    get taskDetails() {
        return this._taskDetails;
    }

    get files() {
        return this._files;
    }

    get boardName() {
        return this._boardName;
    }
}
