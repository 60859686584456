import { NgClass, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    inject,
    OnInit, signal,
} from '@angular/core';
import {
    FormsModule,
    ReactiveFormsModule,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { SupportService, ToastsService } from 'src/app/services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IResult } from '../../interfaces';

@Component({
    selector: 'app-support-modal',
    templateUrl: './support-modal.component.html',
    styleUrls: ['./support-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        TranslateModule,
        RouterLink,
        NgClass,
    ]
})
export class SupportModalComponent implements OnInit {
    public loadingBtn = signal<boolean>(false);

    public supportForm = new UntypedFormGroup({
        description: new UntypedFormControl(null, [Validators.required, Validators.minLength(10)]),
        file: new UntypedFormControl(null),
        fileName: new UntypedFormControl(null),
        fileType: new UntypedFormControl(null),
    });

    private destroy = inject(DestroyRef);

    constructor (
        public activeModal: NgbActiveModal,
        private toastsService: ToastsService,
        private supportService: SupportService,
        private cd: ChangeDetectorRef,
    ) { }

    ngOnInit(): void {
        this.cd.detectChanges();
    }

    public fileChange(event: any) {
        let fileList: any = (event.target as HTMLInputElement);
        let file: File = fileList.files[0];
        const reader = new FileReader();

        reader.onload = (event: any) => {
            const maxSize = 10 * 1024 * 1024; // 10 MB

            if (file && file.size > maxSize) {
                this.toastsService.show('Файл перевищує максимально допустимий розмір 10MB', { classname: 'bg-danger text-light', delay: 3000 });

                return;
            }

            this.supportForm.controls['file'].setValue(event.target.result);
            this.supportForm.controls['fileType'].setValue(file.type);
            this.supportForm.controls['fileName'].setValue(file.name);
            this.supportForm.controls['file'].markAsDirty();
            this.cd.detectChanges();
        }
        reader.readAsDataURL(file);
    }

    public removeFile() {
        this.supportForm.controls['file'].setValue(null);
        this.supportForm.controls['fileName'].setValue(null);
        this.supportForm.controls['file'].markAsDirty();
        this.cd.detectChanges();
    }

    get f() {
        return this.supportForm.controls;
    }

    public onSubmit(): void {
        this.loadingBtn.set(true);

        if (this.supportForm.invalid) {
            this.loadingBtn.set(false);
            this.toastsService.show('Введено некоректні дані', { classname: 'bg-danger text-light', delay: 3000 });

            return;
        }

        this.supportService.createSupportTicket(this.supportForm.value)
            .pipe(takeUntilDestroyed(this.destroy))
            .subscribe((result: IResult) => {
                if (result.isSuccess) {
                    this.toastsService.show('Дякуємо! Ваше повідомлення успішно надіслано', {classname: 'bg-success text-light', delay: 3000});
                    this.activeModal.close();
                }

                this.loadingBtn.set(false);
            });
    }
}
