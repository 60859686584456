import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnInit,
  TemplateRef
} from '@angular/core';
import { ToastsService } from '../../services';
import { NgbToast } from '@ng-bootstrap/ng-bootstrap';
import { NgIf, NgTemplateOutlet } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-toasts',
  template: `
    @for (toast of toastService.toasts.value; track $index) {
      <ngb-toast
          [class]="toast.classname"
          [autohide]="true"
          [delay]="toast.delay || 5000"
          (hidden)="toastService.remove(toast)"
      >
        <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
          <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
        </ng-template>

        <ng-template #text>{{ toast.textOrTpl | translate }}</ng-template>
      </ngb-toast>
    }
  `,
  host: {'class': 'toast-container position-fixed bottom-0 left-0 p-3', 'style': 'z-index: 1200'},
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgbToast,
    NgIf,
    NgTemplateOutlet,
    TranslateModule
  ]
})
export class ToastsContainer implements OnInit {
  private destroy = inject(DestroyRef);

  constructor(
      public toastService: ToastsService,
      private cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.toastService.toasts
        .pipe(takeUntilDestroyed(this.destroy))
        .subscribe(() => {
          this.cd.detectChanges();
        })
  }

  isTemplate(toast: { textOrTpl: any; }) { return toast.textOrTpl instanceof TemplateRef; }
}
