import { ITaskAdditional, IProjectAdditional, ITaskCardAdditional, IBreadcrumb } from '../../interfaces';
import { TaskAdditionalModel } from './task-additional.model';
import { DataListModel } from '../general/data-list.model';
import { BreadcrumbModel } from '../general/breadcrumb.model';

export class TaskCardAdditionalModel implements ITaskCardAdditional {
    private readonly _taskAdditional: ITaskAdditional;
    private readonly _projectAdditional: IProjectAdditional;
    private readonly _breadcrumbs: IBreadcrumb[] = [];

    constructor(data: any) {
        this._taskAdditional = new TaskAdditionalModel(data) ?? null;
        this._projectAdditional = data['projectAdditional'] ?? null;
        this._breadcrumbs = new DataListModel<IBreadcrumb>({ results: data['breadcrumbs'] }, BreadcrumbModel).data;
    }

    get taskAdditional() {
        return this._taskAdditional;
    }

    get projectAdditional() {
        return this._projectAdditional;
    }

    get breadcrumbs() {
        return this._breadcrumbs;
    }
}
