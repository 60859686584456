import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TasksState } from './task.reducer';

export const selectTasksState = createFeatureSelector<TasksState>('tasks');

export const selectTasks = createSelector(selectTasksState, (state) => {
    return state.tasks;
});

export const selectTasksError = createSelector(selectTasksState, (state) => state.error);
