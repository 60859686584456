import {
    Component,
    OnInit,
    EventEmitter,
    Output,
    Inject,
    ViewChild,
    TemplateRef,
    inject,
    DestroyRef, ChangeDetectorRef, ChangeDetectionStrategy, signal
} from '@angular/core';
import { DOCUMENT, NgClass } from '@angular/common';

//Logout
import { NavigationEnd, Router, RouterLink } from '@angular/router';

// Language
import { TranslateService } from '@ngx-translate/core';
import { allNotification, messages } from './data';
import {
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownToggle,
    NgbModal,
    NgbNav, NgbNavContent,
    NgbNavItem, NgbNavLink, NgbNavOutlet
} from '@ng-bootstrap/ng-bootstrap';
import { SimplebarAngularModule } from 'simplebar-angular';
import { FormsModule } from '@angular/forms';
import { AuthTokenService } from '../../services';
import { NgSelectModule } from '@ng-select/ng-select';
import { combineLatest, filter, mergeMap, Observable, of } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IProject, IUser, IDataList } from '../../interfaces';
import { Store } from '@ngrx/store';
import {
    AppState,
    selectUser,
    selectProjects,
    selectSelectedProject,
    setSelectedProject,
    loadProjects
} from '../../store';
import { map } from 'rxjs/operators';
import { NotificationsComponent } from './notifications/notifications.component';
import { SearchComponent } from './search/search.component';
import { CreateProjectFormComponent } from '../create-project-form/create-project-form.component';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        SimplebarAngularModule,
        NgClass,
        NgbDropdown,
        NgbDropdownMenu,
        NgbDropdownToggle,
        NgbNav,
        NgbNavItem,
        NgbNavContent,
        NgbNavOutlet,
        RouterLink,
        FormsModule,
        NgbNavLink,
        NgSelectModule,
        NotificationsComponent,
        SearchComponent,
        CreateProjectFormComponent,
    ],
    standalone: true
})
export class TopbarComponent implements OnInit {
    messages: any
    element: any;
    mode: string | undefined;
    @Output() mobileMenuButtonClicked = new EventEmitter();
    allnotifications: any
    flagvalue: any;
    valueset: any;
    countryName: any;
    cookieValue: any;
    total = 0;
    cart_length: any = 0;
    totalNotify: number = 0;
    newNotify: number = 0;
    readNotify: number = 0;
    isDropdownOpen = false;

    public user = signal<IUser | null>(null);
    public isProjectPage: boolean = false;
    public projects!: IProject[];
    public selectedProject: string | undefined;

    @ViewChild('removenotification') removenotification !: TemplateRef<any>;
    notifyId: any;

    private store = inject(Store<AppState>);
    private selectUser$: Observable<IUser | null> = this.store.select(selectUser);
    private selectProjects$: Observable<IDataList<IProject> | null> = this.store.select(selectProjects);
    private selectSelectedProject$: Observable<IProject | null> = this.store.select(selectSelectedProject);
    private destroy = inject(DestroyRef);

    constructor(
        @Inject(DOCUMENT) private document: any, private modalService: NgbModal, private router: Router,
        public translate: TranslateService,
        private authTokenService: AuthTokenService, private cd: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.store.dispatch(loadProjects());
        this.setIsProjectPage(this.router.url);

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            takeUntilDestroyed(this.destroy)
        ).subscribe((event: any) => {
            this.setIsProjectPage(event.urlAfterRedirects);
        });

        this.selectUser$
            .pipe(
                filter((user): user is IUser => Boolean(user)),
                mergeMap((user: IUser) => {
                    this.user.set(user);

                    return combineLatest([
                        this.selectProjects$,
                        this.selectSelectedProject$
                    ]).pipe(
                        filter((results) => Boolean(results[0]) && Boolean(results[1])),
                        map(([projects, selectedProject]) => ({projects, selectedProject})),
                        takeUntilDestroyed(this.destroy),
                    )
                }),
                takeUntilDestroyed(this.destroy)
            )
            .subscribe(({ projects, selectedProject }) => {
                if (projects) {
                    this.projects = projects!.data as IProject[];
                }

                if (selectedProject?.name) {
                    this.selectedProject = selectedProject?.name;
                }

                this.cd.detectChanges();
            });

        this.element = document.documentElement;

        // Fetch Data
        this.allnotifications = allNotification;

        this.messages = messages;
    }

    public setIsProjectPage(url: string) {
        this.isProjectPage = url.includes('/project');
        this.cd.detectChanges();
    }

    /**
     * Toggle the menu bar when having mobile screen
     */
    toggleMobileMenu(event: any) {
        document.querySelector('.hamburger-icon')?.classList.toggle('open')
        event.preventDefault();
        this.mobileMenuButtonClicked.emit();
    }

    /**
     * Fullscreen method
     */
    fullscreen() {
        document.body.classList.toggle('fullscreen-enable');
        if (
            !document.fullscreenElement && !this.element.mozFullScreenElement &&
            !this.element.webkitFullscreenElement) {
            if (this.element.requestFullscreen) {
                this.element.requestFullscreen();
            } else if (this.element.mozRequestFullScreen) {
                /* Firefox */
                this.element.mozRequestFullScreen();
            } else if (this.element.webkitRequestFullscreen) {
                /* Chrome, Safari and Opera */
                this.element.webkitRequestFullscreen();
            } else if (this.element.msRequestFullscreen) {
                /* IE/Edge */
                this.element.msRequestFullscreen();
            }
        } else {
            if (this.document.exitFullscreen) {
                this.document.exitFullscreen();
            } else if (this.document.mozCancelFullScreen) {
                /* Firefox */
                this.document.mozCancelFullScreen();
            } else if (this.document.webkitExitFullscreen) {
                /* Chrome, Safari and Opera */
                this.document.webkitExitFullscreen();
            } else if (this.document.msExitFullscreen) {
                /* IE/Edge */
                this.document.msExitFullscreen();
            }
        }
    }

    /**
     * Open modal
     * @param content modal content
     */
    openModal(content: any) {
        // this.submitted = false;
        this.modalService.open(content, {centered: true, size: 'md'});
    }

    /**
     * Topbar Light-Dark Mode Change
     */
    changeMode(mode: string) {
        this.mode = mode;
        // this.eventService.broadcast('changeMode', mode);

        switch (mode) {
            case 'light':
                document.documentElement.setAttribute('data-bs-theme', "light");
                break;
            case 'dark':
                document.documentElement.setAttribute('data-bs-theme', "dark");
                break;
            default:
                document.documentElement.setAttribute('data-bs-theme', "light");
                break;
        }
    }


    /**
     * Logout the user
     */
    logout() {
        this.authTokenService.userLogout();
    }

    windowScroll() {
    }


    // Search Topbar


    // Remove Notification
    checkedValGet: any[] = [];

    notificationDelete() {
        if (this.notifyId == '1') {
            for (var i = 0; i < this.checkedValGet.length; i++) {
                for (var j = 0; j < this.allnotifications.length; j++) {
                    if (this.allnotifications[j].id == this.checkedValGet[i]) {
                        this.allnotifications.splice(j, 1)
                    }
                }
            }
        } else {
            for (var i = 0; i < this.checkedValGet.length; i++) {
                for (var j = 0; j < this.messages.length; j++) {
                    if (this.messages[j].id == this.checkedValGet[i]) {
                        this.messages.splice(j, 1)
                    }
                }
            }
        }
        this.calculatenotification()
        this.modalService.dismissAll();
    }

    calculatenotification() {
        this.totalNotify = 0;
        this.checkedValGet = []

        this.checkedValGet.length > 0 ? (document.getElementById("notification-actions") as HTMLElement).style.display = 'block' : (document.getElementById("notification-actions") as HTMLElement).style.display = 'none';
        if (this.totalNotify == 0) {
            document.querySelector('.empty-notification-elem')?.classList.remove('d-none')
        }
    }

    public changedSelectedProject(project: IProject): void {
        this.store.dispatch(setSelectedProject({ project }));
        this.router.navigate(['/project', project.id]);
    }
}
