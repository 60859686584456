import { IParsingSource, IParsingSourceAdditional, ISourceKeywordCategory } from '../../interfaces';

export class ParsingSourceModel implements IParsingSource {
    private readonly _id: number;
    private readonly _name: string;
    private readonly _avatar: string;
    private readonly _category: ISourceKeywordCategory;
    private readonly _date: string;
    private readonly _processed: number;
    private readonly _url: string;

    constructor(data: { value: any, additional: IParsingSourceAdditional }) {
        this._id = data['value']['id'] ?? null;
        this._name = data['value']['name'] ?? null;
        this._avatar = data['value']['avatar'] ?? '/assets/images/user-dummy-img.jpg';
        this._date = data['value']['date'] ?? null;
        this._processed = data['value']['processed'] ?? null;
        this._url = data['value']['url'] ?? null;
        this._category = data['additional']['categoriesMap'][data['value']['category_id']] ?? null;
    }

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    get avatar() {
        return this._avatar;
    }

    get category() {
        return this._category;
    }

    get date() {
        return this._date;
    }

    get processed() {
        return this._processed;
    }

    get url() {
        return this._url;
    }
}
