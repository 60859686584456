import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResult, IUser, IUserLog, IUserUpdateRequest } from 'src/app/interfaces';
import { HttpService } from '../http/http.service';
import { DataListModel, UserLogModel, UserModel } from '../../models';
import { UserDto } from '../../request-dto';
import { TUsersAction } from '../../helpers';

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    readonly usersCoreName = 'users/';
    readonly updateUserName = 'update/';
    readonly usersActionsName = 'actions/';
    readonly userResetPasswordName = 'reset-password/';
    readonly userDisable2faName = '2fa/disable/';
    readonly getUserLogsName = 'activity/';

    constructor(private http: HttpService) { }

    public getUsers<T>(
        page: number,
        page_size: number,
        q: string = '',
        status: string | null,
        level: string | null,
        without: 'role_id' | 'project_id' | null,
        project_id?: number,
        role_id?: number
    ): Observable<IResult<T>> {
        return this.http.request('GET', this.usersCoreName, { page, page_size, q, status, level, without, project_id, role_id }, this.getUsersModel);
    }

    private getUsersModel(data: object): DataListModel<IUser> {
        return new DataListModel<IUser>(data, UserModel);
    }

    public updateUser(id: number, user: IUserUpdateRequest): Observable<IResult> {
        const payload = UserDto.adapt(user);

        return this.http.request('POST', `${this.usersCoreName}${id}/${this.updateUserName}`, payload);
    }

    public usersActions(user_ids: number[], action: TUsersAction): Observable<IResult> {
        return this.http.request('POST', `${this.usersCoreName}${this.usersActionsName}`, { user_ids, action });
    }

    public getUser<T>(id: number): Observable<IResult<T>> {
        return this.http.request('GET', `${this.usersCoreName}${id}/`, {}, this.getUserModel);
    }

    private getUserModel(data: object): UserModel {
        return new UserModel({ value: data });
    }

    public userResetPassword(id: number): Observable<IResult> {
        return this.http.request('POST', `${this.usersCoreName}${id}/${this.userResetPasswordName}`, {});
    }

    public userDisable2fa(id: number): Observable<IResult> {
        return this.http.request('POST', `${this.usersCoreName}${id}/${this.userDisable2faName}`, {});
    }

    public getUserLogs<T>(id: number, page: number, pageSize: number, q: string = ''): Observable<IResult<T>> {
        return this.http.request('GET', `${this.usersCoreName}${id}/${this.getUserLogsName}`, { page, page_size: pageSize, q }, this.getUserLogsModel);
    }

    private getUserLogsModel(data: object): DataListModel<IUserLog> {
        return new DataListModel<IUserLog>(data, UserLogModel);
    }
}
