import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { INotification, INotificationAdditional, IResult } from 'src/app/interfaces';
import { HttpService } from '../http/http.service';
import { DataListModel, NotificationAdditionalModel, NotificationModel } from '../../models';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    readonly notificationsCoreName = 'notification/';
    readonly updateNotificationName = 'update/';

    constructor(
        private http: HttpService
    ) { }

    public getNotifications<T>(page: number, page_size: number, ): Observable<IResult<T>> {
        return this.http.request('GET', this.notificationsCoreName, { page, page_size }, this.getNotificationsModel);
    }

    private getNotificationsModel(data: object): DataListModel<INotification, INotificationAdditional> {
        return new DataListModel<INotification, INotificationAdditional>(data, NotificationModel, NotificationAdditionalModel);
    }

    public updateNotifications(ids: number[], data: any, review_all?: boolean): Observable<IResult> {
        let payload: any = review_all ? { review_all } : { ids, ...data };

        return this.http.request('POST', `${ this.notificationsCoreName}${this.updateNotificationName }`, payload);
    }
}
