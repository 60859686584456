import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'markdownSafeHtml',
    standalone: true,
})
export class MarkdownSafeHtmlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(value: string): SafeHtml {
        if (!value) {
            return '';
        }

        let formattedValue = value
            .replace(/\*\*([\s\S]*?)\*\*/g, '<b>$1</b>')
            .replace(/__([\s\S]*?)__/g, '<b>$1</b>')
            .replace(/\*([\s\S]*?)\*/g, '<em>$1</em>')
            .replace(/_([\s\S]*?)_/g, '<em>$1</em>')
            .replace(/`([\s\S]*?)`/g, '<code class="clickable-code" data-code="$1">$1</code>');

        return this.sanitizer.bypassSecurityTrustHtml(formattedValue);
    }
}
