import {
    IPublicUser,
    ITaskAdditional,
    ITaskCardBoard,
    ITaskCardTeam,
    ITaskCategory,
    ITaskPriority,
    IRole, IProjectStatus, IProjectTag,
} from '../../interfaces';
import { Utils } from '../../helpers';
import { TaskCardBoardModel } from './task-card-board.model';
import { ProjectStatusModel } from '../project/project-status.model';
import { ProjectTagModel } from '../project/project-tag.model';

export class TaskAdditionalModel implements ITaskAdditional {
    private readonly _prioritiesMap: { [key: number]: ITaskPriority } = {};
    private readonly _teamsMap: { [key: number]: ITaskCardTeam } = {};
    private readonly _usersMap: { [key: number]: IPublicUser } = {};
    private readonly _boardsMap: { [key: number]: ITaskCardBoard } = {};
    private readonly _statusesMap: { [key: number]: IProjectStatus } = {};
    private readonly _categoriesMap: { [key: number]: ITaskCategory } = {};
    private readonly _rolesMap: { [key: number]: IRole } = {};
    private readonly _tagsMap: { [key: number]: IProjectTag } = {};

    constructor(data: any) {
        this._rolesMap = Utils.mapByField<IRole>(data['roles'], 'id');
        this._teamsMap = Utils.mapByField<ITaskCardTeam>(data['teams'], 'id');
        this._prioritiesMap = Utils.mapByField<ITaskPriority>(data['priorities'], 'id');
        this._categoriesMap = Utils.mapByField<ITaskCategory>(data['categories'], 'id');
        this._usersMap = Utils.mapByField<IPublicUser>(data['users'], 'id');
        this._boardsMap = Utils.mapByField<ITaskCardBoard>(data['boards'], 'id', TaskCardBoardModel);
        this._statusesMap = Utils.mapByField<IProjectStatus>(data['statuses'], 'id', ProjectStatusModel);
        this._tagsMap = Utils.mapByField<IProjectTag>(data['tags'], 'id', ProjectTagModel);
    }

    get prioritiesMap() {
        return this._prioritiesMap;
    }

    get teamsMap() {
        return this._teamsMap;
    }

    get usersMap() {
        return this._usersMap;
    }

    get boardsMap() {
        return this._boardsMap;
    }

    get statusesMap() {
        return this._statusesMap;
    }

    get categoriesMap() {
        return this._categoriesMap;
    }

    get rolesMap() {
        return this._rolesMap;
    }

    get tagsMap() {
        return this._tagsMap;
    }
}
