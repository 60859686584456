<div class="modal-header p-4 justify-content-between">
    <h6 class="modal-title">
        Керування тегами
    </h6>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span>&times;</span>
    </button>
</div>

<div class="modal-body pt-0 p-4">
    <div class="tags-wrapper">
        <app-search-input (onSearch)="fetchSearchData($event)" [minLength]="2"></app-search-input>

        <div class="tags-list mb-3">
            @for (tag of tags(); track $index) {
                <app-tag [tag]="tag"></app-tag>
            }
        </div>
    </div>

    <div class="d-flex justify-content-end mt-5">
        <button class="btn btn-gray" (click)="activeModal.dismiss('Cross click')">Відміна</button>
        <button type="submit" class="btn btn-default ms-2">Зберегти</button>
    </div>
</div>


