import { IKeywordAdditional, ISourceKeywordCategory } from '../../interfaces';
import { DataListModel } from '../general/data-list.model';
import { KeywordCategoryModel } from './keyword-category.model';
import { Utils } from '../../helpers';

export class KeywordAdditionalModel implements IKeywordAdditional {
    private readonly _categories: ISourceKeywordCategory[];
    private readonly _categoriesMap: { [key: number]: ISourceKeywordCategory } = {};

    constructor(data: any) {
        this._categories = new DataListModel<ISourceKeywordCategory>({ results: data['categories'] }, KeywordCategoryModel).data;
        this._categoriesMap = Utils.mapByField<ISourceKeywordCategory>(this._categories, 'id');
    }

    get categories() {
        return this._categories;
    }

    get categoriesMap() {
        return this._categoriesMap;
    }
}
