<div class="modal-header p-4 justify-content-between">
    <h6 class="modal-title">
        @if (data.field) {
            Редагувати поле
        } @else {
            Додати користувацьке поле
        }
    </h6>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span>&times;</span>
    </button>
</div>

<div class="modal-body pt-0 p-4">
    <form [formGroup]="fieldForm" (ngSubmit)="onSubmit()">
        <div class="fields">
            <div class="mb-3">
                <label class="form-label">Назва</label>
                <input type="text" class="form-control" id="name" placeholder="Введіть назву"
                       [class.is-invalid]="f['name'].touched && f['name'].errors"
                       formControlName="name">
                @if (f['name'].touched && f['name'].errors) {
                    <div class="invalid-feedback">
                        <div>Введіть коректну назву</div>
                    </div>
                }
            </div>
            <div>
                <label class="form-label">Тип поля</label>
                <ng-select
                        class="default-list"
                        [items]="data.fieldTypes()"
                        [placeholder]="'Оберіть типу поля'"
                        [bindLabel]="'name'"
                        [bindValue]="'key'"
                        [ngClass]="{ 'is-invalid': f['type'].touched && f['type'].errors }"
                        (change)="updateValuesControl()"
                        formControlName="type"
                >
                    <ng-template let-item="item" ng-option-tmp>
                        {{ item.name }}
                    </ng-template>
                </ng-select>

                @if (f['type'].touched && f['type'].errors) {
                    <div class="invalid-feedback">
                        <div>Оберіть тип поля</div>
                    </div>
                }
            </div>

            @if (f['values']) {
                <div class="mt-3">
                    <label class="form-label">Варіанти вибору (мінімум 2)</label>
                    <div [ngClass]="{ 'is-invalid': valuesIsInvalid }">
                        <app-drag-sortable-list [items]="dragSortableList" [isFocusOutSubmit]="true" [minElements]="2"
                                                (onError)="valuesIsInvalid = $event" (onSubmit)="submitValuesChange($event)" (onDelete)="deleteValue($event)">
                        </app-drag-sortable-list>
                    </div>
                </div>
            }

            @if (f['dateType']) {
                <div class="mt-3 d-flex">
                    <div class="form-check form-radio-primary">
                        <input class="form-check-input" type="radio" name="dateType" id="radio-date-single"
                               formControlName="dateType" checked value="single">
                        <label class="form-check-label" for="radio-date-single">
                            Вибір однієї дати
                        </label>
                    </div>

                    <div class="form-check form-radio-primary ms-3">
                        <input class="form-check-input" type="radio" name="dateType" id="radio-date-range"
                               formControlName="dateType" value="range">
                        <label class="form-check-label" for="radio-date-range">
                            Вибір проміжку (date range)
                        </label>
                    </div>
                </div>
            }

            @if (f['isDecimalNumber']) {
                <div class="mt-3">
                    <div class="form-check default form-check-outline form-check-info p-0">
                        <input class="form-check-input" type="checkbox" id="checkbox" formControlName="isDecimalNumber">
                        <label class="form-check-label ms-2 fw-medium" for="checkbox">Дозволити дробові значення</label>
                    </div>
                </div>
            }
        </div>

        <div class="d-flex justify-content-end mt-5">
            <button type="button" class="btn btn-gray" (click)="activeModal.dismiss('Cross click')">Відміна</button>
            <button type="submit" class="btn btn-default ms-2" [ngClass]="{ 'loading': loadingBtn() }"
                    [disabled]="fieldForm.invalid || valuesIsInvalid"
            >
                @if (data.field) {
                    Зберегти
                } @else {
                    Додати поле
                }
            </button>
        </div>
    </form>
</div>


