export class NewTaskDto {
    static adapt(data: any): any {
        const payload: any = {
            title: data.title,
            description: data.description,
            board_id: data.boardId,
        };

        if (data.sourceUrl) {
            payload['source_url'] = data.sourceUrl;
        }

        if (data.files.length > 0) {
            payload['files'] = data.files;
        }

        if (data.userGroupsIds) {
            payload['user_groups_ids'] = data.userGroupsIds;
        }

        return payload;
    }
}
