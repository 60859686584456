import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    IResult,
    ITaskCard,
    ITaskCardAdditional,
    ITaskComment,
    ITaskCommentAdditional,
    ITaskHistoryAdditional,
    ITaskHistoryItem,
    ITaskHistoryType,
    ITaskNewComment,
    ITaskRequestParams,
    ITaskUpdateRequestBody
} from 'src/app/interfaces';
import { HttpService } from '../http/http.service';
import {
    DataListModel, TaskCardModel, TaskCommentModel,
    TaskDetailsModel, TaskHistoryItemModel,
} from '../../models';
import { TTaskCardUpdateStatus } from '../../helpers';
import { NewCommentDto, NewTaskDto } from '../../request-dto';
import { TaskCommentAdditionalModel } from '../../models/task/task-comment-additional.model';
import { TaskHistoryAdditionalModel } from '../../models/task/task-history-additional.model';
import { TaskCardAdditionalModel } from '../../models/task/task-card-additional.model';

@Injectable({
    providedIn: 'root'
})
export class TaskService {
    readonly taskCoreName = 'tasks/';
    readonly commentCoreName = 'posts/';
    readonly setTaskInterestName = 'interest/';
    readonly unsetTaskInterestName = 'interest/remove/';
    readonly aiReportName = 'report/ai/';
    readonly updateTaskStatusName = 'status/';
    readonly updateTaskName = 'update/';
    readonly createTaskName = 'create/';
    readonly createTaskSuggestionsName = 'suggestions/create';
    readonly getTaskCommentsName = 'posts/';
    readonly addTaskCommentName = 'add/';
    readonly editTaskCommentName = 'edit/';
    readonly deleteTaskCommentName = 'delete/';
    readonly getTaskHistoryName = 'history/';
    readonly searchTasksName = 'search/';

    constructor(private http: HttpService) { }

    public getTasks<T>(params: ITaskRequestParams): Observable<IResult<T>> {
        return this.http.request('GET', this.taskCoreName, params, this.getBoardTasksModel);
    }

    private getBoardTasksModel(data: object): DataListModel<ITaskCard, ITaskCardAdditional> {
        return new DataListModel<ITaskCard, ITaskCardAdditional>(data, TaskCardModel, TaskCardAdditionalModel);
    }

    public getTaskDetails<T>(id: number): Observable<IResult<T>> {
        return this.http.request('GET', `${ this.taskCoreName }${id}/`, { }, this.getTaskDetailsModel);
    }

    private getTaskDetailsModel(data: object): TaskDetailsModel {
        return new TaskDetailsModel(data);
    }

    public setTaskInterest(id: number, userId?: number): Observable<IResult> {
        return this.http.request('POST', `${this.taskCoreName}${id}/${this.setTaskInterestName}`, { });
    }

    public unsetTaskInterest(id: number, userId?: number): Observable<IResult> {
        return this.http.request('POST', `${this.taskCoreName}${id}/${this.unsetTaskInterestName}`, { });
    }

    public aiReport(id: number): Observable<IResult> {
        return this.http.request('POST', `${this.taskCoreName}${id}/${this.aiReportName}`, { });
    }

    public updateTaskStatus(id: number, action: TTaskCardUpdateStatus): Observable<IResult> {
        return this.http.request('POST', `${this.taskCoreName}${id}/${this.updateTaskStatusName}`, { action });
    }

    public updateTask(data: ITaskUpdateRequestBody): Observable<IResult> {
        return this.http.request('POST', `${this.taskCoreName}${data.id}/${this.updateTaskName}`, data);
    }

    public createTask(data: any): Observable<IResult> {
        const payload = NewTaskDto.adapt(data);

        return this.http.request('POST', `${this.taskCoreName}${this.createTaskName}`, payload);
    }

    public createTaskSuggestions(id: number, data: { suggestions: any, comment?: string }): Observable<IResult> {
        return this.http.request('POST', `${this.taskCoreName}${id}/${this.createTaskSuggestionsName}`, { ...data });
    }

    public getTaskComments<T>(id: number, page_size: number, before: number, around: number, after: number): Observable<IResult<T>> {
        return this.http.request('GET', `${this.taskCoreName}${id}/${this.getTaskCommentsName}`, { before, around, after, page_size }, this.getTaskCommentsModel);
    }

    private getTaskCommentsModel(data: object): DataListModel<ITaskComment, ITaskCommentAdditional> {
        return new DataListModel<ITaskComment, ITaskCommentAdditional>(data, TaskCommentModel, TaskCommentAdditionalModel);
    }

    public addTaskComment(id: number, newComment: ITaskNewComment): Observable<IResult> {
        const payload = NewCommentDto.adapt(newComment);

        return this.http.request('POST', `${this.taskCoreName}${id}/${this.commentCoreName}${this.addTaskCommentName}`, payload);
    }

    public editTaskComment(id: number, updatedComment: ITaskNewComment): Observable<IResult> {
        const payload = NewCommentDto.adapt(updatedComment);

        return this.http.request('POST', `${this.taskCoreName}${this.commentCoreName}${id}/${this.editTaskCommentName}`, payload);
    }

    public deleteTaskComment(id: number): Observable<IResult> {
        return this.http.request('POST', `${this.taskCoreName}${this.commentCoreName}${id}/${this.deleteTaskCommentName}`);
    }

    public getTaskHistory<T>(id: number, page: number, page_size: number, types: ITaskHistoryType[], q?: string): Observable<IResult<T>> {
        const payload: any = { page, page_size, q };
        const ids: number[] = types.map((type: ITaskHistoryType) => type.id);

        if (ids.length) {
            payload['types'] = ids;
        }

        return this.http.request('GET', `${this.taskCoreName}${id}/${this.getTaskHistoryName}`, payload, this.getTaskHistoryModel);
    }

    private getTaskHistoryModel(data: object): DataListModel<ITaskHistoryItem, ITaskHistoryAdditional> {
        return new DataListModel<ITaskHistoryItem, ITaskHistoryAdditional>(data, TaskHistoryItemModel, TaskHistoryAdditionalModel);
    }

    public searchTasks<T>(q: string, page_size: number, params?: any): Observable<IResult<T>> {
        return this.http.request('GET', `${this.searchTasksName}`, { q, page_size, ...params }, this.getBoardTasksModel);
    }
}
