import { JsonPipe, NgClass, NgIf, NgStyle } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef, EventEmitter,
    inject, Input,
    OnInit, Output, signal,
} from '@angular/core';
import {
    NgbAccordionBody,
    NgbAccordionButton, NgbAccordionCollapse,
    NgbAccordionDirective, NgbAccordionHeader,
    NgbAccordionItem,
    NgbActiveModal, NgbDropdown, NgbDropdownMenu, NgbDropdownToggle
} from '@ng-bootstrap/ng-bootstrap';
import {
    FormsModule,
    ReactiveFormsModule,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { NgxMaskDirective } from 'ngx-mask';
import { BoardsService, ProjectsService, SystemSettingsService, ToastsService } from 'src/app/services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    IBoardDetails,
    IDataList,
    IKeywordFilter,
    IProjectRole,
    IResult, ISourceKeywordCategory
} from '../../interfaces';
import { NgSelectModule } from '@ng-select/ng-select';
import { debounceTime, Subject, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { TBoardColor, TBoardIcon, Utils } from '../../helpers';

@Component({
    selector: 'app-manage-board-modal',
    templateUrl: './manage-board-modal.component.html',
    styleUrls: ['./manage-board-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        TranslateModule,
        RouterLink,
        NgClass,
        NgbAccordionDirective,
        NgbAccordionItem,
        NgbAccordionButton,
        NgbAccordionHeader,
        NgbAccordionCollapse,
        NgbAccordionBody,
        NgxMaskDirective,
        NgbDropdown,
        NgbDropdownMenu,
        NgbDropdownToggle,
        NgSelectModule,
        JsonPipe,
        NgStyle,
    ]
})
export class ManageBoardModalComponent implements OnInit {
    @Input() boardDetails!: IBoardDetails;
    @Output() public onUpdatedBoard: EventEmitter<boolean> = new EventEmitter();

    public loadingBtn = signal<boolean>(false);
    public colors: TBoardColor[] = ['#FF3B30', '#FF9500', '#FFCC00', '#34C759', '#007AFF', '#AF52DE', '#A2845E', '#8E8E93'];
    public icons: TBoardIcon[] = ['bxs-plane-alt', 'bxs-binoculars', 'bxs-hot', 'bxs-bomb', 'bxs-car', 'bxs-leaf', 'bxs-cube', 'bx-current-location'];

    public filters: any = {};
    public sourceCategories: any = {};
    public userGroups: any = {};
    public logoPreview: any = null;
    public fileName: string | null = null;

    public boardForm = new UntypedFormGroup({
        name: new UntypedFormControl(null, Validators.required),
        userGroupIds: new UntypedFormControl([]),
        isParser: new UntypedFormControl(false),
        file: new UntypedFormControl(null),
        fileName: new UntypedFormControl(null),
        image: new UntypedFormControl(null),
        iconColor: new UntypedFormControl(null),
        iconClass: new UntypedFormControl(null),
    });

    private debounceTime = 500;
    private destroy = inject(DestroyRef);
    private getKeywordFilterCategoriesTrigger$ = new Subject<any>();
    private getSourceCategoriesTrigger$ = new Subject<any>();
    private getUserGroupsTrigger$ = new Subject<any>();
    private onSubmitSearch$ = new Subject<{ params: any, getTrigger: Subject<any> }>();
    protected readonly Utils = Utils;

    constructor (
        public activeModal: NgbActiveModal,
        private cd: ChangeDetectorRef,
        private projectsService: ProjectsService,
        private boardsService: BoardsService,
        private toastsService: ToastsService,
        private systemSettingsService: SystemSettingsService,
    ) { }

    ngOnInit(): void {
        if (this.boardDetails.details?.id) {
            this.boardForm.patchValue({
                name: this.boardDetails.details.name,
                image: this.boardDetails.details.image,
                iconColor: this.boardDetails.details.iconColor,
                iconClass: this.boardDetails.details.iconClass,
                isParser: this.boardDetails.details.isParser,
                userGroupIds: Utils.pluck(this.boardDetails.details.userGroups, 'id'),
            });

            if (this.boardDetails.details.image) {
                this.logoPreview = this.boardDetails.details.image;
            }

            if (this.boardDetails.details.isParser) {
                this.createParserControls();
            }

            this.userGroups['groups'] = this.boardDetails.details.userGroups;
        } else {
            this.systemSettingsService.getFilters<IDataList<IKeywordFilter>>()
                .pipe(takeUntilDestroyed(this.destroy))
                .subscribe((result: IResult<IDataList<IKeywordFilter>>) => {
                    if (result.isSuccess) {
                        result.data.data.forEach((filter: IKeywordFilter) => {
                            this.filters[filter.id] =  { categories: [], filter, fetchedFirst: false, loading: false };
                        });
                    }
                });
        }

        if (!this.boardForm.controls['iconColor'].value && !this.boardForm.controls['iconClass'].value) {
            this.boardForm.patchValue({
                iconColor: this.colors[0],
                iconClass: this.icons[0],
            });
        }

        this.getKeywordFilterCategoriesTrigger$
            .pipe(
                switchMap((params: any) => {
                    return this.systemSettingsService.getKeywordCategories<IDataList<ISourceKeywordCategory>>(
                        params.filterId, 1, 10, params.searchTerm
                    ).pipe(
                        map(result => ({ result, filterId: params.filterId }))
                    );
                }),
                takeUntilDestroyed(this.destroy)
            )
            .subscribe(({ result, filterId }) => {
                if (result.isSuccess) {
                    this.filters[filterId]['categories'] = result.data.data;
                }

                this.filters[filterId]['loading'] = false;

                this.cd.detectChanges();
            })

        this.getSourceCategoriesTrigger$
            .pipe(
                switchMap((params: any) => {
                    return this.systemSettingsService.getSourceCategories<IDataList<ISourceKeywordCategory>>(
                        1, 10, params.searchTerm
                    )
                }),
                takeUntilDestroyed(this.destroy)
            )
            .subscribe((result: IResult<IDataList<ISourceKeywordCategory>>) => {
                if (result.isSuccess) {
                    this.sourceCategories['categories'] = result.data.data;
                }

                this.sourceCategories['loading'] = false;

                this.cd.detectChanges();
            })

        this.getUserGroupsTrigger$
            .pipe(
                switchMap((params: any) => {
                    return this.projectsService.getRoles<IDataList<IProjectRole>>(
                        this.boardDetails.projectId, 1, 10, params.searchTerm
                    )
                }),
                takeUntilDestroyed(this.destroy)
            )
            .subscribe((result: IResult<IDataList<IProjectRole>>) => {
                if (result.isSuccess) {
                    this.userGroups['groups'] = result.data.data;
                }

                this.userGroups['loading'] = false;

                this.cd.detectChanges();
            })

        this.onSubmitSearch$
            .pipe(
                debounceTime(this.debounceTime),
                takeUntilDestroyed(this.destroy)
            )
            .subscribe(({ params, getTrigger }) => {
                if (params['searchTerm'].length >= 2 || params['searchTerm'].length === 0) {
                    getTrigger.next({ ...params });
                }
            });

        if (!this.boardDetails.details?.id) {
            this.getUserGroupsTrigger$.next({});
        }

        this.cd.detectChanges();
    }

    public fileChange(event: any) {
        let fileList: any = (event.target as HTMLInputElement);
        let file: File = fileList.files[0];
        const reader = new FileReader();

        if (!this.boardForm.get('fileName')) {
            this.boardForm.addControl('fileName', new UntypedFormControl(null));
        }

        reader.onload = (event: any) => {
            this.logoPreview = event.target.result;
            this.fileName = file.name;
            this.boardForm.controls['file'].setValue(event.target.result);
            this.boardForm.controls['fileName'].setValue(file.name);
            this.cd.detectChanges();
        }
        reader.readAsDataURL(file);
    }

    public selectLogo(type: 'old' | 'new' | 'icon'): void {
        if (type === 'old') {
            this.logoPreview = this.boardDetails.details!.image;
            this.boardForm.controls['fileName'].setValue(null);
        } else if (type === 'new') {
            this.logoPreview = this.boardForm.controls['file'].value;
            this.boardForm.controls['fileName'].setValue(this.fileName);
        } else {
            this.logoPreview = null;
            this.boardForm.controls['fileName'].setValue(null);
        }
    }

    public isParserChange(event: any): void {
        if (event.target.checked) {
            this.createParserControls();
        } else {
            Object.keys(this.filters).forEach((filterId: string) => {
                this.boardForm.removeControl(`filter-${filterId}`);
            });

            this.boardForm.removeControl('sourceCategories');
        }
    }

    get f() {
        return this.boardForm.controls;
    }

    public onSubmit(): void {
        this.loadingBtn.set(true);

        if (this.boardForm.invalid) {
            this.loadingBtn.set(false);
            this.toastsService.show('Введено некоректні дані', { classname: 'bg-danger text-light', delay: 3000 });

            return;
        }

        if (!this.boardForm.controls['fileName'].value) {
            this.boardForm.controls['file'].setValue(null);
        }

        if (this.logoPreview) {
            this.boardForm.controls['iconColor'].setValue(null);
            this.boardForm.controls['iconClass'].setValue(null);
        }

        let filters;

        if (this.boardForm.controls['isParser'].value) {
            filters = Object.keys(this.filters).reduce((acc: Record<string, any>, filterId: string) => {
                acc[filterId] = this.boardForm.controls[`filter-${filterId}`].value;
                return acc;
            }, {} as Record<string, any>);
        }

        if (this.boardDetails.details?.id) {
            this.boardsService.updateBoard(this.boardDetails.details!.id, { ...this.boardForm.value, filters })
                .pipe(takeUntilDestroyed(this.destroy))
                .subscribe((result: IResult) => {
                    if (result.isSuccess) {
                        this.toastsService.show('Дошку оновлено', { classname: 'bg-success text-light', delay: 3000 });
                        this.activeModal.close();
                        this.onUpdatedBoard.emit(true);
                    }

                    this.loadingBtn.set(false);
                });
        } else {
            this.projectsService.createBoard(this.boardDetails.projectId, { ...this.boardForm.value, filters })
                .pipe(takeUntilDestroyed(this.destroy))
                .subscribe((result: IResult) => {
                    if (result.isSuccess) {
                        this.toastsService.show('Дошку створено', {classname: 'bg-success text-light', delay: 3000});
                        this.activeModal.close();
                        this.onUpdatedBoard.emit(true);
                    }

                    this.loadingBtn.set(false);
                });
        }
    }

    public onFilterSelectOpen(filterId: number): void {
        if (!this.filters[filterId]['fetchedFirst']) {
            this.filters[filterId]['fetchedFirst'] = true;
            this.filters[filterId]['loading'] = true;
            this.getKeywordFilterCategoriesTrigger$.next({filterId, page: 1});
        }
    }

    public onFilterSearch(event: {  term: string, items: any[] }, filterId: number): void {
        this.filters[filterId]['fetchedFirst'] = false;
        this.filters[filterId]['loading'] = true;
        this.onSubmitSearch$.next({ params: { filterId, searchTerm: event.term }, getTrigger: this.getKeywordFilterCategoriesTrigger$ });
    }

    public onSourceCategorySelectOpen(): void {
        if (!this.sourceCategories['fetchedFirst']) {
            this.sourceCategories['fetchedFirst'] = true;
            this.sourceCategories['loading'] = true;
            this.getSourceCategoriesTrigger$.next({ page: 1 });
        }
    }

    public onSourceCategorySearch(event: { term: string, items: any[] }): void {
        this.sourceCategories['fetchedFirst'] = false;
        this.sourceCategories['loading'] = true;
        this.onSubmitSearch$.next({ params: { searchTerm: event.term }, getTrigger: this.getSourceCategoriesTrigger$ });
    }

    public onUserGroupsSelectOpen(): void {
        if (!this.userGroups['fetchedFirst']) {
            this.userGroups['fetchedFirst'] = true;
            this.userGroups['loading'] = true;
            this.getUserGroupsTrigger$.next({ page: 1 });
        }
    }

    public onUserGroupsSearch(event: { term: string, items: any[] }): void {
        this.userGroups['fetchedFirst'] = false;
        this.userGroups['loading'] = true;
        this.onSubmitSearch$.next({ params: { searchTerm: event.term }, getTrigger: this.getUserGroupsTrigger$ });
    }

    private createParserControls(): void {
        if (this.boardDetails.details?.id) {
            this.boardDetails.details.filters?.forEach((filter: IKeywordFilter) => {
                this.filters[filter.id] = {categories: filter.categories, filter, fetchedFirst: false, loading: false};
                const categoryIds = filter.categories?.map((category) => category.id);

                this.boardForm.addControl(`filter-${filter.id}`,
                    new UntypedFormControl(categoryIds)
                );
            });

            const sourceCategoryIds = this.boardDetails.details.sourceCategories.map((category) => category.id);
            this.sourceCategories = {categories: this.boardDetails.details.sourceCategories, fetchedFirst: false, loading: false};

            this.boardForm.addControl(`sourceCategories`, new UntypedFormControl(sourceCategoryIds));
        } else {
            Object.keys(this.filters).forEach((filterId) => {
                this.boardForm.addControl(`filter-${filterId}`, new UntypedFormControl(null));
            })

            this.boardForm.addControl(`sourceCategories`, new UntypedFormControl(null));
        }
    }
}
