import { ITaskComment, ITaskCommentParentInfo, IFile } from '../../interfaces';
import * as moment from 'moment/moment';
import { Moment } from 'moment';
import { TaskMediaModel } from './task-media.model';

export class TaskCommentModel implements ITaskComment {
    public id: number;
    public taskId: number;
    public parentPost: ITaskCommentParentInfo | null = null;
    public author: { id: number, username: string, avatar: string };
    public content: string;
    public publishedAt: string;
    public publishedAtTime: string;
    public dateMoment: Moment;
    public isInitial: boolean;
    public files: IFile[] = [];
    public showAvatar: boolean = false;

    constructor(data: { value: any }) {
        this.id = data['value']['id'] ?? null;
        this.taskId = data['value']['task_id'] ?? null;
        this.author = data['value']['author'] ?? null;
        this.content = data['value']['content'] ?? '';
        this.isInitial = data['value']['is_initial'] ?? null;
        this.publishedAt = moment(data['value']['published_at']).format('DD.MM.YYYY HH:mm:ss');
        this.publishedAtTime = moment(data['value']['published_at']).format('HH:mm');
        this.dateMoment = moment(this.publishedAt, 'DD.MM.YYYY HH:mm:ss');
        this.author['avatar'] = this.author['avatar'] ?? '/assets/images/user-dummy-img.jpg';

        if (data['value']['parent_post']) {
            this.parentPost = data['value']['parent_post'];
            this.parentPost!['author']['avatar'] = this.parentPost!['author']['avatar'] ?? '/assets/images/user-dummy-img.jpg';
        }

        if (data['value']['files']?.length > 0) {
            data['value']['files'].forEach((file: any) => {
                this.files.push(new TaskMediaModel(file));
            });
        }
    }

}
