import { IDragSortableItem } from '../interfaces';

export class CustomFieldDto {
    static adapt(data: any): any {
        const payload: any = {};

        if (data['name']) {
            payload['name'] = data['name'];
        }

        if (data['type']) {
            payload['field_type'] = data['type'];
        }

        if (data['boardId']) {
            payload['board_id'] = data['boardId'];
        }

        if (data['dateType']) {
            payload['date_type'] = data['dateType'];
        }

        if (data.hasOwnProperty('isActive')) {
            payload['is_active'] = data['isActive'];
        }

        if (data.hasOwnProperty('isDecimalNumber')) {
            payload['is_fractional'] = data['isDecimalNumber'];
        }

        if (data.hasOwnProperty('values')) {
            payload['values'] = data['values'].map((item: IDragSortableItem) => ({ value: item.name }));
        }

        return payload;
    }
}
