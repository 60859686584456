<div class="page-title-box breadcrumb-block align-items-center m-0"
     [ngClass]="{ 'd-none' : !active, 'root-only': (breadcrumbs() ?? []).length < 2 }">

    @if (navigationService.previousUrl()) {
        <div class="back">
            <a (click)="navigationService.goBack()"><i class="las la-arrow-left la-lg"></i></a>
        </div>
    } @else if(back) {
        <div class="back">
            <a [routerLink]="back.url"><i class="las la-arrow-left la-lg"></i></a>
        </div>
    }

    <h4 [ngClass]="{ 'ps-0': !navigationService.previousUrl() && !back }">{{ active?.name }}</h4>
    <div class="breadcrumb-list page-title-left">
        <ol class="breadcrumb m-0">
            @if ((breadcrumbs() ?? []).length > 1) {
                @for (item of breadcrumbs(); track $index) {
                    @if (item.url) {
                        <a class="breadcrumb-item" [routerLink]="item.url">{{ item.name }}</a>
                    } @else {
                        <a class="breadcrumb-item active">{{ item.name }}</a>
                    }
                }
            }
        </ol>
    </div>
</div>
