import { IResult } from 'src/app/interfaces/';
import { HttpErrorResponse } from '@angular/common/http';

export class ResultModel implements IResult {
    private _isSuccess: boolean;
    private _data: object | Array<object> | any = {};
    private _error: HttpErrorResponse | null = null;
    private _action: string;

    constructor(isSuccess: boolean = true) {
        this._isSuccess = isSuccess;
        this._action = '';
    }

    set isSuccess(value) {
        this._isSuccess = value;
    }

    get isSuccess(): boolean {
        return this._isSuccess;
    }

    set data(value: any) {
        this._data = value;
    }

    get data(): any {
        return this._data;
    }

    set error(value: HttpErrorResponse | null) {
        this._isSuccess = false;
        this._error = value;
    }

    get error() {
        return this._error;
    }

    set action(value) {
        this._action = value;
    }

    get action() {
        return this._action;
    }
}
