import { DestroyRef, inject, Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, EMPTY, interval, Observable, Observer, Subject, SubscriptionLike } from 'rxjs';
import { WebSocketSubject, WebSocketSubjectConfig } from 'rxjs/webSocket';
import { IWsConfig, IWsMessage } from 'src/app/interfaces';
import { distinctUntilChanged, filter, map, share, takeWhile } from 'rxjs/operators';
import { WEBSOCKET_CONFIG } from 'src/app/websoket';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
    providedIn: 'root'
})
export class WebsocketService implements OnDestroy {
    // об'єкти конфігурації WebSocketSubject
    private config: WebSocketSubjectConfig<IWsMessage<any>>;
    private websocketSub: SubscriptionLike;
    private statusSub!: SubscriptionLike;
    private isStartReconnect = false;

    // Observable для реконекта по interval
    private reconnection$!: Observable<number> | null;
    private websocket$!: WebSocketSubject<IWsMessage<any>> | null;

    // повідомлюємо, коли відбувається конект і реконект
    private connection$!: Observer<boolean>;

    // допоміжний Observable для роботи з підписками на повідомлення
    private wsMessages$: Subject<IWsMessage<any>>;

    // пауза між спробами реконекта в мілісекундах
    private reconnectInterval: number;

    // кількість спроб реконекта
    private reconnectAttempts: number;

    // синхронний флаг для статуса з'єднання
    public isConnected!: boolean;

    // статус з'єднання
    public status$: Observable<boolean>;
    public isAuthorized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public statusConection$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public reConnectedEnded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public reConnectedFinish$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public updateChart$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private isChangeConnect = false;
    private destroy = inject(DestroyRef);

    constructor(
        @Inject(WEBSOCKET_CONFIG) private wsConfig: IWsConfig,
        private translate: TranslateService,
    ) {

        this.wsMessages$ = new Subject<IWsMessage<any>>();

        // смотрим конфиг, если пусто, задаем умолчания для реконнекта
        this.reconnectInterval = wsConfig.reconnectInterval || 5000;
        this.reconnectAttempts = wsConfig.reconnectAttempts || 3;

        this.config = {
            url: wsConfig.url,
            closeObserver: {
                next: (event: CloseEvent) => {
                    if (!this.isChangeConnect) {
                        this.websocket$ = null;
                        this.connection$.next(false);
                        this.statusConection$.next(false);
                    }

                }
            },
            // при конекті змінюємо статус connection$
            openObserver: {
                next: (event: Event) => {
                    this.connection$.next(true);
                    this.isChangeConnect = false;
                    this.statusConection$.next(true);
                    this.updateChart$.next(true);

                    if (this.isStartReconnect) {
                        this.isStartReconnect = false;
                        this.reConnectedEnded$.next(true);
                        this.reConnectedEnded$.next(false);
                    }
                }
            }
        };

        // connection status
        this.status$ = new Observable<boolean>((observer) => {
            this.connection$ = observer;
        }).pipe(
            share(),
            distinctUntilChanged()
        );

        // говоримо, що щось пішло не так
        this.websocketSub = this.wsMessages$
            .pipe(takeUntilDestroyed(this.destroy))
            .subscribe({
                next: () => { },
                error: (error: ErrorEvent) => console.error('WebSocket error!', error)
            });
    }

    private createStatusSub(): void {
        this.statusSub = this.status$
            .pipe(takeUntilDestroyed(this.destroy))
            .subscribe((isConnected) => {
                this.isConnected = isConnected;
            });
    }

    public startConnect() {
        this.connect();
    }

    private connect(): void {
        this.config.url = environment.ws.url;
        this.websocket$ = new WebSocketSubject(this.config); //створюємо

        this.createStatusSub();
        // якщо є повідомлення - шлємо їх далі. Якщо ні - чекаємо. Реконектимось коли отримаємо помилку
        this.websocket$
            .pipe(takeUntilDestroyed(this.destroy))
            .subscribe({
                next: (message) => this.wsMessages$.next(message),
                error: () => {
                    if (!this.isStartReconnect) {
                        this.reconnect();
                    }
                }
            });
    }

    private reconnect(): void {
        // створюємо interval зі значенням з reconnectInterval
        this.reconnection$ = interval(this.reconnectInterval)
            .pipe(
                takeWhile((index: number) => {
                    if (index < this.reconnectAttempts && !this.websocket$) {
                        this.isStartReconnect = true;
                        return true;
                    } else if (index === this.reconnectAttempts) {
                        if (!this.statusConection$.value) {
                            this.wsMessages$.complete();
                            this.connection$.complete();
                        }
                        this.reconnection$ = null;
                        this.reConnectedFinish$.next(true);
                    }

                    return false;
                })
            );

        //Намагаємось підключитись поки не буде вдалого підключення, або поки не закінчаться спроби підключення
        this.reconnection$
            .pipe(takeUntilDestroyed(this.destroy))
            .subscribe(() => this.connect());
    }

    public disConnect() {
        this.websocket$!.complete();
        this.statusSub.unsubscribe();
    }

    public on<T>(event: string): Observable<T> {
        if (event) {
            return this.wsMessages$.pipe(
                filter((message: IWsMessage<T>) => message.event === event),
                map((message: IWsMessage<T>) => message.data)
            );
        }

        return EMPTY;
    }

    public send(event: string, data: any = {}): void {
        if (this.websocket$) {
            if (event && this.isConnected) {
                this.websocket$.next({ event, data });
            } else {
                console.error('Send error!');
            }
        }
    }

    public errorWS(error: number | null): void {
        if (error !== null) {
            const errorName = `WS_ERROR_${error}`;

            this.translate.get([errorName])
                .pipe(takeUntilDestroyed(this.destroy))
                .subscribe(translations => {

                });
        }
    }

    ngOnDestroy() {
        this.websocketSub.unsubscribe();
        this.statusSub.unsubscribe();
    }
}
