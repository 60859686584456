import { IPublicUser, IGeoUser, ITaskHistoryType, ITaskHistoryAdditional } from '../../interfaces';
import { Utils } from '../../helpers';

export class TaskHistoryAdditionalModel implements ITaskHistoryAdditional {
    private readonly _usersMap: { [key: number]: IPublicUser } = {};
    private readonly _historyTypes: ITaskHistoryType[];

    constructor(data: any) {
        this._usersMap = Utils.mapByField<IGeoUser>(data['users'], 'id');
        this._historyTypes = data['history_types'] ?? [];
    }

    get usersMap() {
        return this._usersMap;
    }

    get historyTypes() {
        return this._historyTypes;
    }
}
