<div class="search-box d-inline-block mb-4">
    <input type="text" class="form-control search" placeholder="Пошук..." [(ngModel)]="searchText"
           (ngModelChange)="onSearchChange()">
    <i class="ri-search-line search-icon"></i>
</div>

<div class="table-responsive">
    <table class="table settings-table">
        <thead>
        <tr>
            <th>Дія</th>
            <th>Час</th>
            <th>IP пристрою</th>
            <th>Опис</th>
            <th>Девайс</th>
        </tr>
        </thead>
        <tbody>
            @for (data of logsSource(); track $index) {
                <tr>
                    <td><b>{{ data.actionTranslation }}</b></td>
                    <td>{{ data.date }}</td>
                    <td>{{ data.ip }}</td>
                    <td>{{ data.description }}</td>
                    <td>{{ data.deviceInfo }}</td>
                </tr>
            }

            @if (logsSource().length === 0) {
                <tr>
                    <td colspan="5" class="text-center">Немає даних</td>
                </tr>
            }
        </tbody>
    </table>
</div>

@if (logsSource().length > 0) {
    <div class="row justify-content-md-between align-items-md-center">
        <app-paginator [totalSize]="totalSize" [data]="logsSource" [defaultPageSize]="pageSize"
                       (onFetchData)="fetchData($event)">
        </app-paginator>
    </div>
}
