<div class="onboarding-container">
    <div class="steps">
        <div class="step" [class.active]="step() === 1">Двохфакторна автентифікація</div>
        <div class="step" [class.active]="step() === 2">Створення проєкту</div>
    </div>

    @if (step() === 1) {
        <div class="title">
            <h2>Для початку роботи треба зробити усього два невеликі кроки. </h2>
            <p>Перш за все, ви повинні увімкнути двохфакторну автентифікацію, аби убезпечити свій акаунт.</p>
        </div>

        <div class="two-fa-block">
            <h4>Підключення Google Authenticator <span class="badge rounded-pill bg-danger ml-3">Обов'язково</span></h4>
            <app-2fa [qrcodeUrl]="qrcodeUrl"></app-2fa>

        </div>
    } @else {
        <div class="title">
            <h2>Тепер, створіть свій перший проєкт.</h2>
            <p>Це дозволить вам розпочати роботу із системою. Ви завжди зможете змінити усі налаштування пізніше.</p>
        </div>

        <app-create-project-form></app-create-project-form>
    }
</div>
