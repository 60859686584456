<div class="modal-header p-4 justify-content-between">
    <h6 class="modal-title">
        {{ data.title }}
    </h6>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span >&times;</span>
    </button>
</div>
<div class="modal-body pt-0 p-4">
    <div class="d-flex flex-column justify-content-end">
        <div class="modal-content mb-3">
            @if (data.content) {
                <p>{{ data.content }}</p>
            }

            @if (data.isIrreversibleAction) {
                <p>Ця дія незворотня.</p>
            }
        </div>

        <div class="d-flex justify-content-end">
            <button class="btn btn-gray" (click)="activeModal.dismiss()">
                Відміна
            </button>
            <button class="btn btn-danger ms-3"
                    [ngClass]="{ 'btn-danger': !data.isConfirm, 'btn-default': data.isConfirm }"
                    (click)="onConfirm.emit(); activeModal.dismiss()"
            >
                @if (data.isConfirm) {
                    Підтвердити
                } @else {
                    <i class="las la-trash-alt la-lg"></i> Так, видалити
                }
            </button>

        </div>
    </div>
</div>
