import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
    standalone: true,
    selector: '[appScrollTracker]'
})
export class ScrollTrackerDirective {
    @Output() scrolledToBottom = new EventEmitter<void>();
    @Output() scrolledToTop = new EventEmitter<void>();

    @HostListener('scroll', ['$event'])
    onScroll(event: any): void {
        const tracker = event.target;

        const scrollTop = tracker.scrollTop;
        const scrollHeight = tracker.scrollHeight;
        const clientHeight = tracker.clientHeight;

        const bottomLimit = scrollHeight - clientHeight;

        if (Math.abs(scrollTop - bottomLimit) < 100) {
            this.scrolledToBottom.emit();
        }

        if (scrollTop === 0) {
            this.scrolledToTop.emit();
        }
    }
}
