import { inject } from '@angular/core';
import { Router } from '@angular/router';

export const isAuthGuard = () => {
    const router = inject(Router);
    const authToken = JSON.parse(<string>localStorage.getItem('authToken'));

    if (authToken && 'accessToken' in authToken) {
        return true;
    }

    router.navigate(['/auth/login']);

    return false;
};
