import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, Observable } from 'rxjs';
import { TaskService } from '../../services';
import {
    loadTasks,
    loadTasksFailure,
    loadTasksSuccess,
} from './task.actions';
import { IDataList, IResult, ITaskCard, ITaskCardAdditional, ITaskRequestParams } from '../../interfaces';

@Injectable()
export class TaskEffects {
    loadTasks$: any;

    constructor(private actions$: Actions, private taskService: TaskService) {
        this.loadTasks$ = createEffect(() =>
            this.actions$.pipe(
                ofType(loadTasks),
                mergeMap(({ params }) => this.getLoadTaskRequest(params).pipe(
                        map((result: IResult) => {
                            if (result.isSuccess) {
                                return loadTasksSuccess({ tasks: result.data })
                            } else {
                                return loadTasksFailure({ error: result.error })
                            }
                        })
                    )
                )
            )
        );
    }

    private getLoadTaskRequest(params: ITaskRequestParams): Observable<IResult> {
        if (params['q']) {
            return this.taskService.searchTasks<IDataList<ITaskCard, ITaskCardAdditional>>(
                params['q'], params['page_size']!, { board_id: params['board_id'], page: params['page'] }
            );
        } else {
            return this.taskService.getTasks<IDataList<ITaskCard, ITaskCardAdditional>>(params);
        }
    }
}
