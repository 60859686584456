import { IBoardAdditional, IProject } from '../../interfaces';
import { ProjectModel } from '../project/project.model';

export class BoardAdditionalModel implements IBoardAdditional {
    private readonly _project: IProject | null;

    constructor(data: any) {
        this._project = data['project'] ? new ProjectModel({ value: data['project'] }) : null;
    }

    get project() {
        return this._project;
    }
}
