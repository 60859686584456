<div class="board-card">
    <div class="board-card-header d-flex justify-content-between align-items-center">
        <span>{{ board.lastUpdate ?? '' }}</span>
        <div class="menu">
            <button type="button" class="btn star" [ngClass]="{ 'loading': loading }" (click)="toggleFavoriteBoard()">
                <i [ngClass]="{ 'active': board.isFavorite }" class="las la-star la-lg"></i>
            </button>

            <!--<div class="btn-group" ngbDropdown>
                <button type="button" class="btn d-flex" data-bs-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false" ngbDropdownToggle>
                    <i class="bx bx-dots-horizontal-rounded fs-22 d-flex align-items-center"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-start" ngbDropdownMenu>
                    <a class="dropdown-item" href="javascript: void(0);">Редагувати</a>
                </div>
            </div>-->
        </div>
    </div>
    <div class="board-card-body">
        <div class="name">
            @if (board.image) {
                <img [src]="board.image" alt="board-logo.png">
            } @else {
                <div class="icon-wrapper" [ngStyle]="{ 'backgroundColor': board.iconColor }">
                    <i class="bx {{ board.iconClass }}"></i>
                </div>
            }
            <p>{{ board.name }}</p>
        </div>

        <div class="processed">
            <div class="text">
                @if (board.isParser) {
                    <span>Новин не опрацьовано</span>
                    <span>{{ board.totalTasks - board.completedTasks }}</span>
                } @else if (board.isRecycle) {
                    <span>Новин видалено</span>
                    <span>{{ board.totalTasks }}</span>
                } @else {
                    <span>Задачі</span>
                    <span>{{ board.completedTasks }} / {{ board.totalTasks }}</span>
                }
            </div>

            @if (!board.isParser && !board.isRecycle) {
                <div class="progress progress-sm">
                    <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': board.tasksProgress + '%' }"
                         aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            }
        </div>
    </div>
    <div class="board-card-footer">
        <div>
           <!-- <span class="badge" [ngStyle]="{ 'backgroundColor': board.roleColor }">{{ board.roleName }}</span>-->
        </div>
        <button class="btn d-flex align-items-center" [routerLink]="['/board', board.id]">
            Перейти <i class="bx bx-right-arrow-alt fs-18"></i>
        </button>
    </div>
</div>
