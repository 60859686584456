export class BoardDto {
    static adapt(data: any): any {
        const payload: any = {
            name: data.name,
            is_parser: data.isParser,
            user_group_ids: data.userGroupIds,
        };

        if (data.filters) {
            payload['filters'] = data.filters;
        }

        if (data.fileName) {
            payload['fileName'] = data.fileName;
        }

        if (data.sourceCategories) {
            payload['source_category_ids'] = data.sourceCategories;
        }

        if (data.file) {
            payload['file'] = data.file;
        }

        if (data.iconColor) {
            payload['icon_color'] = data.iconColor;
        }

        if (data.iconClass) {
            payload['icon_class'] = data.iconClass;
        }

        return payload;
    }
}
