import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit
} from '@angular/core';
import { NgClass } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { IFile } from '../../interfaces';
import { MediaViewerModalComponent } from '../media-viewer-modal/media-viewer-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-files-preview',
    templateUrl: './files-preview.component.html',
    styleUrls: ['./files-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        MatPaginatorModule,
    ],
})
export class FilesPreviewComponent implements OnInit {
    @Input() files: IFile[] = [];

    constructor(private modalService: NgbModal) { }

    ngOnInit(): void {
    }

    public showMediaViewerModal(index: number, files: IFile[]): void {
        const modalRef = this.modalService.open(MediaViewerModalComponent);
        modalRef.componentInstance.media = files;
        modalRef.componentInstance.focusedMediaIndex = index;
    }
}
