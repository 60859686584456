import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, EventEmitter,
    Input, OnInit, Output, Signal, signal,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { PaginatorComponent, ToastsContainer } from 'src/app/shared';
import { NgbHighlight, NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { IPagination, IUserLog } from '../../interfaces';

@Component({
    selector: 'app-logs-table',
    templateUrl: './logs-table.component.html',
    styleUrls: ['./logs-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        TranslateModule,
        RouterLink,
        NgClass,
        ToastsContainer,
        NgbHighlight,
        NgbPagination,
        AsyncPipe,
        PaginatorComponent,
    ],
})
export class LogsTableComponent implements OnInit {
    @Input() logsSource: Signal<IUserLog[]> = signal([]);
    @Input() totalSize: Signal<number> = signal(0);
    @Input() pageSize: number = 10;

    @Output() public onFetchData: EventEmitter<IPagination> = new EventEmitter();

    public searchText = signal<string>('');

    constructor(private cd: ChangeDetectorRef) { }

    ngOnInit(): void { }

    public fetchData(event: { currentPage: number, pageSize: number }): void {
        this.pageSize = event.pageSize;
        this.onFetchData.emit({ page: event.currentPage, pageSize: event.pageSize });

        this.cd.detectChanges();
    }

    public onSearchChange(): void {
        this.onFetchData.emit({ page: 1, pageSize: this.pageSize, searchTerm: this.searchText() });
    }
}
