import { IUser, IUserLevel, IUserStatus } from '../../interfaces';
import * as moment from 'moment';
import { UserAdditionalModel } from './user-additional.model';

export class UserModel implements IUser {
    private readonly _id: number;
    private readonly _email: string;
    private readonly _username: string;
    private readonly _firstName: string;
    private readonly _lastName: string;
    private readonly _publicName: string;
    private readonly _lastLogin: string | null;
    private readonly _avatar: string;
    private readonly _messenger: string;
    private readonly _phone: string;
    private readonly _level: IUserLevel;
    private readonly _status: IUserStatus;
    private readonly _is2FaActive: boolean;
    private readonly _is2FaRequired: boolean;
    private readonly _isStaff: boolean;
    private readonly _isAdmin: boolean;
    private readonly _isActive: boolean;
    private readonly _isBlocked: boolean;
    private readonly _isDeleted: boolean;
    private readonly _isSetup: boolean;
    private readonly _isInvited: boolean;

    constructor(data: { value: any }) {
        this._id = data['value']['id'] ?? null;
        this._email = data['value']['email'] ?? null;
        this._firstName = data['value']['first_name'] ?? null;
        this._lastName = data['value']['last_name'] ?? null;
        this._username = data['value']['username'] ?? null;
        this._publicName = data['value']['public_name'] ?? null;
        this._avatar = data['value']['avatar'] ?? '/assets/images/user-dummy-img.jpg';
        this._messenger = data['value']['messenger'] ?? null;
        this._phone = data['value']['phone'] ?? null;
        this._lastLogin = data['value']['last_login'] ? moment(data['value']['last_login']).format('DD.MM.YYYY HH:mm:ss') : null;
        this._is2FaActive = data['value']['is_2fa_active'] ?? null;
        this._is2FaRequired = data['value']['is_2fa_required'] ?? null;
        this._isStaff = data['value']['is_staff'] ?? null;
        this._isAdmin = data['value']['is_admin'] ?? null;
        this._isActive = data['value']['is_active'] ?? null;
        this._isBlocked = data['value']['is_blocked'] ?? null;
        this._isDeleted = data['value']['is_deleted'] ?? null;
        this._isSetup = data['value']['is_setup'] ?? null;
        this._isInvited = data['value']['is_invited'] ?? null;

        const userAdditional = new UserAdditionalModel();
        this._level = userAdditional.levelsMap[userAdditional.getLevelKey(this)];
        this._status = userAdditional.statusesMap[userAdditional.getStatusKey(this)];
    }

    get id() {
        return this._id;
    }

    get email() {
        return this._email;
    }

    get username() {
        return this._username;
    }

    get firstName() {
        return this._firstName;
    }

    get lastName() {
        return this._lastName;
    }

    get publicName() {
        return this._publicName;
    }

    get lastLogin() {
        return this._lastLogin;
    }

    get avatar() {
        return this._avatar;
    }

    get messenger() {
        return this._messenger;
    }

    get phone() {
        return this._phone;
    }

    get level() {
        return this._level;
    }

    get status() {
        return this._status;
    }

    get is2FaActive() {
        return this._is2FaActive;
    }

    get is2FaRequired() {
        return this._is2FaRequired;
    }

    get isStaff() {
        return this._isStaff;
    }

    get isAdmin() {
        return this._isAdmin;
    }

    get isActive() {
        return this._isActive;
    }

    get isBlocked() {
        return this._isBlocked;
    }

    get isDeleted() {
        return this._isDeleted;
    }

    get isSetup() {
        return this._isSetup;
    }

    get isInvited() {
        return this._isInvited;
    }
}
