import { createReducer, on } from '@ngrx/store';
import { DataListModel } from '../../models';
import {
    loadClearTasks,
    loadTasks,
    loadTasksFailure,
    loadTasksSuccess
} from './task.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { ITaskCard, ITaskCardAdditional } from '../../interfaces';

export interface TasksState {
    tasks: DataListModel<ITaskCard, ITaskCardAdditional> | null;
    loading: boolean;
    error: HttpErrorResponse | null;
}

export const initialState: TasksState = {
    tasks: null,
    loading: false,
    error: null,
};

export const taskReducer = createReducer(
    initialState,
    on(loadTasks, (state) => ({ ...state, loading: true })),
    on(loadTasksSuccess, (state, { tasks }) => ({ ...state, loading: false, tasks })),
    on(loadTasksFailure, (state, { error }) => ({ ...state, loading: false, error })),
    on(loadClearTasks, (state) => ({ ...state, tasks: null, error: null })),
);
