import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './user.reducer';

export const selectUserState = createFeatureSelector<UserState>('user');

export const selectUser = createSelector(selectUserState, (state) => {
    return state.user;
});

export const selectUserLoading = createSelector(selectUserState, (state) => state.loading);

export const selectUserError = createSelector(selectUserState, (state) => state.error);

export const selectUserIsStaff = createSelector(selectUserState, (state) => {
    return state.user?.isStaff;
});

export const selectUserIsAdmin = createSelector(selectUserState, (state) => {
    return state.user?.isAdmin;
});

export const selectUserIs2FaActive = createSelector(selectUserState, (state) => {
    return state.user?.is2FaActive;
});

export const selectUserNeeds2Fa = createSelector(selectUserState, (state) => {
    return { is2FaActive: state.user?.is2FaActive, is2FaRequired: state.user?.is2FaRequired }
});

export const selectUserId = createSelector(selectUserState, (state) => {
    return state.user?.id;
});


// Impersonated User
export const selectImpersonatedUser = createSelector(selectUserState, (state) => {
    return state.impersonatedUser;
});

export const selectImpersonatedUserIs2FaActive = createSelector(selectUserState, (state) => {
    return state.impersonatedUser?.is2FaActive;
});

export const selectImpersonatedUserId = createSelector(selectUserState, (state) => {
    return state.impersonatedUser?.id;
});

