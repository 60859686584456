import { IGeoStatus } from '../../interfaces';

export class GeoStatusModel implements IGeoStatus {
    private readonly _id: number;
    private readonly _name: string;
    private readonly _isDefault: boolean;
    private readonly _color: string;
    private readonly _backgroundColor: string;

    constructor(data: { value: any }) {
        this._id = data['value']['id'] ?? null;
        this._name = data['value']['name'] ?? null;
        this._isDefault = data['value']['is_default'] ?? null;
        this._color = data['value']['color'] ?? null;
        this._backgroundColor = data['value']['background_color'] ?? null;
    }

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    get isDefault() {
        return this._isDefault;
    }

    get color() {
        return this._color;
    }

    get backgroundColor() {
        return this._backgroundColor;
    }
}
