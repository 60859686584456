import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class ToastsService {
  public toasts: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
  private maxToasts = 3;

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    const currentToasts = this.toasts.getValue();

    if (currentToasts.length >= this.maxToasts) {
      currentToasts.shift();
    }

    this.toasts.next([...currentToasts, { textOrTpl, ...options }])
  }

  remove(toast: any) {
    this.toasts.next(this.toasts.value.filter(t => t !== toast));
  }
}
