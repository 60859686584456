import {
    IPublicUser,
    ITaskCard,
    ITaskCardAI,
    ITaskCardBoard,
    ITaskCardTeam,
    IFile,
    ITaskPriority,
    IRole,
    ITaskCardAdditional, IProjectStatus, ITaskCustomField, IProjectTag,
} from '../../interfaces';
import { TaskMediaModel } from './task-media.model';
import * as moment from 'moment';
import 'moment/min/locales';
import { DataListModel } from '../general/data-list.model';
import { TaskCustomFieldModel } from './task-custom-field.model';
moment.locale('uk');

export class TaskCardModel implements ITaskCard {
    public id: number;
    public channelPostId: number;
    public title: string;
    public description: string;
    public date: string;
    public shortDate: string;
    public media: IFile[] = [];
    public isInterested: boolean;
    public sourceUrl: string;
    public sourceDeepUrl: string;
    public statusId: number;
    public categoryId: number;
    public suggestionsCount: number;
    public historyCount: number;
    public commentsCount: number;
    public boardId: number;
    public projectId: number;
    public disabled: boolean = false;
    public ai: ITaskCardAI;
    public customFields: ITaskCustomField[] = [];
    public priority: ITaskPriority = {id: 0, color: '', color2: '', name: '', style: ''};
    public allTeams: ITaskCardTeam[] = [];
    public interestedTeams: ITaskCardTeam[] = [];
    public users: IPublicUser[] = [];
    public allUsers: IPublicUser[] = [];
    public boards: ITaskCardBoard[] = [];
    public roles: IRole[] = [];
    public statuses: IProjectStatus[] = [];
    public tags: IProjectTag[] = [];

    constructor (data: { value: any, additional?: ITaskCardAdditional }) {
        this.id = data['value']['id'];
        this.title = data['value']['channel_post']?.['title'] ?? null;
        this.channelPostId = data['value']['channel_post']?.['id'] ?? null;
        this.description = data['value']['channel_post']?.['description'] ?? null;
        this.ai = data['value']['channel_post']?.['ai'] ?? null;
        this.sourceUrl = data['value']['channel_post']?.['source_url'] ?? null;
        this.sourceDeepUrl = data['value']['channel_post']?.['source_deep_url'] ?? null;
        this.isInterested = data['value']['is_interested'] ?? null;
        this.statusId = data['value']['status_id'] ?? null;
        this.categoryId = data['value']['category_id'] ?? null;
        this.boardId = data['value']['board_id'] ?? null;
        this.projectId = data['value']['project_id'] ?? null;
        this.suggestionsCount = data['value']['suggestions_count'] ?? null;
        this.historyCount = data['value']['history_count'] ?? null;
        this.commentsCount = data['value']['posts_count'] ?? null;
        this.date = moment(data['value']['channel_post']?.['post_date_at']).format('DD.MM.YYYY HH:mm:ss');
        this.shortDate = moment(data['value']['channel_post']?.['post_date_at']).format('D MMM');

        if (data['value']['channel_post']?.['media']?.length > 0) {
            Object.keys(data['value']['channel_post']['media']).map((key: string) => {
                this.media.push(new TaskMediaModel(data['value']['channel_post']['media'][key]));
            });
        }

        data['value']['options'] = [
            {
                id: 1,
                name: 'Custom Field 1',
                field_type: 'select',
                value: 1,
                values: [
                    { id: 1, value: 'Value 1' },
                    { id: 2, value: 'Value 2' },
                    { id: 3, value: 'Value 3' },
                ]
            },
            {
                id: 2,
                name: 'Custom Field 2',
                field_type: 'multiselect',
                value: [2, 3],
                values: [
                    { id: 1, value: 'Value 1' },
                    { id: 2, value: 'Value 2' },
                    { id: 3, value: 'Value 3' },
                ]
            },
            {
                id: 3,
                name: 'Custom Field 3',
                field_type: 'text',
                value: 'Value 3'
            },
            {
                id: 9,
                name: 'Custom Field 9',
                field_type: 'text',
                value: 'Value 6'
            },
            {
                id: 4,
                name: 'Custom Field 4',
                field_type: 'textarea',
                value: 'Value 4, Value 4, Value 4, Value 4, Value 4, Value 4, Value 4, Value 4, Value 4, Value 4, Value 4, Value 4, Value 4'
            },
            {
                id: 5,
                name: 'Custom Field 5',
                field_type: 'checkbox',
                value: true
            },
            {
                id: 6,
                name: 'Custom Field 6',
                field_type: 'radio',
                value: 2,
                values: [
                    { id: 1, value: 'Value 1' },
                    { id: 2, value: 'Value 2' },
                    { id: 3, value: 'Value 3' },
                    { id: 4, value: 'Value 4' },
                    { id: 5, value: 'Value 5' },
                    { id: 6, value: 'Value 6' },
                ]
            },
            {
                id: 7,
                name: 'Custom Field 7',
                field_type: 'date',
                value: '06.03.2025 to 27.03.2027',
                mode: 'range'
            },
            {
                id: 23,
                name: 'Custom Field 45',
                field_type: 'date',
                value: '03.07.2022',
                mode: 'single'
            },
            {
                id: 8,
                name: 'Custom Field 8',
                field_type: 'number',
                mode: 'decimal',
                value: 143
            }
        ]

        if (data['value']['options']?.length > 0) {
            this.customFields = new DataListModel<ITaskCustomField>({ results: data['value']['options'] }, TaskCustomFieldModel).data;
        }

        if (data.additional?.taskAdditional) {
            const firstPriorityKey: number = Number(Object.keys(data['additional']['taskAdditional'].prioritiesMap)[0]);
            this.priority = data.additional.taskAdditional.prioritiesMap[data['value']['priority_id']] ?? data.additional.taskAdditional.prioritiesMap[firstPriorityKey];
            this.allTeams = Object.values(data.additional.taskAdditional['teamsMap']);
            this.allUsers = Object.values(data.additional.taskAdditional['usersMap']);
            this.roles = Object.values(data.additional.taskAdditional['rolesMap']);

            data['value']['teams_interested'].forEach((teamId: number) => {
                if (data.additional?.taskAdditional?.['teamsMap'][teamId]) {
                    this.interestedTeams.push(data.additional.taskAdditional['teamsMap'][teamId]);
                }
            })

            data['value']['navigation_boards'].forEach((boardId: number) => {
                if (data.additional?.taskAdditional?.['boardsMap'][boardId]) {
                    this.boards.push(data.additional.taskAdditional['boardsMap'][boardId]);
                }
            })

            data['value']['users_interested'].forEach((userId: number) => {
                if (data.additional?.taskAdditional?.['usersMap'][userId]) {
                    this.users.push(data.additional.taskAdditional['usersMap'][userId]);
                }
            })

            data['value']['available_statuses'].forEach((statusId: number) => {
                if (data.additional?.taskAdditional?.['statusesMap'][statusId]) {
                    this.statuses.push(data.additional.taskAdditional['statusesMap'][statusId]);
                }
            })

            data['value']['tag_ids']?.forEach((tagId: number) => {
                if (data.additional?.projectAdditional?.['tagsMap'][tagId]) {
                    this.tags.push(data.additional.projectAdditional['tagsMap'][tagId]);
                }
            })
        }

        /*if (data['projectAdditional']) {
            const firstPriorityKey: number = Number(Object.keys(data['projectAdditional'].priorities)[0]);
            this.priority = data['projectAdditional'].priorities[data['value']['priority_id']] ?? data['projectAdditional']['priorities'][firstPriorityKey];
            this.allTeams = Object.values(data['projectAdditional']['teams']);

            data['value']['teams_interested'].forEach((teamId: number) => {
                if (data['projectAdditional']?.['teams'][teamId]) {
                    this.interestedTeams.push(data['projectAdditional']['teams'][teamId]);
                }
            })
        }*/
    }
}
