import { IBreadcrumb } from '../../interfaces';

export class BreadcrumbModel implements IBreadcrumb {
    private readonly _name: string;
    private readonly _url: string;
    private readonly _isBack: boolean = false;
    private readonly _isActive: boolean = false;

    constructor(data: { value: any, length: number, index: number }) {
        this._name = data['value']['name'] ?? null;
        this._url = data['value']['url'] ?? null;

        if (data['value']['project_id']) {
            this._url = `/project/${data['value']['project_id']}`;
        }

        if (data['value']['board_id']) {
            this._url = `/board/${data['value']['board_id']}`;
        }

        if (data['value']['suggestion_id']) {
            this._url = `/loc4verify/${data['value']['suggestion_id']}`;
        }

        if (data['length'] - 1 === data['index']) {
            this._isActive = true;
        }

        if (data['length'] - 2 === data['index']) {
            this._isBack = true;
        }
    }

    get name() {
        return this._name;
    }

    get url() {
        return this._url;
    }

    get isBack() {
        return this._isBack;
    }

    get isActive() {
        return this._isActive;
    }
}
