import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IBoard, IBoardAdditional, IBoardCustomFieldOption, IBoardPreview, IResult } from 'src/app/interfaces';
import { HttpService } from '../http/http.service';
import {
    DashboardModel, BoardDetailsModel, BoardCustomFieldOptionModel,
    BoardModel, BoardAdditionalModel, DataListModel, BoardPreviewModel
} from '../../models';
import { BoardDto, CustomFieldOptionDto } from '../../request-dto';

@Injectable({
    providedIn: 'root'
})
export class BoardsService {
    readonly boardsCoreName = 'boards/';
    readonly updateBoardName = 'update/';
    readonly favoriteBoardCoreName = 'favorite/';
    readonly toggleBoardFavoriteName = 'favorite/toggle/';
    readonly getDashboardStatsName = 'dashboard/stats/';
    readonly getCustomFieldOptionsName = 'options/';
    readonly updateCustomFieldOptionName = 'update/';
    readonly createCustomFieldOptionsName = 'create/';

    constructor(
        private http: HttpService
    ) { }

    public getAllBoards<T>(page: number, page_size: number, q?: string): Observable<IResult<T>> {
        return this.http.request('GET', `${this.boardsCoreName}`, { page, page_size, q }, this.getAllBoardsModel);
    }

    private getAllBoardsModel(data: object): DataListModel<IBoardPreview> {
        return new DataListModel<IBoardPreview>(data, BoardPreviewModel);
    }

    public updateBoard(id: number, data: any): Observable<IResult> {
        const payload = BoardDto.adapt(data);

        return this.http.request('POST', `${this.boardsCoreName}${id}/${this.updateBoardName}`, payload);
    }

    public getDashboardStats<T>(): Observable<IResult<T>> {
        return this.http.request('GET', `${this.getDashboardStatsName}`, { }, this.getDashboardStatsModel);
    }

    private getDashboardStatsModel(data: object): DashboardModel {
        return new DashboardModel(data);
    }

    public getFavoriteBoards<T>(project_id: number): Observable<IResult<T>> {
        return this.http.request('GET', `${this.boardsCoreName}${this.favoriteBoardCoreName}`, { project_id }, this.getBoardsModel);
    }

    private getBoardsModel(data: object): DataListModel<IBoard, IBoardAdditional> {
        return new DataListModel<IBoard, IBoardAdditional>(data, BoardModel, BoardAdditionalModel);
    }

    public toggleBoardFavorite(board_id: number): Observable<IResult> {
        return this.http.request('POST', `${this.boardsCoreName}${this.toggleBoardFavoriteName}`, { board_id });
    }

    public getBoardCustomFieldsOptions<T>(id: number, page: number, page_size: number, q?: string): Observable<IResult<T>> {
        return this.http.request('GET', `${this.boardsCoreName}${id}/${this.getCustomFieldOptionsName}`, { page, page_size, q }, this.getBoardCustomFieldsOptionsModel);
    }

    private getBoardCustomFieldsOptionsModel(data: object): DataListModel<IBoardCustomFieldOption> {
        return new DataListModel<IBoardCustomFieldOption>(data, BoardCustomFieldOptionModel);
    }

    public updateCustomFieldOptions(boardId: number, optionId: number, data: any): Observable<IResult> {
        const payload = CustomFieldOptionDto.adapt(data);

        return this.http.request('POST', `${this.boardsCoreName}${boardId}/${this.getCustomFieldOptionsName}${optionId}/${this.updateCustomFieldOptionName}`, payload);
    }

    public createCustomFieldOptions(boardId: number, data: any): Observable<IResult> {
        const payload = CustomFieldOptionDto.adapt(data);

        return this.http.request('POST', `${this.boardsCoreName}${boardId}/${this.getCustomFieldOptionsName}${this.createCustomFieldOptionsName}`, payload);
    }

    public getBoardDetails<T>(id: number): Observable<IResult<T>> {
        return this.http.request('GET', `${this.boardsCoreName}${id}/`, { }, this.getBoardDetailsModel);
    }

    private getBoardDetailsModel(data: object): BoardDetailsModel {
        return new BoardDetailsModel(data);
    }
}
