import {
    ChangeDetectionStrategy,
    Component, effect, EventEmitter,
    Input,
    OnInit, Output, signal, Signal, ViewChild,
} from '@angular/core';
import { NgClass } from '@angular/common';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';

@Component({
    selector: 'app-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        MatPaginatorModule,
    ],
})
export class PaginatorComponent implements OnInit {
    @Input() data: Signal<any[]> = signal<any[]>([]);
    @Input() totalSize: Signal<number> = signal<number>(0);
    @Input() defaultPageSize: number = 10;
    @Input() pageSizeOptions: number[] = [5, 10, 20];

    @Output() public onFetchData: EventEmitter<{ currentPage: number, pageSize: number }> = new EventEmitter();

    public pageSize = signal<number>(0);
    public currentPage = signal<number>(0);
    private paginatorRef!: MatPaginator;

    @ViewChild(MatPaginator) set paginator(el: MatPaginator) {
        if (el) {
            this.paginatorRef = el;
        }
    };

    constructor() {
        effect(() => {
            if (this.totalSize()) {
                this.paginatorRef.firstPage();
            }
        });
    }

    ngOnInit(): void {
        this.pageSize.set(this.defaultPageSize);
    }

    public onPageChange(event: any): void {
        if (this.data().length === 0) {
            return;
        }

        if (event.pageSize !== this.pageSize()) {
            this.currentPage.set(0);
            this.paginatorRef.firstPage();
        } else {
            this.currentPage.set(event.pageIndex);
        }

        this.pageSize.set(event.pageSize);

        this.onFetchData.emit({ currentPage: this.currentPage() + 1, pageSize: this.pageSize() });
    }
}
