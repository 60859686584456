import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component, ElementRef,
    Input, OnDestroy,
    OnInit, ViewChild
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BuildVideoJsonPipe } from '../../pipe';
import { IFile } from '../../interfaces';
import lightGallery from 'lightgallery';
import lgZoom from 'lightgallery/plugins/zoom';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgVideo from 'lightgallery/plugins/video';
import lgFullscreen from 'lightgallery/plugins/fullscreen';

@Component({
    selector: 'app-media-viewer-modal',
    templateUrl: './media-viewer-modal.component.html',
    styleUrls: ['./media-viewer-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        BuildVideoJsonPipe
    ],
})
export class MediaViewerModalComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() public focusedMediaIndex!: number;
    @Input() public set media(media: IFile[]) {
        this._media = media.filter((file: IFile) => file.fileType === 'photo' || file.fileType === 'video');
    };

    @ViewChild('lightGalleryContainer', { static: false }) lightGalleryContainer!: ElementRef;

    public get media(): IFile[] {
        return this._media;
    }

    private _media: IFile[] = [];
    private lightGalleryInstance: any;

    constructor(public activeModal: NgbActiveModal) { }

    ngOnInit(): void { }

    ngAfterViewInit(): void {
        this.lightGalleryInstance = lightGallery(this.lightGalleryContainer.nativeElement, {
            plugins: [lgZoom, lgThumbnail, lgVideo, lgFullscreen],
            actualSize: false,
            showZoomInOutIcons: true,
            controls: true,
            autoplayVideoOnSlide: true,
            download: true,
            zoom: true,
            fullScreen: true,
        });

        this.lightGalleryContainer.nativeElement.addEventListener('lgBeforeClose', this.handleGalleryClose);

        this.lightGalleryInstance.openGallery(this.focusedMediaIndex);
    }

    public handleGalleryClose = () => {
        this.activeModal.close();
    }

    ngOnDestroy(): void {
        if (this.lightGalleryContainer) {
            this.lightGalleryContainer.nativeElement.removeEventListener('lgBeforeClose', this.handleGalleryClose);
        }

        if (this.lightGalleryInstance) {
            this.lightGalleryInstance.destroy();
        }
    }
}
