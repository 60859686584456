import { IDataList } from '../../interfaces';

export class DataListModel<T, U = undefined> implements IDataList<T, U> {
    private readonly _data: T[] = [];
    private readonly _additional: U | undefined;
    private readonly _total: number;

    constructor(
        data: any,
        modelClass: { new (data: any, additional?: U): T },
        additionalModelClass?: { new (data: any): U }
    ) {
        if (additionalModelClass) {
            this._additional = new additionalModelClass(data);
        }

        Object.keys(data['results']).map((key: string, index: number) => {
            this._data.push(
                new modelClass({
                    value: data['results'][key],
                    additional: this._additional,
                    length: data['results'].length,
                    index
                })
            );
        });

        this._total = data['count'] ?? 0;
    }

    get data() {
        return this._data;
    }

    get total() {
        return this._total;
    }

    get additional() {
        return this._additional;
    }
}
