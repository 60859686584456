import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IGeoAdditional, IGeoSuggestion, IResult } from 'src/app/interfaces';
import { HttpService } from '../http/http.service';
import {
    DataListModel, GeoAdditionalModel,
    GeoSuggestionDetailsModel, GeoSuggestionModel,
} from '../../models';

@Injectable({
    providedIn: 'root'
})
export class GeolocationService {
    readonly geolocationCoreName = 'geolocations/';
    readonly getConfirmedGeolocationName = 'confirmed/';
    readonly getSuggestionsName = 'suggestions/';

    constructor(private http: HttpService) { }

    public getSuggestions<T>(
        page: number,
        page_size: number,
        q: string = '',
        task_id: number | null = null,
        status: number | null = null
    ): Observable<IResult<T>> {

        return this.http.request('GET', `${ this.geolocationCoreName }${this.getSuggestionsName}`, { page, page_size, q, task_id, status }, this.getSuggestionsModel);
    }

    private getSuggestionsModel(data: object): DataListModel<IGeoSuggestion, IGeoAdditional> {
        return new DataListModel<IGeoSuggestion, IGeoAdditional>(data, GeoSuggestionModel, GeoAdditionalModel);
    }

    public getSuggestionDetails<T>(id: number): Observable<IResult<T>> {
        return this.http.request('GET', `${ this.geolocationCoreName }${this.getSuggestionsName}${id}/`, { }, this.getSuggestionDetailsModel);
    }

    private getSuggestionDetailsModel(data: object): GeoSuggestionDetailsModel {
        return new GeoSuggestionDetailsModel(data);
    }
}
