<div class="tag-wrapper" [ngClass]="{'is-list-item': !isSelected}" (click)="selectTag(tag.id)">
    @if (tag.id !== tagEditableId()) {
        <div class="tag" (mouseleave)="closeAllDropdowns()">
            <span [ngbTooltip]="isSelected ? tag.name : null">{{ tag.name }}</span>

            @if (isSelected) {
                <div class="selected-tag-buttons">
                    <div class="manage-tag-wrapper" ngbDropdown>
                        <button type="button" class="btn dropdown-toggle after-none"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false" ngbDropdownToggle (click)="$event.stopPropagation()">
                            <i class="bx bx-dots-vertical-rounded fs-18 d-flex align-items-center"></i>
                        </button>
                        <div class="dropdown-menu" ngbDropdownMenu>
                            <a class="dropdown-item"
                               (click)="$event.stopPropagation(); tagEditableId.set(tag.id)"> <i class='bx bx-pencil'></i>
                                Перейменувати</a>
                            <a class="dropdown-item delete"
                               (click)="$event.stopPropagation(); toggleModal(tag)"><i class="bx bx-trash-alt"></i> Видалити</a>
                        </div>
                    </div>

                    <button class="btn" (click)="unselectTag(tag.id)">
                        <i class="las la-times"></i>
                    </button>
                </div>
            }
        </div>

        @if (!isSelected) {
            <div class="tag-item-buttons">
                <button (click)="$event.stopPropagation(); tagEditableId.set(tag.id)">
                    <i class='bx bx-pencil'></i>
                </button>
                <button (click)="$event.stopPropagation(); toggleModal(tag)">
                    <i class="bx bx-trash-alt"></i>
                </button>
            </div>
        }
    } @else {
        <div class="edit-tag-wrapper">
            <input type="text" #editTagInput [value]="tag.name" (click)="$event.stopPropagation();"
                   (keydown.enter)="$event.stopPropagation(); updateTag(editTagInput.value, tag.id)"
                   (keydown.escape)="$event.stopPropagation(); tagEditableId.set(null)"
            >
            <button class="save" (click)="$event.stopPropagation(); updateTag(editTagInput.value, tag.id)"><i
                    class="las la-check"></i></button>
            <button class="cancel" (click)="$event.stopPropagation(); tagEditableId.set(null)"><i class="las la-times"></i>
            </button>
        </div>
    }
</div>
