import { Directive, HostListener, Output, EventEmitter, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    selector: '[pasteFiles]',
    standalone: true
})
export class PasteFilesDirective {
    @Output() filesPasted = new EventEmitter<File[]>();

    constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

    @HostListener('window:paste', ['$event'])
    onPaste(event: ClipboardEvent) {
        const activeElement = this.renderer.selectRootElement(':focus', true);

        if (activeElement !== this.elementRef.nativeElement) {
            return;
        }

        const files: File[] = [];
        const items = event.clipboardData?.items;

        if (items) {
            Array.from(items).forEach((item) => {
                if (item.kind === 'file') {
                    const file = item.getAsFile();
                    if (file) {
                        files.push(file);
                    }
                }
            });
        }

        if (files.length > 0) {
            this.filesPasted.emit(files);
        }
    }
}
