<form (ngSubmit)="onSubmitConfirm2Fa()">
    <div class="security-content">
        <div class="security-text">
            <h3>Інструкція</h3>
            <p class="m-0">1. Скачайте застосунок Google Authenticator, якщо він у Вас не встановлений</p>
            <div class="stores">
                <div class="store">
                    <a class="google" target="_blank"
                       href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"></a>
                    <img src="assets/images/settings/google-play-qr.png" alt="google-play-qr">
                </div>
                <div class="store">
                    <a class="apple" target="_blank"
                       href="https://apps.apple.com/ru/app/google-authenticator/id388497605"></a>
                    <img src="assets/images/settings/app-store-qr.png" alt="app-store-qr">
                </div>
            </div>
            <p class="m-0">2. Відскануйте QR код який знаходиться праворуч, через застосунок <b>Google
                Authenticator</b></p>
            <p class="m-0">3. Після успішного додавання, введіть код в поле, та натисніть підтвердити</p>
        </div>

        <div class="security-qr">
            <p>Відскануйте через додаток Google Authenticator</p>
            @if (qrcodeUrl()) {
                <qrcode [qrdata]="qrcodeUrl()" [margin]="1" [elementType]="'img'" [width]="260"></qrcode>
            }
        </div>
    </div>

    <div class="buttons d-flex justify-content-between align-items-center my-3">
        <div>
            <label>Введіть будь-ласка код Google Autentificator</label>
            <ng-otp-input [config]="otpConfig" (onInputChange)="onOtpChange($event)"></ng-otp-input>
        </div>

        <div class="buttons">
            <button class="btn btn-settings w-100" [ngClass]="{ 'loading': loadingBtn() }" [disabled]="otpControl.invalid">
                <i class="las la-check-circle la-lg"></i>Підключити
            </button>
        </div>
    </div>
</form>
