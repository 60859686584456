<div class="suggestions-list-accordion" ngbAccordion [closeOthers]="false">
    @for (suggestion of suggestions(); track $index) {
        <div ngbAccordionItem [collapsed]="suggestion.id !== locationId" [disabled]="suggestion.id === locationId">
            <h2 ngbAccordionHeader class="accordion-header border-0" [ngClass]="{ 'is-location': suggestion.id === locationId }">
                <button ngbAccordionButton>
                    <div class="w-100 d-flex justify-content-between align-items-center">
                        <div>
                            Результат {{ $index + 1 }}
                            <span class="badge rounded-pill mx-2" [ngStyle]="{ backgroundColor: suggestion.status.color }">
                                        {{ suggestion.status.name }}
                                    </span>
                        </div>
                        <div class="avatar">
                            <img [src]="suggestion.team.avatar" [ngbTooltip]="suggestion.team.name" alt="" class="rounded-circle ">
                        </div>
                    </div>
                </button>
            </h2>
            <div ngbAccordionCollapse [ngClass]="{ 'is-location': suggestion.id === locationId }">
                <div ngbAccordionBody>
                    <div class="fields">
                        <div class="coords">
                            <label for="coords" class="form-label">Знайдені координати</label>
                            <input type="text" class="form-control" id="coords" required [value]="suggestion.latitude + ', ' + suggestion.longitude" disabled>
                        </div>
                        <div class="time-code">
                            <label for="time-code" class="form-label">Тайм-код</label>
                            <input type="text" class="form-control" id="time-code" [value]="suggestion.timeCode" disabled>
                        </div>
                    </div>
                    <ng-template>
                        @if (suggestion.files.length > 0) {
                            <p class="mt-3 mb-2">Докази ({{ suggestion.files.length }})</p>
                            <app-files-preview [files]="suggestion.files"></app-files-preview>
                        }

                        @if (suggestion.comment && userIsAdmin()) {
                            <p class="mt-3 mb-2">Коментар</p>
                            <p class="comment">{{ suggestion.comment }}</p>
                        }
                    </ng-template>
                </div>
            </div>
        </div>

        @if (suggestion.id === locationId && suggestions().length > 1) {
            <p class="my-3 fw-semibold">
                Інші пропозиції
            </p>
        }
    }
</div>
