import { NgClass, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component, EventEmitter, Input,
    OnInit, Output,
    signal
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-ai-report-modal',
    templateUrl: './ai-report-modal.component.html',
    styleUrls: ['./ai-report-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        TranslateModule,
        RouterLink,
        NgClass,
    ],
})
export class AiReportModalComponent implements OnInit {
    @Input() public taskId!: number;
    @Output() public onSubmitReport: EventEmitter<boolean> = new EventEmitter();

    public loadingBtn = signal<boolean>(false);

    constructor(public activeModal: NgbActiveModal) { }

    ngOnInit(): void { }

    public submitReport() {
        this.onSubmitReport.emit(true);
        this.activeModal.close();
    }
}
