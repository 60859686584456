import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResult } from 'src/app/interfaces';
import { HttpService } from '../http/http.service';
import { IRegister } from '../../interfaces';

@Injectable({
    providedIn: 'root'
})
export class SupportService {
    readonly getSupportDepartureName = 'support/departments/';
    readonly createSupportTicketName = 'support/tickets/create/';

    constructor(
        private http: HttpService
    ) { }

    public getSupportDeparture(): Observable<IResult> {
        return this.http.request('GET', this.getSupportDepartureName, { });
    }

    public createSupportTicket(ticket: any): Observable<IResult> {
        return this.http.request('POST', this.createSupportTicketName, ticket);
    }

}
