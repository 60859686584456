import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef, EventEmitter,
    inject, Input,
    OnInit, Output,
    signal
} from '@angular/core';
import { debounceTime, Subject } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
    selector: 'app-search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
    ],
})
export class SearchInputComponent implements OnInit {
    @Input() public minLength = 3;
    @Input() public borderNone = false;
    @Output() public onSearch: EventEmitter<string> = new EventEmitter();

    public searchText = signal<string>('');

    private debounceTime = 500;
    private onSubmitSearch$ = new Subject<string>();
    private destroy = inject(DestroyRef);

    constructor() {}

    ngOnInit(): void {
        this.onSubmitSearch$
            .pipe(
                debounceTime(this.debounceTime),
                takeUntilDestroyed(this.destroy)
            )
            .subscribe((searchTerm) => {
                if (searchTerm.length >= this.minLength || searchTerm.length === 0) {
                    this.onSearch.emit(searchTerm);
                }
            });
    }

    public search(): void {
        this.onSubmitSearch$.next(this.searchText());
    }

    public clear(): void {
        this.searchText.set('');
        this.search();
    }
}
