<div class="tags-wrapper">
    <div class="tags-list">
        @for (tag of selectedTags; track $index) {
            <app-tag [tag]="tag" [isSelected]="true" (onTagAction)="tagAction($event)"></app-tag>
        }

        <div class="dropdown" ngbDropdown #dropdown="ngbDropdown" (openChange)="toggleDropdown()">
            <button class="btn add-btn dropdown-toggle after-none" type="button" id="dropdownMenuButton1"
                    data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                <i class="las la-plus la-1x"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-start" aria-labelledby="dropdownMenuButton1"
                 ngbDropdownMenu>
                <div class="search-settings">
                    <div class="search-block">
                        <div class="search-box d-inline-block">
                            @if (dropdown.isOpen()) {
                                <input type="text" class="form-control search" #searchInput
                                       [(ngModel)]="searchText" (keydown.enter)="$event.stopPropagation(); createTag(searchText())"
                                       placeholder="Введіть текст, щоб додати тег">
                                <i class="ri-search-line search-icon"></i>
                            }
                        </div>
                        <span class="mdi mdi-close-circle search-widget-icon-close" id="search-close-options"
                              [ngClass]="{'d-none': !searchText()}" (click)="clear()"></span>
                    </div>
                    <button class="settings-btn" (click)="toggleModal()"><i class="mdi mdi-cog-outline"></i></button>
                </div>

                @if (tags.length > 0) {
                    <div class="items-list">
                        @for (tag of tags | filterArray: searchText():'name'; track $index) {
                            <app-tag [tag]="tag" (onTagAction)="tagAction($event)"></app-tag>
                        }
                    </div>
                }

                @if (showCreateTagBtn) {
                    <div class="submit">
                        <button class="btn-transparent justify-content-start px-1" (click)="createTag(searchText())">
                            <i class="las la-plus-circle la-lg"></i> Додати "{{ searchText() }}"
                        </button>
                    </div>
                }
            </div>
        </div>
    </div>
</div>
