import { createReducer, on } from '@ngrx/store';
import { loadProjects, loadProjectsFailure, loadProjectsSuccess, setSelectedProject } from './project.actions';
import { IDataList, IProject } from '../../interfaces';

export interface ProjectsState {
    projects: IDataList<IProject> | null;
    selectedProject: IProject | null;
    loading: boolean;
    error: string | null;
}

export const initialState: ProjectsState = {
    projects: null,
    selectedProject: null,
    loading: false,
    error: null,
};

export const projectReducer = createReducer(
    initialState,
    on(loadProjects, (state) => ({ ...state, loading: true })),
    on(loadProjectsSuccess, (state, { projects }) => ({ ...state, loading: false, projects })),
    on(loadProjectsFailure, (state, { error }) => ({ ...state, loading: false, error })),
    on(setSelectedProject, (state, { project }) => ({ ...state, selectedProject: project })),
);
