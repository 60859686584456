import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component, DestroyRef, EventEmitter,
    inject, Input,
    OnInit, Output, signal,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import {
    ChangeUserAccessModalComponent, ConfirmModalComponent,
    PaginatorComponent,
    SearchInputComponent,
    ToastsContainer
} from 'src/app/shared';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle, NgbHighlight, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BreadcrumbsComponent } from '../../shared';
import {
    IDataList,
    IPagination,
    IResult,
    IUser,
    IUserAdditional,
    IUserUpdateRequest
} from '../../interfaces';
import { ToastsService, UsersService } from '../../services';
import { TUserLevel, TUsersAction } from '../../helpers';
import { NgSelectModule } from '@ng-select/ng-select';
import { CopyToClipboardDirective } from '../../directives';
import { first, Subject, switchMap } from 'rxjs';
import { InviteUserModalComponent } from './invite-user-modal/invite-user-modal.component';
import { UserAdditionalModel } from '../../models';

@Component({
    selector: 'app-users-table',
    templateUrl: './users-table.component.html',
    styleUrls: ['./users-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        TranslateModule,
        RouterLink,
        NgClass,
        ToastsContainer,
        NgbHighlight,
        AsyncPipe,
        BreadcrumbsComponent,
        PaginatorComponent,
        NgbDropdown,
        NgbDropdownMenu,
        NgbDropdownToggle,
        NgSelectModule,
        CopyToClipboardDirective,
        SearchInputComponent,
    ],
})
export class UsersTableComponent implements OnInit {
    @Input() public projectId: number | undefined;
    @Input() public roleId: number | undefined;
    @Input() public without: 'role_id' | 'project_id' | null = null;
    @Input() public onReload$: Subject<boolean> | undefined;

    @Output() public onAddedUserToRole: EventEmitter<number> = new EventEmitter();
    @Output() public onDeletedUsersFromRole: EventEmitter<number[]> = new EventEmitter();

    public actionType: 'block' | 'delete' = 'block';
    public userAdditional: IUserAdditional = new UserAdditionalModel();

    public selectedStatus = signal<string | null>(null);
    public selectedLevel = signal<string | null>(null);
    public users = signal<IUser[]>([]);
    public roleUsers = signal<IUser[]>([]);
    public totalSize = signal<number>(0);
    public isToggledAllState = signal<boolean>(false);
    public checkedUserIds = signal<number[]>([]);

    private currentPage: number = 1;
    private pageSize: number = 10;
    private searchText: string | undefined;
    private getUsersTrigger$ = new Subject<IPagination>();
    private getUsersByRoleTrigger$ = new Subject<IPagination>();
    private destroy = inject(DestroyRef);

    constructor(
        private modalService: NgbModal,
        private usersService: UsersService,
        private toastsService: ToastsService,
    ) { }

    ngOnInit(): void {
        if (this.onReload$) {
            this.onReload$
                .pipe(takeUntilDestroyed(this.destroy))
                .subscribe(() => {
                    this.getUsersTrigger$.next({ page: 1, pageSize: this.pageSize });
                });
        }

        this.getUsersTrigger$
            .pipe(
                switchMap((pagination: IPagination) => {
                    this.currentPage = pagination.page;

                    return this.usersService.getUsers<IDataList<IUser>>(
                        pagination.page,
                        pagination.pageSize,
                        this.searchText,
                        this.selectedStatus(),
                        this.selectedLevel(),
                        this.without,
                        this.projectId,
                        this.roleId
                    )
                }),
                takeUntilDestroyed(this.destroy)
            )
            .subscribe((result: IResult<IDataList<IUser>>) => {
                if (result.isSuccess) {
                    this.totalSize.set(result.data.total);
                    this.users.set(result.data.data);
                    this.isToggledAllState.set(false);
                    this.checkedUserIds.set([]);
                }
            });

        this.getUsersByRoleTrigger$
            .pipe(
                switchMap((pagination: IPagination) => {
                    return this.usersService.getUsers<IDataList<IUser>>(
                        pagination.page,
                        pagination.pageSize,
                        this.searchText,
                        null,
                        null,
                        'role_id',
                        undefined,
                        this.roleId
                    )
                }),
                takeUntilDestroyed(this.destroy)
            )
            .subscribe((result: IResult<IDataList<IUser>>) => {
                if (result.isSuccess) {
                    this.roleUsers.set(result.data.data);
                }
            })

        this.getUsersTrigger$.next({ page: this.currentPage, pageSize: this.pageSize });
    }

    public toggleUserCheckbox(userId: number): void {
        const currentIds: number[] = this.checkedUserIds();

        if (currentIds.includes(userId)) {
            this.checkedUserIds.set(currentIds.filter(existingId => existingId !== userId));
        } else {
            this.checkedUserIds.set([...currentIds, userId]);
        }
    }

    public toggleAll() {
        this.checkedUserIds.set(this.isToggledAllState() ? this.getUserIds(this.users()) : []);
    }

    public toggleRoleUsersDropdown(isOpen: boolean): void {
        if (isOpen) {
            this.roleUsers.set([]);
            this.getUsersByRoleTrigger$.next({ page: 1, pageSize: 50 });
        }
    }

    public toggleModal(modal: string, size: string, data?: any): void {
        let modalComp;

        if (modal === 'invite-user') {
            modalComp = InviteUserModalComponent;
        } else if (modal === 'change-user-access') {
            data['actionType'] = data['actionType'] as TUsersAction;
            data['usersIds'] = this.checkedUserIds();
            modalComp = ChangeUserAccessModalComponent;
        } else if (modal === 'confirm-delete-user-role') {
            data['title'] = data['username'] ?
                `Ви дійсно хочете видалити користувача ${data['username']} з поточної ролі?` :
                `Ви дійсно хочете видалити обраних користувачів з поточної ролі?`;
            modalComp = ConfirmModalComponent;
        } else if (modal === 'confirm-change-role') {
            data['title'] = `Ви дійсно хочете змінити рівень доступу для ${data['user']['username']}?`;
            data['content'] = `Змінити з рівня ${ data['user']['level'].value }
                на рівень ${ this.userAdditional.levelsMap[data['newLevel'] as TUserLevel].value }?`;
            data['isConfirm'] = true;
            modalComp = ConfirmModalComponent;
        }

        const modalRef = this.modalService.open(modalComp, { centered: true, size: size ?? 'bg' });
        modalRef.componentInstance.data = data;

        if (modal === 'invite-user') {
            modalRef.componentInstance.onUpdate
                .pipe(first())
                .subscribe(() => {

                });
        } else if (modal === 'change-user-access') {
            modalRef.componentInstance.onChangeUserAccess
                .pipe(first())
                .subscribe(() => {
                    this.getUsersTrigger$.next({ page: this.currentPage, pageSize: this.pageSize });
                });
        } else if (modal === 'confirm-delete-user-role') {
            modalRef.componentInstance.onConfirm
                .pipe(first())
                .subscribe(() => {
                    this.onDeletedUsersFromRole.emit(data['ids'])
                });
        } else if (modal === 'confirm-change-role') {
            modalRef.componentInstance.onConfirm
                .pipe(first())
                .subscribe(() => {
                    this.changeUserLevel(data['user']['id'], { isAdmin: data['newLevel'] === 'is_admin' });
                });
        }
    }

    public onLevelSelect(): void {
        this.getUsersTrigger$.next({ page: this.currentPage, pageSize: this.pageSize });
    }

    public onStatusSelect(): void {
        this.getUsersTrigger$.next({ page: this.currentPage, pageSize: this.pageSize });
    }

    public resetLevel(): void {
        this.selectedLevel.set(null);
        this.onLevelSelect();
    }

    public resetStatus(): void {
        this.selectedStatus.set(null);
        this.onStatusSelect();
    }

    public fetchSearchData(searchTerm: string): void {
        this.searchText = searchTerm;
        this.getUsersTrigger$.next({ page: 1, pageSize: this.pageSize });
    }

    public fetchSearchRoleData(searchTerm: string): void {
        this.searchText = searchTerm;
        this.getUsersByRoleTrigger$.next({ page: 1, pageSize: this.pageSize });
    }

    public fetchPageData(event: any): void {
        this.isToggledAllState.set(false);
        this.toggleAll();
        this.getUsersTrigger$.next({ page: event.currentPage, pageSize: event.pageSize })
    }

    private getUserIds(users: IUser[]): number[] {
        return users.map((user: IUser) => user.id);
    }

    private changeUserLevel(userId: number, requestData: IUserUpdateRequest): void {
        this.usersService.updateUser(userId, requestData)
            .pipe(first())
            .subscribe((result: IResult) => {
                if (result.isSuccess) {
                    this.toastsService.show('Рівень доступу користувача змінено', { classname: 'bg-secondary' });
                    this.getUsersTrigger$.next({ page: this.currentPage, pageSize: this.pageSize });
                }
            });
    }
}
