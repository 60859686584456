<div class="slider-block" dir="ltr">
    @if (media.length > 3) {
        <div class="media-slider">
            <owl-carousel-o [options]="customOptions">
                @for (item of media; track $index) {
                    <ng-template carouselSlide>
                        <ng-container *ngTemplateOutlet="mediaRef; context: { data: { item, index: $index } }"></ng-container>
                    </ng-template>
                }
            </owl-carousel-o>
        </div>
    } @else {
        <div class="media-wrapper">
            @for (item of media; track $index) {
                <ng-container *ngTemplateOutlet="mediaRef; context: { data: { item, index: $index } }"></ng-container>
            }
        </div>
    }
</div>

<ng-template #mediaRef let-data="data">
    <div class="img-wrapper" [ngClass]="{ 'video-preview': data.item.fileType === 'video' }">
        <img [src]="data.item.thumbUrl ?? data.item.url" alt="">
        <button class="btn-preview {{data.item.fileType}}" (click)="showMediaViewerModal(data.index)"></button>
    </div>
</ng-template>
