import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { IResult } from 'src/app/interfaces';
import { AuthTokenModel, ResultModel } from 'src/app/models';
import { AuthTokenService, ToastsService } from 'src/app/services';
import { environment } from '../../../environments/environment';
import { TMethod } from '../../helpers';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    readonly tokenRefreshName = 'account/token/refresh/';

    private destroy = inject(DestroyRef);
    private isRefreshToken = false;
    private tokenRefreshWorked$: Subject<'success' | 'error'> = new Subject<'success' | 'error'>();

    constructor(
        private http: HttpClient,
        private translate: TranslateService,
        private authTokenService: AuthTokenService,
        private toastsService: ToastsService,
    ) { }

    public request(method: TMethod, action: string, params: any = {}, callback = this.emptyFunction): Observable<IResult> {
        if (this.authTokenService.isTokenExpired()) {
            if (this.isRefreshToken) {
                return this.tokenRefreshWorked$.pipe(
                    mergeMap((res) => res === 'success' ? this.sendRequest(method, action, params, callback) : of())
                )
            }

            this.isRefreshToken = true;

            return this.tokenRefresh(this.authTokenService.refreshToken)
                .pipe(
                    map((result: IResult | any) => this.checkResult(result)),
                    mergeMap((res) => {
                        if (res) {
                            this.tokenRefreshWorked$.next('success');
                            return this.sendRequest(method, action, params, callback);
                        }

                        this.tokenRefreshWorked$.next('error');
                        return of();
                    }),
                    catchError(this.handleError())
                );
        } else {
            return this.sendRequest(method, action, params, callback);
        }
    }

    public tokenRefresh(refresh: string | null): Observable<any> {
        return this.sendRequest('POST', this.tokenRefreshName, { refresh }, this.tokenRefreshFunction);
    }

    public checkResult(result: IResult | any): boolean {
        if (result.isSuccess) {
            localStorage.removeItem('authToken');
            this.authTokenService.accessToken = null;
            this.authTokenService.refreshToken = null;
            result.data.createTokenAuth();
            this.authTokenService.accessToken = result.data.accessToken;
            this.authTokenService.refreshToken = result.data.refreshToken;
            this.isRefreshToken = false;
        }

        return result.isSuccess ?? false;
    }

    private tokenRefreshFunction(data: any): AuthTokenModel | null {
        if (data.length === 0) {
            return null;
        }

        return new AuthTokenModel(data);
    }

    private responseProcessing(response: any, action: string, callback = this.emptyFunction) {
        const obResult = new ResultModel();
        obResult.action = action;
        obResult.data = callback(response);

        return obResult;
    }

    private sendRequest(method: TMethod, action: string, data: any, callback = this.emptyFunction): Observable<IResult> {
        if (method === 'POST') {
            return this.http.post(`${environment.apiUrl}${action}`, data)
                .pipe(
                    map((response) => {
                        return this.responseProcessing(response, action, callback);
                    }),
                    catchError(this.handleError())
                );

        } else if (method === 'GET') {
            let params = new HttpParams();

            for (const paramKey in data) {
                if (data[paramKey] === null || data[paramKey] === undefined) {
                    continue;
                }

                params = params.set(paramKey, data[paramKey].toString());
            }

            return this.http.get(`${environment.apiUrl}${action}`, { params })
                .pipe(
                    map((response) => {
                        return this.responseProcessing(response, action, callback);
                    }),
                    catchError(this.handleError())
                );
        } else if (method === 'PATCH') {
            return this.http.patch(`${environment.apiUrl}${action}`, data)
                .pipe(
                    map((response) => {
                        return this.responseProcessing(response, action, callback);
                    }),
                    catchError(this.handleError())
                );
        } else if (method === 'PUT') {
            return this.http.put(`${environment.apiUrl}${action}`, data)
                .pipe(
                    map((response) => {
                        return this.responseProcessing(response, action, callback);
                    }),
                    catchError(this.handleError())
                );
        }


        return of();
    }

    private handleError() {
        return (error: HttpErrorResponse) => {
            if (error.status === 500 || !error.status) {
                if (!environment.production) {
                    console.log(error);
                }

                this.showDefaultErrorMsg();
            } else if (error.status === 401) {
                this.toastsService.show(error.error.detail, { classname: 'bg-danger text-white' });
                this.authTokenService.userLogout();
            } else {
                const errorMsgKey = error.error['detail'];

                this.translate.get([errorMsgKey])
                    .pipe(takeUntilDestroyed(this.destroy))
                    .subscribe(translations => {
                        if (translations[errorMsgKey] !== errorMsgKey) {

                        } else if (error.error?.detail) {
                            this.toastsService.show(error.error.detail, { classname: 'bg-danger text-white' });
                        } else {
                            this.showDefaultErrorMsg();
                        }
                    });
            }

            const result: IResult = {
                isSuccess: false,
                data: null,
                error: error,
                action: ''
            };

            return of(result);
        };
    }

    private emptyFunction(data: any) {
        return data;
    }

    private showDefaultErrorMsg(): void {
        this.toastsService.show('DEFAULT_ERROR', { classname: 'bg-danger text-white' });
    }
}
