import { ITwoFaSetup } from '../../interfaces';

export class TwoFaSetupModel implements ITwoFaSetup {
    private readonly _otpUri: string;
    private readonly _qrCode: string;

    constructor(data: any) {
        this._otpUri = data['otp_uri'] ?? null;
        this._qrCode = data['qr_code'] ?? null;
    }

    get otpUri() {
        return this._otpUri;
    }

    get qrCode() {
        return this._qrCode;
    }
}
