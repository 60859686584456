import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { JsonPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { IFile } from '../../interfaces';
import { LightgalleryModule } from 'lightgallery/angular';
import { BuildVideoJsonPipe } from '../../pipe';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MediaViewerModalComponent } from '../media-viewer-modal/media-viewer-modal.component';

@Component({
    selector: 'app-media-slider',
    templateUrl: './media-slider.component.html',
    styleUrls: ['./media-slider.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CarouselModule,
        NgClass,
        LightgalleryModule,
        BuildVideoJsonPipe,
        NgTemplateOutlet,
        JsonPipe,
    ],
})
export class MediaSliderComponent implements OnInit {
    @Input() public media!: IFile[];

    customOptions: OwlOptions = {
        loop: true,
        dots: true,
        navSpeed: 500,
        autoWidth: true,
        navText: ['', ''],
        items: 3,
        margin: 20,
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 2
            },
            740: {
                items: 3
            },
            940: {
                items: 4
            }
        },
        nav: true
    }

    constructor(
        private cd: ChangeDetectorRef,
        private modalService: NgbModal
    ) { }

    ngOnInit(): void {
        this.cd.detectChanges();
    }

    public showMediaViewerModal(index: number) {
        const modalRef = this.modalService.open(MediaViewerModalComponent);
        modalRef.componentInstance.media = this.media;
        modalRef.componentInstance.focusedMediaIndex = index;
    }
}
