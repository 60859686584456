import { IPublicUser, ITaskHistoryAdditional, ITaskHistoryItem } from '../../interfaces';
import * as moment from 'moment';

export class TaskHistoryItemModel implements ITaskHistoryItem {
    private readonly _id: number;
    private readonly _description: string;
    private readonly _date: string;
    private readonly _user: IPublicUser;

    constructor(data: { value: any, additional: ITaskHistoryAdditional }) {
        this._id = data['value']['id'] ?? null;
        this._description = data['value']['description'] ?? null;
        this._date = moment(data['value']['timestamp']).format('DD.MM.YYYY HH:mm:ss');
        this._user = data['value']['user_id'] ?
            data['additional']['usersMap'][data['value']['user_id']] :
            {
                id: 0,
                username: 'Система',
                avatar: '/assets/images/user-dummy-img.jpg'
            };
    }

    get id() {
        return this._id;
    }

    get description() {
        return this._description;
    }

    get date() {
        return this._date;
    }

    get user() {
        return this._user;
    }
}
