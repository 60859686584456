<div class="modal-header p-4 justify-content-between">
    <h6 class="modal-title">Запросити користувачів</h6>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span>&times;</span>
    </button>
</div>

<div class="modal-body pt-0 p-4">
    <form [formGroup]="inviteUsersForm" (ngSubmit)="onSubmit()">
        <div class="fields">
            <div class="mb-3">
                <label class="form-label">Пошта</label>
                <ng-select
                        class="default-list"
                        [multiple]="true"
                        [placeholder]="'Введіть email аби додати користувача'"
                        [addTag]="addUser"
                        [addTagText]="'Запросити користувача'"
                        [ngClass]="{ 'is-invalid': f['email'].touched && f['email'].errors }"
                >
                    <ng-template let-item="item" ng-option-tmp>
                        {{ item | json }}
                    </ng-template>
                </ng-select>

                @if (f['email'].touched && f['email'].errors) {
                    <div class="invalid-feedback">
                        Введіть коректний email
                    </div>
                }
            </div>
            <div class="category">
                <label class="form-label">Рівень</label>
                <ng-select class="select-container" bindLabel="name"
                           [items]="levels"
                           [placeholder]="'Рівень'"
                           [clearable]="false"
                           [bindLabel]="'value'"
                           [bindValue]="'key'"
                           formControlName="levelId">
                    <ng-template ng-option-tmp let-item="item">
                        <div class="ng-option-name">
                            {{ item.value }}
                        </div>
                    </ng-template>
                </ng-select>
                @if (f['levelId'].touched && f['levelId'].errors) {
                    <div class="invalid-feedback d-block">
                        <div>Оберіть рівень</div>
                    </div>
                }
            </div>
        </div>

        <div class="d-flex justify-content-end">
            <button class="btn btn-gray" (click)="activeModal.dismiss('Cross click')">Відміна</button>
            <button type="submit" class="btn btn-default ms-2" [ngClass]="{'loading': loadingBtn()}"
                    [disabled]="inviteUsersForm.invalid"> Запросити
            </button>
        </div>
    </form>
</div>


