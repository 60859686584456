import { UntypedFormControl, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import { b } from '@fullcalendar/core/internal-common';

@Injectable({
    providedIn: 'root'
})
export class ValidationLayerService {
    public validationSetting: any = {
        email: [
            this.requiredTrim,
            Validators.minLength(6),
            Validators.maxLength(50),
            this.validateEmailControl.bind(this)
        ],
        emailNotRequired: [
            this.validateEmailControl.bind(this)
        ],
        phone: [
            Validators.minLength(2),
            Validators.maxLength(17),
            this.onlyNumberValidator,
        ],
        password: [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(32),
            this.whitespaceValidatorCyrillic,
            this.patternValidator,
            this.containsNonLatinLetters,
        ],
        coords: [
            Validators.required,
            this.coordsValidator,
        ],
        notEmptyArray: [
            this.notEmptyArrayValidator,
        ],
        telegramUrl: [
            this.checkTelegramUrl,
        ],
        atLeastTwoItemRequired: [
            this.atLeastTwoItemRequiredValidator,
        ],
    };

    private readonly emailPattern = /^[=_0-9a-z+~'!\$&*^`|\\#%/?{}-]+(\.[=_0-9a-z+~'!\$&*^`|\\#%/?{}-]+)*@(([-0-9a-z]+\.)+)([a-z0-9-]{2,20})$/i;

    public get(name: string): any {
        return this.validationSetting[name] ?? [];
    }

    public isValidEmailByString (email: string): boolean {
        return this.emailPattern.test(email);
    }

    private validateEmailControl(control: UntypedFormControl) {
        const pass = control.value;

        if (!pass) {
            return null;
        }

        return this.emailPattern.test(pass) ? null : { customValidEmail: true };
    }

    private notEmptyArrayValidator(control: UntypedFormControl) {
        const value = control.value;

        if (Array.isArray(value) && value.length === 0) {
            return { emptyArray: true };
        }

        return null;
    }

    private requiredTrim(control: UntypedFormControl) {
        return !control.value || (control.value && (control.value === '' || control.value.trim() === ''))
            ? { required: true }
            : null;
    }

    private coordsValidator(control: UntypedFormControl) {
        const coords = control.value;
        const re = /^-?\d{1,3}(\.\d+)?,\s*-?\d{1,3}(\.\d+)?$/;

        if (!coords) {
            return null;
        }

        return re.test(coords) ? null : { coordsValidator: true };
    }

    private whitespaceValidatorCyrillic(control: UntypedFormControl) {
        const pass = control.value;
        const re = /^[\w!@#$%^&*()+]+$/i;
        if (!pass) {
            return null;
        }

        return re.test(pass) ? null : { whitespaceValidatorCyrillic: true };
    }

    private patternValidator(control: UntypedFormControl) {
        const pass = control.value;
        let status = 0;

        if (!pass) {
            return null;
        }

        if (/[0-9]/g.test(pass)) {
            status++;
        }

        if (/[a-z]/g.test(pass)) {
            status++;
        }

        if (/[A-Z]/g.test(pass)) {
            status++;
        }

        if (/[!@#$%^&*()\-_+={}[]|\\:;"'<>,.?\/]/g.test(pass)) {
            status++;
        }

        return status > 3 ? null : { patternValidator: true };
    }

    private containsNonLatinLetters(control: UntypedFormControl) {
        const pass = control.value;

        if (!pass) {
            return false;
        }

        const allowedPattern = /^[0-9a-zA-Z!@#$%^&*()\-_+={}\[\]\|\\:;"'<>,.?/\s]*$/;

        return allowedPattern.test(pass) ? null : { notAllowedValidator: true };
    }

    private onlyNumberValidator(control: UntypedFormControl) {
        const _pass = control.value;
        const re = /^[0-9]+$/i;

        if (!_pass) {
            return null;
        }

        return re.test(_pass) ? null : { onlyNumberValidator: true };
    }

    private checkTelegramUrl(control: UntypedFormControl) {
        const value = control.value;

        if (!value || value.startsWith('https://t.me/')) {
            return null;
        }

        return { invalidTelegramUrl: true };
    }

    private atLeastTwoItemRequiredValidator(control: UntypedFormControl) {
        if (Array.isArray(control.value) && control.value.length > 1) {
            return null;
        }

        return { atLeastOneRequired: true };
    }
}

