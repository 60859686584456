export const WSTAA = {
    ON: {
        GETTASKUPDATES: 't', // отримання оновлень завдання
        GETBOARDUPDATES: 'b', // отримання оновлень дошки завдань
        NOTIFICATIONS: 'n', // отримання сповіщень
    },


    SEND: {
        SUBTASKUPDATES: 'ts', // підписка на оновлення деталей завдання
        UNSUBTASKUPDATES: 'tu', // відписка від оновлення деталей завдання
        SUBBOARDUPDATES: 'bs', // підписка на отримання деталей завдання
        UNSUBBOARDUPDATES: 'bu', // відписка від отримання деталей завдання
        SUBNOTIFICATIONS: 'ns', // підписка на отримання сповіщень
        UNSUBNOTIFICATIONS: 'nu', // відписка від отримання сповіщень
    }
};
