import { ICustomFieldType } from '../../interfaces';

export class CustomFieldTypeModel implements ICustomFieldType {
    private readonly _key: string;
    private readonly _name: string;

    constructor(data: { value: any }) {
        this._key = data['value']['key'] ?? null;
        this._name = data['value']['name'] ?? null;
    }

    get key() {
        return this._key;
    }

    get name() {
        return this._name;
    }
}
