import { IAuthToken } from '../../interfaces';

export class AuthTokenModel implements IAuthToken {
    private readonly _accessToken!: string;
    private readonly _refreshToken!: string;
    private readonly _expirationTime!: number;
    private readonly _twoFaToken: string | null = null;

    constructor(data: any) {
        if (data['2fa_token']) {
            this._twoFaToken = data['2fa_token'] ?? null;
        } else {
            this._accessToken = data['access'] ?? null;
            this._refreshToken = data['refresh'] ?? null;
            const expiresAt = new Date(data['expires_at'] ?? 0);
            this._expirationTime = Math.floor(expiresAt.getTime() / 1000);
        }
    }

    get accessToken() {
        return this._accessToken;
    }

    get refreshToken() {
        return this._refreshToken;
    }

    get twoFaToken() {
        return this._twoFaToken;
    }

    get expirationTime() {
        return this._expirationTime;
    }

    public createTokenAuth(): void {
        if (this.accessToken && this.refreshToken) {
            localStorage.setItem('authToken', JSON.stringify({
                accessToken: this.accessToken,
                refreshToken: this.refreshToken,
                expirationTime: this.expirationTime,
            }));
        }
    }
}
