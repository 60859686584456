import { ITaskCardBoard } from '../../interfaces';

export class TaskCardBoardModel implements ITaskCardBoard {
    private readonly _id: number;
    private readonly _name: string;
    private readonly _icon: string;
    private readonly _isNavigation: boolean;
    private readonly _categoryFilter: boolean;
    private readonly _isTab: boolean;
    private readonly _taskCount: number;

    constructor(data: { value: any }) {
        this._id = data['value']['id'] ?? null;
        this._name = data['value']['name'] ?? null;
        this._icon = data['value']['icon'] ?? null;
        this._isNavigation = data['value']['is_navigation'] ?? null;
        this._categoryFilter = data['value']['category_filter'] ?? null;
        this._isTab = data['value']['is_tab'] ?? null;
        this._taskCount = data['value']['task_count'] ?? 0;
    }

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    get icon() {
        return this._icon;
    }

    get isNavigation() {
        return this._isNavigation;
    }

    get categoryFilter() {
        return this._categoryFilter;
    }

    get isTab() {
        return this._isTab;
    }

    get taskCount() {
        return this._taskCount;
    }
}
