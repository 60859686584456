import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectsState } from './project.reducer';

export const selectProjectsState = createFeatureSelector<ProjectsState>('projects');

export const selectProjects = createSelector(selectProjectsState, (state) => {
    return state.projects;
});

export const selectProjectsLoading = createSelector(selectProjectsState, (state) => state.loading);
export const selectProjectsError = createSelector(selectProjectsState, (state) => state.error);

export const selectSelectedProject = createSelector(selectProjectsState, (state) => state.selectedProject);
