import { ITaskCustomField } from '../../interfaces';
import { TCustomFieldType } from '../../helpers';

export class TaskCustomFieldModel implements ITaskCustomField {
    private readonly _id: number;
    private readonly _name: string;
    private readonly _fieldType: TCustomFieldType;
    private readonly _value: string | number | boolean;
    private readonly _values?: { id: number; value: string }[];
    private readonly _mode?: string;

    constructor(data: { value: any }) {
        this._id = data['value']['id'] ?? null;
        this._name = data['value']['name'] ?? null;
        this._fieldType = data['value']['field_type'] ?? null;
        this._value = data['value']['value'] ?? null;
        this._values = data['value']['values'] ?? [];
        this._mode = data['value']['mode'] ?? [];
    }

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    get fieldType() {
        return this._fieldType;
    }

    get value() {
        return this._value;
    }

    get values() {
        return this._values;
    }

    get mode() {
        return this._mode;
    }
}
