<div class="files mb-2">
    @for (item of files; track $index) {
        <div class="img-wrapper"
             [ngClass]="{
                'video-preview': item.fileType === 'video',
                'files-preview': item.fileType !== 'video' && item.fileType !== 'photo' }"
        >
            @if (item.fileType === 'video' || item.fileType === 'photo') {
                <img [src]="item.thumbUrl ?? item.url" alt="">
                <button class="btn-preview {{item.fileType}}"
                        (click)="showMediaViewerModal($index, files)"></button>
            } @else {
                <img src="/assets/images/file-types/{{ item.fileType }}.svg" alt="">
                <a class="btn-preview {{item.fileType}}" [href]="item.url" target="_blank" download>
                    <i class='bx bxs-download'></i>
                </a>
            }
        </div>
    }
</div>
