import { IFile } from '../../interfaces';

export class TaskMediaModel implements IFile {
    private readonly _id: number;
    private readonly _url: string;
    private readonly _thumbUrl: string | null;
    private readonly _fileType: 'photo' | 'video';

    constructor(data: any) {
        this._id = data['id'] ?? null;
        this._url = data['filename_url'] ?? null;
        this._thumbUrl = data['thumb_url'] ?? null;
        this._fileType = data['file_type'] ?? null;
    }

    get id() {
        return this._id;
    }

    get url() {
        return this._url;
    }

    get thumbUrl() {
        return this._thumbUrl;
    }

    get fileType() {
        return this._fileType;
    }
}
