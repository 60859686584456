import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Signal,
  effect, signal
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { IBreadcrumb } from '../../interfaces';
import { NgClass } from '@angular/common';
import { NavigationService } from '../../services/navigation/navigation.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLink,
    NgClass
  ],
  standalone: true
})

/**
 * Bread Crumbs Component
 */
export class BreadcrumbsComponent implements OnInit {
  @Input() breadcrumbs: Signal<IBreadcrumb[] | null> = signal<IBreadcrumb[] | null>(null);

  public active: IBreadcrumb | undefined;
  public back: IBreadcrumb | undefined;

  constructor(
      public navigationService: NavigationService,
      private cd: ChangeDetectorRef,
  ) {
    effect(() => {
      if (this.breadcrumbs()) {
        this.breadcrumbs()!.forEach((item: IBreadcrumb) => {
          if (item.isActive) {
            this.active = item;
          }

          if (item.isBack) {
            this.back = item;
          }

          this.cd.detectChanges();
        })
      }
    });
  }

  ngOnInit(): void {

  }

}
