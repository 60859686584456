import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, Observable } from 'rxjs';
import { AppState, selectUserIsStaff } from '../store';
import { Utils } from '../helpers';

export const isStaffGuard = (): Observable<boolean> => {
    const store = inject(Store<AppState>);
    const router = inject(Router);

    return store.select(selectUserIsStaff)
        .pipe(
            filter((isStaff): isStaff is boolean => !Utils.isNil(isStaff)),
            map((isStaff: boolean) => {
                if (isStaff) {
                    return true;
                }

                router.navigate(['/']);

                return false;
            })
        );
};
