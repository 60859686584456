import {
    ChangeDetectionStrategy,
    Component, DestroyRef, inject,
    OnInit, signal,
} from '@angular/core';
import { NgClass, NgStyle } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ProjectsService, TaskService } from '../../../services';
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from '@angular/router';
import { combineLatestWith, debounceTime, filter, startWith, Subject, switchMap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { map } from 'rxjs/operators';
import { IBoard, IBoardAdditional, IDataList, IResult, ITaskCard, ITaskCardAdditional } from '../../../interfaces';
import { ClickOutsideDirective } from '../../../directives';
import { StripHtmlPipe } from '../../../pipe';
import { Utils } from '../../../helpers';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        FormsModule,
        ClickOutsideDirective,
        StripHtmlPipe,
        RouterLink,
        NgStyle,
    ],
})
export class SearchComponent implements OnInit {
    public loading = signal<boolean>(false);
    public results = signal<any[]>([]);
    public searchIn = signal<'project' | 'board' | null>(null);
    public showResults = signal<boolean>(false);
    public searchText = signal<string>('');
    public activeIndex = signal<number | null>(null);

    public searchParams: any = {};
    public currentPage = 1;
    public pageSize = 30;

    private onSubmitSearch$ = new Subject<string>();
    private debounceTime = 500;
    private destroy = inject(DestroyRef);

    constructor(
        private projectsService: ProjectsService,
        private taskService: TaskService,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                startWith(null),
                map(() => Utils.getChildRoute(this.route)),
                switchMap(childRoute =>
                    childRoute.data.pipe(
                        combineLatestWith(childRoute.paramMap, childRoute.queryParamMap)
                    )
                ),
                takeUntilDestroyed(this.destroy)
            )
            .subscribe(([data, paramMap, queryParams]) => {
                this.searchIn.set(data?.['searchIn'] ?? null);
                this.searchParams = {};

                if (paramMap.get('id') && this.searchIn()) {
                    this.searchParams[this.searchIn() + '_id'] = paramMap.get('id');
                }

                this.searchText.set(queryParams.get('q') ?? '')
            });

        this.onSubmitSearch$
            .pipe(
                debounceTime(this.debounceTime),
                takeUntilDestroyed(this.destroy)
            )
            .subscribe((searchTerm) => {
                if (searchTerm.length >= 3) {
                    this.performSearch(searchTerm, this.pageSize);
                }

                if (searchTerm.length === 0 && this.results().length > 0) {
                    this.clear();
                }
            });
    }

    public onKeyDown(event: KeyboardEvent) {
        if (this.results().length > 0 &&
            (['ArrowDown', 'ArrowUp'].includes(event.key) || (event.key === 'Enter' && this.activeIndex() !== null))
        ) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    public onKeyUp(event?: KeyboardEvent): void {
        if (event && this.results().length > 0) {
            if (event.key === 'ArrowDown') {
                this.activeIndex.set(((this.activeIndex() ?? -1) + 1) % this.results().length);
            } else if (event.key === 'ArrowUp') {
                this.activeIndex.set(((this.activeIndex() ?? 0) - 1 + this.results().length) % this.results().length);
            } else if (event.key === 'Enter' && this.activeIndex() !== null) {
                this.navigateToSearchItem();
            } else if (event.key === 'Escape') {
                this.clear();
            }
        }
    }

    public search(): void {
        this.onSubmitSearch$.next(this.searchText());
    }

    public clear(): void {
        this.searchText.set('');
        this.results.set([]);
        this.hideResults();
        this.removeQueryParam('q');
    }

    public onFocusSearch(): void {
        if (this.searchText().length >= 3 && !this.showResults()) {
           this.search();
        }
    }

    public navigateToSearchItem() {
        const item = this.results()[this.activeIndex()!];

        if (this.searchIn() === 'board') {
            this.router.navigate(['/task', item.id]);
        } else if (this.searchIn() === 'project') {
            this.router.navigate(['/board', item.id])
        }

        this.hideResults();
    }

    public hideResults(): void {
        this.showResults.set(false);
        this.activeIndex.set(null);
    }

    private handleSearchResults(result: IResult<IDataList<IBoard, IBoardAdditional>>): void {
        this.showResults.set(true);
        this.results.set(result.data.data);
    }

    private removeQueryParam(paramKey: string) {
        const currentParams = { ...this.route.snapshot.queryParams };

        delete currentParams[paramKey];

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: currentParams,
        });
    }

    private performSearch(searchTerm: string, pageSize: number): void {
        if (this.searchIn() === 'board') {

            this.taskService.searchTasks<IDataList<ITaskCard, ITaskCardAdditional>>(searchTerm, pageSize, this.searchParams)
                .pipe(takeUntilDestroyed(this.destroy))
                .subscribe((result: IResult) => {
                    if (result.isSuccess) {
                        this.handleSearchResults(result);
                    }
                });

        } else if (this.searchIn() === 'project') {

            this.projectsService.getProjectBoards<IDataList<IBoard, IBoardAdditional>>(
                this.searchParams['project_id'], this.currentPage, this.pageSize, searchTerm
            )
                .pipe(takeUntilDestroyed(this.destroy))
                .subscribe((result: IResult<IDataList<IBoard, IBoardAdditional>>) => {
                    if (result.isSuccess) {
                        this.handleSearchResults(result);
                    }
                });

        }
    }
}
