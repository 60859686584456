import { IKeyword, IKeywordAdditional, ISourceKeywordCategory } from '../../interfaces';

export class KeywordModel implements IKeyword {
    private readonly _id: number;
    private readonly _value: string;
    private readonly _onText: boolean;
    private readonly _onImage: boolean;
    private readonly _categoriesMap: { [key: number]: ISourceKeywordCategory } = {};
    private readonly _categoryIds: number[];
    private readonly _hitCount: number;
    private readonly _actionType: string;
    private readonly _date: string;

    constructor(data: { value: any, additional: IKeywordAdditional }) {
        this._id = data['value']['id'] ?? null;
        this._value = data['value']['value'] ?? null;
        this._onText = data['value']['on_text'] ?? null;
        this._onImage = data['value']['on_image'] ?? null;
        this._hitCount = data['value']['hit_count'] ?? null;
        this._actionType = data['value']['action_type'] ?? null;
        this._date = data['value']['date'] ?? null;
        this._categoryIds = data['value']['category_ids'] ?? null;

        data['value']['category_ids']?.forEach((categoryId: number) => {
            this._categoriesMap[categoryId] = data['additional']['categoriesMap'][categoryId];
        })
    }

    get id() {
        return this._id;
    }

    get value() {
        return this._value;
    }

    get onText() {
        return this._onText;
    }

    get onImage() {
        return this._onImage;
    }

    get hitCount() {
        return this._hitCount;
    }

    get actionType() {
        return this._actionType;
    }

    get categoriesMap() {
        return this._categoriesMap;
    }

    get categoryIds() {
        return this._categoryIds;
    }

    get date() {
        return this._date;
    }
}
