import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { IUser } from '../../interfaces';

export const loadUser = createAction('[User Component] Load User');
export const loadUserSuccess = createAction('[User Component] Load User Success', props<{ user: IUser }>());
export const loadUserFailure = createAction('[User Component] Load User Failure', props<{ error: HttpErrorResponse | null }>());

export const loadImpersonatedUser = createAction('[User Component] Load Impersonated User', props<{ id: number }>());
export const loadImpersonatedUserSuccess = createAction('[User Component] Load Impersonated User Success', props<{ impersonatedUser: IUser }>());
export const loadImpersonatedUserFailure = createAction('[User Component] Load Impersonated User Failure', props<{ error: HttpErrorResponse | null }>());
export const loadClearImpersonatedUser = createAction('[User Component] Load Clear Impersonated User');
