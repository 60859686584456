import { IProjectTag } from '../../interfaces';

export class ProjectTagModel implements IProjectTag {
    private readonly _id: number;
    private readonly _name: string;
    private readonly _projectId: number;

    constructor(data: { value: any }) {
        this._id = data['value']['id'] ?? null;
        this._name = data['value']['name'] ?? null;
        this._projectId = data['value']['project_id'] ?? null;
    }

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    get projectId() {
        return this._projectId;
    }
}
