import { Location } from '@angular/common';
import { inject } from '@angular/core';
import { Router } from '@angular/router';

let firstEntry = false;

export const authGuard = () => {
    const router = inject(Router);
    const location = inject(Location);
    const authToken = JSON.parse(<string>localStorage.getItem('authToken'));

    if (authToken && 'accessToken' in authToken) {
        if (firstEntry) {
            location.back();
            return false;
        }

        router.navigate(['/']);
        firstEntry = true;

        return false;
    }

    return true;
};
