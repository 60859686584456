import {
    ITaskCardTeam,
    ITaskPriority,
    IProjectAdditional,
    IProjectTag, IProjectOption, IProjectStatus,
} from '../../interfaces';
import { Utils } from '../../helpers';
import { ProjectTagModel } from './project-tag.model';
import { ProjectOptionModel } from './project-option.model';
import { ProjectStatusModel } from './project-status.model';

export class ProjectAdditionalModel implements IProjectAdditional {
    private readonly _tagsMap: { [key: number]: IProjectTag } = {};
    private readonly _prioritiesMap: { [key: number]: ITaskPriority } = {};
    private readonly _statusesMap: { [key: number]: IProjectStatus } = {};
    private readonly _teamsMap: { [key: number]: ITaskCardTeam } = {};
    private readonly _optionsMap: { [key: number]: IProjectOption } = {};

    constructor(data: any) {
        this._prioritiesMap = Utils.mapByField<ITaskPriority>(data['project']?.['priorities'], 'id');
        this._teamsMap = Utils.mapByField<ITaskCardTeam>(data['project']['teams'], 'id');
        this._tagsMap = Utils.mapByField<IProjectTag>(data['tags'], 'id', ProjectTagModel);
        this._optionsMap = Utils.mapByField<IProjectOption>(data['options'], 'id', ProjectOptionModel);
        this._statusesMap = Utils.mapByField<IProjectStatus>(data['project']['statuses'], 'id', ProjectStatusModel);
    }

    get tagsMap() {
        return this._tagsMap;
    }

    get optionsMap() {
        return this._optionsMap;
    }

    get prioritiesMap() {
        return this._prioritiesMap;
    }

    get statusesMap() {
        return this._statusesMap;
    }

    get teamsMap() {
        return this._teamsMap;
    }
}
