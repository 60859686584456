export type TMethod = 'POST' | 'GET' | 'PATCH' | 'PUT' | 'DELETE';
export type TUsersAction = 'block' | 'unblock' | 'delete';
export type TFileTypes = 'photo' | 'video' | 'document' | 'spreadsheet' | 'presentation' | 'archive' | 'audio' | 'unknown';
export type TTaskCardUpdateStatus = 'remove';
export type TNotificationType = 'mentioned' | 'debug' | 'critical' | 'success' | 'info' | 'error' | 'warning' | 'replied';
export type TUserStatus = 'is_blocked' | 'is_active' | 'is_non_active' | 'is_invited';
export type TUserLevel = 'is_admin' | 'is_user';
export type TCustomFieldType = 'text' | 'textarea' | 'select' | 'multiselect' | 'checkbox' | 'radio' | 'date' | 'number';
export type TBoardColor = '#FF3B30' | '#FF9500' | '#FFCC00' | '#34C759' | '#007AFF' | '#AF52DE' | '#A2845E' | '#8E8E93';
export type TBoardIcon = 'bxs-plane-alt' | 'bxs-binoculars' | 'bxs-hot' | 'bxs-bomb' | 'bxs-car' | 'bxs-leaf' | 'bxs-cube' | 'bx-current-location';
