import { IUserAdditional, IUserLevel, IUserStatus } from '../../interfaces';
import { TUserLevel, TUserStatus, Utils } from '../../helpers';
import { UserModel } from './user.model';

export class UserAdditionalModel implements IUserAdditional {
    private readonly _levels: IUserLevel[];
    private readonly _statuses: IUserStatus[];
    private readonly _levelsMap: { [key in TUserLevel]: IUserLevel };
    private readonly _statusesMap: { [key in TUserStatus]: IUserStatus };

    constructor() {
        this._levels = [
            { key: 'is_admin', value: 'Адміністратор' },
            { key: 'is_user', value: 'Користувач' },
        ];

        this._statuses = [
            { key: 'is_non_active', value: 'Неактивний' },
            { key: 'is_blocked', value: 'Заблокований' },
            { key: 'is_invited', value: 'Запрошений' },
            { key: 'is_active', value: 'Активний' }
        ];

        this._levelsMap = Utils.mapByField<IUserLevel>(this._levels, 'key') as { [key in TUserLevel]: IUserLevel };
        this._statusesMap = Utils.mapByField<IUserStatus>(this._statuses, 'key') as { [key in TUserStatus]: IUserStatus };
    }

    get levels() {
        return this._levels;
    }

    get statuses() {
        return this._statuses;
    }

    get levelsMap() {
        return this._levelsMap;
    }

    get statusesMap() {
        return this._statusesMap;
    }

    public getLevelKey(user: UserModel): TUserLevel {
        if (user.isAdmin) {
            return 'is_admin';
        } else {
            return 'is_user';
        }
    }

    public getStatusKey(user: UserModel): TUserStatus {
        if (!user.isBlocked && !user.isActive) {
            return 'is_non_active';
        } else if (user.isBlocked) {
            return 'is_blocked';
        } else if (user.isInvited) {
            return 'is_invited';
        } else {
            return 'is_active';
        }
    }
}
