import { Route } from '@angular/router';
import { check2faGuard, isAuthGuard } from './guards';
import { ErrorComponent } from './pages/error/error.component';
import { OnboardingComponent } from './pages/onboarding/onboarding.component';

export const APP_ROUTES: Route[] = [
    {
        path: '',
        canActivate: [isAuthGuard],
        loadChildren: () => import('./pages/pages.routes').then(r => r.routes),
    },
    {
        path: 'auth',
        loadChildren: () => import('./pages/auth/auth.routes').then(r => r.routes)
    },
    {
        path: 'password',
        loadChildren: () => import('./pages/password/password.routes').then(r => r.routes)
    },
    {
        path: 'onboarding',
        canActivate: [isAuthGuard, check2faGuard],
        component: OnboardingComponent,
    },
    {
        path: 'error',
        component: ErrorComponent,
    },

    {
        path: '**',
        redirectTo: '',
    },
];
