import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'buildVideoJson'
})
export class BuildVideoJsonPipe implements PipeTransform {

    transform(videoUrl: string): string {
        const config = {
            "downloadUrl": videoUrl,
            "source": [{"src": videoUrl, "type": "video/mp4"}],
            "attributes": {"preload": false, "controls": true}
        };

        return JSON.stringify(config);
    }
}
