import {
    IBoard,
    IBoardDetails,
    IProjectAdditional,
} from '../../interfaces';
import { ProjectAdditionalModel } from '../project/project-additional.model';
import { BoardModel } from './board.model';

export class BoardDetailsModel implements IBoardDetails {
    private readonly _details: IBoard;
    private readonly _projectId: number;
    private readonly _additional: IProjectAdditional;

    constructor(data: any) {
        this._details = new BoardModel({ value: data['details'], additional: { project: null } });
        this._projectId = data['project']?.['id'] ?? 0;
        this._additional = new ProjectAdditionalModel(data);
    }

    get details() {
        return this._details;
    }

    get projectId() {
        return this._projectId;
    }

    get additional() {
        return this._additional;
    }
}
