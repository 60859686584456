import { IKeywordAdditional, IKeywordFilter } from '../../interfaces';

export class KeywordFilterModel implements IKeywordFilter {
    private readonly _id: number;
    private readonly _name: string;
    private readonly _total: number;

    constructor(data: { value: any, additional: IKeywordAdditional }) {
        this._id = data['value']['id'] ?? null;
        this._name = data['value']['name'] ?? null;
        this._total = data['value']['word_count'] ?? null;
    }

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    get total() {
        return this._total;
    }
}
