import { ICustomField, ICustomFieldAdditional, ICustomFieldType, ICustomFieldValue } from '../../interfaces';

export class CustomFieldModel implements ICustomField {
    private readonly _id: number;
    private readonly _name: string;
    private readonly _fieldType: ICustomFieldType;
    private readonly _isActive: boolean;
    private readonly _values: ICustomFieldValue[];

    constructor(data: { value: any, additional: ICustomFieldAdditional }) {
        this._id = data['value']['id'] ?? null;
        this._name = data['value']['name'] ?? null;
        this._isActive = data['value']['is_active'] ?? null;
        this._values = data['value']['values'] ?? null;
        this._fieldType = data['additional']['fieldTypesMap'][data['value']['field_type']];
    }

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    get fieldType() {
        return this._fieldType;
    }

    get isActive() {
        return this._isActive;
    }

    get values() {
        return this._values;
    }
}
