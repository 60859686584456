import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component, DestroyRef, inject,
    Input,
    OnInit, signal, Signal,
} from '@angular/core';
import { IResult } from '../../interfaces';
import { NgClass, NgStyle } from '@angular/common';
import { RouterLink } from '@angular/router';
import { FormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { NgOtpInputModule } from 'ng-otp-input';
import { QRCodeModule } from 'angularx-qrcode';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppState, loadUser } from '../../store';
import { SettingsService, ToastsService } from '../../services';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-2fa',
    templateUrl: './2fa.component.html',
    styleUrls: ['./2fa.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        NgStyle,
        RouterLink,
        FormsModule,
        NgOtpInputModule,
        QRCodeModule,
    ],
})
export class TwoFaComponent implements OnInit {
    @Input() qrcodeUrl!: Signal<string>;

    public loadingBtn = signal<boolean>(false);

    public otpControl = new UntypedFormControl('', [Validators.required, Validators.minLength(6)]);
    public otpConfig = {
        allowNumbersOnly: true,
        length: 6,
        isPasswordInput: false,
        disableAutoFocus: false,
        placeholder: '',
        inputStyles: {
            'width': '40px',
            'height': '40px',
            'font-size': '16px'
        },
    };

    private store = inject(Store<AppState>);
    private destroy = inject(DestroyRef);

    constructor(
        private settingsService: SettingsService,
        private toastsService: ToastsService,
        private cd: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
    }

    public onOtpChange(event: any) {
        this.otpControl.setValue(event);
        this.cd.detectChanges();
    }

    public onSubmitConfirm2Fa() {
        if (this.otpControl.valid) {
            this.loadingBtn.set(true);

            this.settingsService.confirm2FaVerify(this.otpControl.value as string)
                .pipe(takeUntilDestroyed(this.destroy))
                .subscribe((result: IResult) => {
                    if (result.isSuccess) {
                        this.store.dispatch(loadUser());
                        this.toastsService.show(result.data.detail, { classname: 'bg-success text-light', delay: 3000 });
                    }

                    this.loadingBtn.set(false);
                });

            return;
        }

        this.toastsService.show('Введіть коректний код з Google Authenticator', { classname: 'bg-danger text-light', delay: 3000 });
    }

}
