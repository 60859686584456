<div class="sortable-container">
    @if (formArray && formArray.length > 0) {
        <div cdkDropList [cdkDropListData]="items()" (cdkDropListDropped)="drop($event)">
            @for (item of items(); track $index) {
                <div cdkDrag>
                    <div class="drag-sortable-item">
                        <button type="button" class="btn-transparent drag" cdkDragHandle [disabled]="item.id === 0">
                            <i class="las la-grip-lines"></i>
                        </button>
                        <input type="text" class="form-control" placeholder="Введіть назву"
                               [formControl]="formArray.controls[$index]"
                               (keydown.enter)="submitChange($index); removeFocus($event)"
                               (blur)="isFocusOutSubmit ? submitChange($index) : null"
                               [class.is-invalid]="formArray.controls[$index].touched && formArray.controls[$index].errors">
                        <button type="button" class="btn-transparent" [disabled]="items().length === 1"
                                (click)="!item.id ? removeItem($index) : onDelete.emit({ id: item.id })">
                            <i class='bx bx-trash-alt fs-20'></i>
                        </button>
                    </div>
                </div>
            }
        </div>
    }

    <button type="button" class="btn-transparent default ms-3" [disabled]="items()[items().length - 1].name === ''"
            (click)="addItem()">+ Додати</button>
</div>
