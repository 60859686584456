<div class="modal-header p-4 justify-content-between">
    <h6 class="modal-title">
        @if (data.actionType === 'block') {
            Заблокувати обраного користувача(ів)?
        } @else if (data.actionType === 'unblock') {
            Розблокувати обраного користувача(ів)?
        } @else {
            Видалити обраного користувача(ів)?
        }
    </h6>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span>&times;</span>
    </button>
</div>
<div class="modal-body pt-0 p-4">
    <div class="d-flex justify-content-end mt-3">
        <div>
            <button class="btn btn-settings transparent mx-3"
                    (click)="activeModal.dismiss('Cross click')"> Закрити
            </button>
        </div>
        <div>
            <button class="btn btn-settings" [ngClass]="{ 'loading': loadingBtn() }" (click)="changeUserAccess()">
                <i class="las la-ban la-lg"></i>&nbsp;
                @if (data.actionType === 'block') {
                    Так, заблокувати
                } @else if (data.actionType === 'unblock') {
                    Так, розблокувати
                } @else {
                    Так, видалити
                }
            </button>
        </div>
    </div>
</div>
