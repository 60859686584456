import { IBoardCustomFieldOption } from '../../interfaces';

export class BoardCustomFieldOptionModel implements IBoardCustomFieldOption {
    private readonly _id: number;
    private readonly _boardId: number;
    private readonly _projectOptionId: number;
    private readonly _name: string;
    private readonly _showInBoard: boolean;
    private readonly _showInTaskDetail: boolean;

    constructor(data: { value: any }) {
        this._id = data['value']['id'] ?? null;
        this._boardId = data['value']['board_id'] ?? null;
        this._projectOptionId = data['value']['option_id'] ?? null;
        this._name = data['value']['name'] ?? null;
        this._showInBoard = data['value']['show_in_board'] ?? null;
        this._showInTaskDetail = data['value']['show_in_task_detail'] ?? null;
    }

    get id() {
        return this._id;
    }

    get boardId() {
        return this._boardId;
    }

    get projectOptionId() {
        return this._projectOptionId;
    }

    get name() {
        return this._name;
    }

    get showInBoard() {
        return this._showInBoard;
    }

    get showInTaskDetail() {
        return this._showInTaskDetail;
    }
}
