import { IBoard, IBoardAdditional, IKeywordFilter, IProjectRole, ISourceKeywordCategory } from '../../interfaces';
import * as moment from 'moment';
import { TBoardColor, TBoardIcon } from '../../helpers';

export class BoardModel implements IBoard {
    private readonly _id: number;
    private readonly _name: string;
    private readonly _projectId: number;
    private readonly _teamId: number;
    private readonly _image: string;
    private readonly _isAuto: boolean;
    private readonly _isPublic: boolean;
    private readonly _isParser: boolean;
    private readonly _isRecycle: boolean;
    private readonly _defaultList: number;
    private readonly _hasParent: boolean;
    private readonly _lastUpdate: string | null = null;
    private readonly _roleId: number;
    private readonly _totalTasks: number;
    private readonly _completedTasks: number;
    private readonly _tasksProgress: number = 0;
    private readonly _iconClass: TBoardIcon;
    private readonly _iconColor: TBoardColor;
    private readonly _filters: IKeywordFilter[] = [];
    private readonly _sourceCategories: ISourceKeywordCategory[] = [];
    private readonly _userGroups: IProjectRole[] = [];
    private _isFavorite: boolean;

    constructor(data: { value: any, additional: IBoardAdditional }) {
        this._id = data['value']['id'] ?? null;
        this._name = data['value']['name'] ?? null;
        this._projectId = data['value']['project'] ?? null;
        this._teamId = data['value']['team'] ?? null;
        this._image = data['value']['image'] ?? null;
        this._isAuto = data['value']['is_auto'] ?? null;
        this._isPublic = data['value']['is_public'] ?? null;
        this._isParser = data['value']['is_parser'] ?? null;
        this._isRecycle = data['value']['is_recycle'] ?? null;
        this._isFavorite = data['value']['is_favorite'] ?? null;
        this._defaultList = data['value']['default_list'] ?? null;
        this._hasParent = data['value']['has_parent'] ?? null;
        this._roleId = data['value']['role_id'] ?? null;
        this._totalTasks = data['value']['total_tasks'] ?? null;
        this._completedTasks = data['value']['completed_tasks'] ?? null;
        this._filters = data['value']['filters'];
        this._sourceCategories = data['value']['source_categories'];
        this._userGroups = data['value']['user_groups'];
        this._iconClass = data['value']['icon_class'] ?? null;
        this._iconColor = data['value']['icon_color'] ?? null;

        if (this._completedTasks && this._totalTasks) {
            this._tasksProgress = (this._completedTasks / this._totalTasks) * 100;
        }

        if (data['value']['get_last_update']) {
            const now = moment();
            const lastUpdateDate = moment(data['value']['get_last_update']);
            const diffInHours = now.diff(lastUpdateDate, 'hours');

            this._lastUpdate = diffInHours < 24 ?
                `Оновлено ${diffInHours}г тому` :
                `Оновлено ${lastUpdateDate.format('DD.MM.YYYY, HH:mm')}`;
        }
    }

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    get projectId() {
        return this._projectId;
    }

    get teamId() {
        return this._teamId;
    }

    get image() {
        return this._image;
    }

    get isAuto() {
        return this._isAuto;
    }

    get isPublic() {
        return this._isPublic;
    }

    get isParser() {
        return this._isParser;
    }

    get isRecycle() {
        return this._isRecycle;
    }

    get isFavorite() {
        return this._isFavorite;
    }

    set isFavorite(value: boolean) {
        this._isFavorite = value;
    }

    get defaultList() {
        return this._defaultList;
    }

    get hasParent() {
        return this._hasParent;
    }

    get lastUpdate() {
        return this._lastUpdate;
    }

    get roleId() {
        return this._roleId;
    }

    get totalTasks() {
        return this._totalTasks;
    }

    get completedTasks() {
        return this._completedTasks;
    }

    get tasksProgress() {
        return this._tasksProgress;
    }

    get iconClass() {
        return this._iconClass;
    }

    get iconColor() {
        return this._iconColor;
    }

    get filters() {
        return this._filters;
    }

    get sourceCategories() {
        return this._sourceCategories;
    }

    get userGroups() {
        return this._userGroups;
    }
}
