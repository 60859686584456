import {
    ChangeDetectionStrategy,
    Component, DestroyRef, inject,
    OnInit, QueryList, signal, ViewChildren,
} from '@angular/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
    NgbDropdown, NgbDropdownMenu,
    NgbDropdownToggle,
    NgbNav,
    NgbNavContent,
    NgbNavItem,
    NgbNavLink, NgbNavOutlet
} from '@ng-bootstrap/ng-bootstrap';
import { ClickOutsideDirective, ScrollTrackerDirective, VisibleDirective } from '../../../directives';
import { SimplebarAngularModule } from 'simplebar-angular';
import { FormsModule } from '@angular/forms';
import { NotificationsService, WebsocketService } from '../../../services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IDataList, INotification, INotificationAdditional, IResult } from '../../../interfaces';
import { Observable, Subject, switchMap } from 'rxjs';
import { MarkdownSafeHtmlPipe } from '../../../pipe';
import { PreloaderComponent } from '../../preloader/preloader.component';
import { WSTAA } from '../../../websoket';
import { Router } from '@angular/router';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        NgbDropdown,
        ClickOutsideDirective,
        NgbNav,
        NgbNavItem,
        SimplebarAngularModule,
        NgbNavContent,
        NgbNavLink,
        NgbDropdownToggle,
        NgbDropdownMenu,
        FormsModule,
        NgbNavOutlet,
        MarkdownSafeHtmlPipe,
        ScrollTrackerDirective,
        PreloaderComponent,
        VisibleDirective,
        NgTemplateOutlet,
    ],
})
export class NotificationsComponent implements OnInit {
    @ViewChildren(NgbDropdown) dropdowns!: QueryList<NgbDropdown>;

    public allNotifications = signal<INotification[]>([]);
    public mentions = signal<INotification[]>([]);
    public unreviewedTotal = signal<number>(0);
    public total = signal<number>(0);
    public loading = signal<boolean>(false);

    public currentPage = 1;
    public pageSize = 10;
    public reviewedIds: number[] = [];

    private destroy = inject(DestroyRef);
    private getNotificationsTrigger$ = new Subject<boolean>();
    private wsGetNotifications$!: Observable<any>;

    constructor(
        private notificationsService: NotificationsService,
        private wsService: WebsocketService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.wsGetNotifications$ = this.wsService.on<any>(WSTAA.ON.NOTIFICATIONS);

        this.wsGetNotifications$
            .pipe(takeUntilDestroyed(this.destroy))
            .subscribe(() => {
                this.unreviewedTotal.update((total: number) => total + 1);
            });

        this.getNotificationsTrigger$
            .pipe(
                switchMap(() => {
                    this.loading.set(true);
                    return this.notificationsService.getNotifications<IDataList<INotification, INotificationAdditional>>(this.currentPage, this.pageSize)
                }),
                takeUntilDestroyed(this.destroy)
            )
            .subscribe((result: IResult<IDataList<INotification, INotificationAdditional>>) => {
                if (result.isSuccess) {
                    this.allNotifications.update((items: INotification[]) => [...items, ...result.data.data]);
                    this.mentions.update((items: INotification[]) =>
                        [...items, ...result.data.data.filter((item: INotification) => item.typeKey === 'mentioned')]
                    );
                    this.loading.set(false);

                    if (result.data.additional) {
                        this.unreviewedTotal.set(result.data.additional['unreviewedTotal']);
                        this.total.set(result.data.additional['totalCount']);
                    }
                }
            })

        this.getNotificationsTrigger$.next(true);
    }

    public onDropdownToggle(isOpen: boolean): void {
        if (isOpen) {
            this.getFirstPageNotifications();
        } else if (this.reviewedIds.length > 0) {
            this.updateNotification(this.reviewedIds);
        }
    }

    public closeAllDropdowns() {
        this.dropdowns.forEach((dropdown) => {
            if (dropdown.isOpen()) {
                dropdown.close();
            }
        });
    }

    public onScrolledToBottom(): void {
        if (!this.loading() && this.allNotifications().length < this.total()) {
            this.currentPage++;
            this.getNotificationsTrigger$.next(true);
        }
    }

    public saveVisibleId(notification: INotification): void {
        if (!notification.isReviewed && !this.reviewedIds.includes(notification.id)) {
            this.reviewedIds.push(notification.id);
        }
    }

    public navigateToNotification(fullUrl: string): void {
        const url = new URL(fullUrl);
        const path = url.pathname;
        const queryParams: { [key: string]: string } = {};

        url.searchParams.forEach((value, key) => {
            queryParams[key] = value;
        });

        this.router.navigate([path], { queryParams });

        this.closeAllDropdowns();
    }

    public getFirstPageNotifications(): void {
        this.currentPage = 1;
        this.allNotifications.set([]);
        this.mentions.set([]);
        this.getNotificationsTrigger$.next(true);
    }

    public updateNotification(ids: number[], reviewAll?: boolean): void {
        this.notificationsService.updateNotifications(ids, { reviewed: true }, reviewAll)
            .pipe(takeUntilDestroyed(this.destroy))
            .subscribe((result: IResult) => {
                if (result.isSuccess) {
                    this.reviewedIds = [];

                    if (reviewAll) {
                        this.getFirstPageNotifications();

                        return;
                    }

                    this.unreviewedTotal.update((total: number) => total - ids.length);
                }
            });
    }
}
