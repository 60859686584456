import { ICustomFieldAdditional, ICustomFieldType } from '../../interfaces';
import { DataListModel } from '../general/data-list.model';
import { CustomFieldTypeModel } from './custom-field-type.model';
import { Utils } from '../../helpers';

export class CustomFieldAdditionalModel implements ICustomFieldAdditional {
    private readonly _fieldTypes: ICustomFieldType[];
    private readonly _fieldTypesMap: { [key: string]: ICustomFieldType };

    constructor(data: any) {
        this._fieldTypes = new DataListModel<ICustomFieldType>({ results: data['field_types'] }, CustomFieldTypeModel).data;
        this._fieldTypesMap = Utils.mapByField<ICustomFieldType>(this._fieldTypes, 'key');
    }

    get fieldTypes() {
        return this._fieldTypes;
    }

    get fieldTypesMap() {
        return this._fieldTypesMap;
    }
}
