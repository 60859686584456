import { Directive, HostListener, Input } from '@angular/core';
import { ToastsService } from '../services';

@Directive({
    standalone: true,
    selector: '[copyToClipboard]'
})
export class CopyToClipboardDirective {
    @Input('copyToClipboard') textToCopy: string = '';

    constructor(private toastsService: ToastsService) {}

    @HostListener('click') onClick() {
        if (this.textToCopy) {
            navigator.clipboard.writeText(this.textToCopy).then(
                () => {
                    this.toastsService.show('Скопійовано', { classname: 'bg-secondary' });
                },
                (err) => {
                    console.error('Помилка копіювання:', err);
                }
            );
        }
    }
}
