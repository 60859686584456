import { Injectable, signal } from '@angular/core';
import { filter } from 'rxjs';
import { Router, RoutesRecognized } from '@angular/router';
import { map } from 'rxjs/operators';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Injectable({ providedIn: 'root' })

export class NavigationService {
  public previousUrl = signal<string | null>(null);

  constructor(
      private router: Router,
      private location: Location,
      private modalService: NgbModal
  ) {
    this.router.events
        .pipe(
            filter((event) => event instanceof RoutesRecognized),
            map((event) => (event as RoutesRecognized).urlAfterRedirects)
        )
        .subscribe((url: string) => {
          this.modalService.dismissAll();
          this.previousUrl.set(url);
        });
  }

  goBack(): void {
    if (this.previousUrl !== undefined) {
      this.location.back();
    } else {
      this.router.navigate(['/'], { replaceUrl: true });
    }
  }
}
