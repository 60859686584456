import { NgClass, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit, Output,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        TranslateModule,
        RouterLink,
        NgClass,
    ],
})
export class ConfirmModalComponent implements OnInit {
    @Input() data!: { title: string, isConfirm: boolean, content?: string, isIrreversibleAction?: boolean };

    @Output() public onConfirm: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        public activeModal: NgbActiveModal,
    ) { }

    ngOnInit(): void { }
}
