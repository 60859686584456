import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of } from 'rxjs';
import { loadProjects, loadProjectsFailure, loadProjectsSuccess } from './project.actions';
import { ProjectsService } from '../../services';
import { IDataList, IProject, IResult } from '../../interfaces';

@Injectable()
export class ProjectEffects {
    loadProjects$: any;

    constructor(private actions$: Actions, private projectsService: ProjectsService) {
        this.loadProjects$ = createEffect(() =>
            this.actions$.pipe(
                ofType(loadProjects),
                mergeMap(() =>
                    this.projectsService.getProjects<IDataList<IProject>>().pipe(
                        map((result: IResult<IDataList<IProject>>) => {
                            return loadProjectsSuccess({ projects: result.data })
                        }),
                        catchError((error) => of(loadProjectsFailure({ error: error.message })))
                    )
                )
            )
        );
    }
}
