import { ITaskCard, ITaskAdditional, ITaskDetails, IBreadcrumb } from '../../interfaces';
import { TaskCardModel } from './task-card.model';
import { TaskAdditionalModel } from './task-additional.model';
import { DataListModel } from '../general/data-list.model';
import { BreadcrumbModel } from '../general/breadcrumb.model';
import { TaskCardAdditionalModel } from './task-card-additional.model';

export class TaskDetailsModel implements ITaskDetails {
    private readonly _details: ITaskCard;
    private readonly _additional: ITaskAdditional;
    private readonly _breadcrumbs: IBreadcrumb[];

    constructor(data: any) {
        this._additional = new TaskAdditionalModel(data);
        this._details = new TaskCardModel({ value: data.details, additional: new TaskCardAdditionalModel(data) });
        this._breadcrumbs = new DataListModel<IBreadcrumb>({ results: data['breadcrumbs'] }, BreadcrumbModel).data;
    }

    get details() {
        return this._details;
    }

    get additional() {
        return this._additional;
    }

    get breadcrumbs() {
        return this._breadcrumbs;
    }
}
