import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
@Component({
    selector: 'app-preloader',
    templateUrl: './preloader.component.html',
    styleUrls: ['./preloader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
    ],
})
export class PreloaderComponent implements OnInit {
    @Input() public class?: string;

    constructor(
        private cd: ChangeDetectorRef,
    ) {
        cd.detach();
    }

    ngOnInit(): void {
        this.cd.detectChanges();
    }
}
