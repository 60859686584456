import { INotificationAdditional } from '../../interfaces';

export class NotificationAdditionalModel implements INotificationAdditional {
    private readonly _unreviewedTotal: number;
    private readonly _totalCount: number;

    constructor(data: any) {
        this._unreviewedTotal = data['unreviewed_count'] ?? 0;
        this._totalCount = data['total_count'] ?? 0;
    }

    get unreviewedTotal() {
        return this._unreviewedTotal;
    }

    get totalCount() {
        return this._totalCount;
    }
}
