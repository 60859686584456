import { NgClass, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    inject, OnDestroy,
    OnInit, signal,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { SettingsService } from '../../services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IResult, ITwoFaSetup } from '../../interfaces';
import { CreateProjectFormComponent, TwoFaComponent } from '../../shared';
import { AppState, selectUser, selectUserLoading } from '../../store';
import { Store } from '@ngrx/store';
import { combineLatest, filter, mergeMap, Observable, of } from 'rxjs';
import { Utils } from '../../helpers';
import { map } from 'rxjs/operators';
import { ResultModel } from '../../models';

@Component({
    selector: 'app-onboarding',
    templateUrl: './onboarding.component.html',
    styleUrls: ['./onboarding.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        TranslateModule,
        RouterLink,
        NgClass,
        CreateProjectFormComponent,
        TwoFaComponent,
    ],
})
export class OnboardingComponent implements OnInit, OnDestroy {
    public qrcodeUrl = signal<string>('');
    public step = signal<1 | 2>(1);

    private destroy = inject(DestroyRef);
    private store = inject(Store<AppState>);
    private selectUserLoading$: Observable<any> = this.store.select(selectUserLoading);
    private selectUser$: Observable<any> = this.store.select(selectUser);

    constructor(private settingsService: SettingsService,) { }
    ngOnInit(): void {
        combineLatest([
            this.selectUser$,
            this.selectUserLoading$,
        ])
            .pipe(
                filter(([user, loading]) => !Utils.isNil(user) && !loading),
                map(([user]) => ({ user })),
                mergeMap(({ user }) => {
                    if (!user.is2FaActive && user.is2FaRequired) {
                        return this.settingsService.get2FaSetup<ITwoFaSetup>();
                    }

                    this.step.set(2);

                    const result: IResult = new ResultModel(false);

                    return of(result);
                }),
                takeUntilDestroyed(this.destroy)
            )
            .subscribe((result: IResult<ITwoFaSetup>) => {
                if (result.isSuccess) {
                    this.qrcodeUrl.set(result.data.otpUri);
                }
            });
    }

    ngOnDestroy(): void {

    }
}
