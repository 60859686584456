import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthTokenService, } from 'src/app/services';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Injectable()
export class JWTInterceptor implements HttpInterceptor {
    constructor(
        private authTokenService: AuthTokenService,
        private cookieService: CookieService,
        private router: Router,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let authReq: HttpRequest<any>;

        const accessToken = this.authTokenService.accessToken;
        const csrfToken = this.cookieService.get('csrftoken');
        let headers: HttpHeaders = new HttpHeaders();

        if (request.url.includes('/i18n/')) {
            headers = headers.set('Cache-Control', 'no-cache, no-store, must-revalidate');
        } else {
            headers.set('Content-Type', 'application/json; charset=utf-8;')

            headers = headers.set('Authorization', `Bearer ${accessToken}`);
            headers = headers.set('X-CSRFToken', csrfToken);
        }

        authReq = request.clone({ headers });

        // send the newly created request
        return next.handle(authReq)
            .pipe(
                catchError(error => {
                    if (error.status === 0) {
                        return throwError(() => new Error(`${error.url} net::ERR_INTERNET_DISCONNECTED`));
                    }

                    if (error.status === 502) {
                        this.router.navigate(['/error']);
                    }

                    return throwError(() => error);
                })
            );
    }
}
