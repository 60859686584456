import { NgClass, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef, EventEmitter,
    inject, Input,
    OnInit, Output,
    signal
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ToastsService, UsersService } from '../../services';
import { IResult } from '../../interfaces';
import { TUsersAction } from '../../helpers';
import { isArray } from 'lodash';

@Component({
    selector: 'app-change-user-access-modal',
    templateUrl: './change-user-access-modal.component.html',
    styleUrls: ['./change-user-access-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        TranslateModule,
        RouterLink,
        NgClass,
    ],
})
export class ChangeUserAccessModalComponent implements OnInit {
    @Input() data!: { usersIds: number[] | number, actionType: TUsersAction };

    @Output() public onChangeUserAccess: EventEmitter<boolean> = new EventEmitter<boolean>();

    public loadingBtn = signal<boolean>(false);

    private destroy = inject(DestroyRef);

    constructor(
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private usersService: UsersService,
        private toastsService: ToastsService,
    ) { }

    ngOnInit(): void {
    }

    public changeUserAccess() {
        if(!this.data.usersIds) {
            return;
        }

        this.loadingBtn.set(true);

        if (isArray(this.data.usersIds)) {
            this.usersService.usersActions(this.data.usersIds as number[], this.data.actionType)
                .pipe(takeUntilDestroyed(this.destroy))
                .subscribe((result: IResult) => {
                    if (result.isSuccess) {
                        this.toastsService.show('Доступ для користувача(ів) змінено', {
                            classname: 'bg-success text-light',
                            delay: 3000
                        });
                        this.onChangeUserAccess.emit(true);

                        this.modalService.dismissAll();
                    }

                    this.loadingBtn.set(false);
                })
        } else {
            let requestData;

            if (this.data.actionType === 'block') {
                requestData = { isBlocked: true }
            } else if (this.data.actionType === 'unblock') {
                requestData = { isBlocked: false }
            } else {
                requestData = { isDeleted: true }
            }

            this.usersService.updateUser(this.data.usersIds as number, requestData)
                .pipe(takeUntilDestroyed(this.destroy))
                .subscribe((result: IResult) => {
                    if (result.isSuccess) {
                        this.toastsService.show('Доступ для користувача змінено', {
                            classname: 'bg-success text-light',
                            delay: 5000
                        });

                        this.onChangeUserAccess.emit(true);
                        this.modalService.dismissAll();
                    }

                    this.loadingBtn.set(false);
                })
        }
    }
}
