<div class="modal-header p-4 justify-content-between">
    <h6 class="modal-title">Ви точно хочете надіслати репорт про помилку ШІ?</h6>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span >&times;</span>
    </button>
</div>
<div class="modal-body pt-0 p-4">
    <div>
        <p>
            Подібні репорти дозволяють нам навчати алгоритм і робити його більше точним.
        </p>
        <p>
            Ви маєте бути впевнені, що ШІ зробив помилку, інакше репорт негативно відобразиться на роботі алгоритму.
        </p>
    </div>
    <div class="buttons mt-4">
        <div>
            <button class="btn btn-settings transparent" (click)="activeModal.dismiss('Close click')">
                Ні, відмінити
            </button>
        </div>
        <div>
            <button class="btn btn-settings" (click)="submitReport()"
                    [ngClass]="{ 'loading': loadingBtn() }">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19" viewBox="0 0 16 19" fill="none">
                    <path d="M0 18.5V13.5C0 12.95 0.195833 12.4792 0.5875 12.0875C0.979167 11.6958 1.45 11.5 2 11.5H14C14.55 11.5 15.0208 11.6958 15.4125 12.0875C15.8042 12.4792 16 12.95 16 13.5V18.5H0ZM5 10.5C3.61667 10.5 2.4375 10.0125 1.4625 9.0375C0.4875 8.0625 0 6.88333 0 5.5C0 4.11667 0.4875 2.9375 1.4625 1.9625C2.4375 0.9875 3.61667 0.5 5 0.5H11C12.3833 0.5 13.5625 0.9875 14.5375 1.9625C15.5125 2.9375 16 4.11667 16 5.5C16 6.88333 15.5125 8.0625 14.5375 9.0375C13.5625 10.0125 12.3833 10.5 11 10.5H5ZM2 16.5H14V13.5H2V16.5ZM5 8.5H11C11.8333 8.5 12.5417 8.20833 13.125 7.625C13.7083 7.04167 14 6.33333 14 5.5C14 4.66667 13.7083 3.95833 13.125 3.375C12.5417 2.79167 11.8333 2.5 11 2.5H5C4.16667 2.5 3.45833 2.79167 2.875 3.375C2.29167 3.95833 2 4.66667 2 5.5C2 6.33333 2.29167 7.04167 2.875 7.625C3.45833 8.20833 4.16667 8.5 5 8.5ZM5 6.5C5.28333 6.5 5.52083 6.40417 5.7125 6.2125C5.90417 6.02083 6 5.78333 6 5.5C6 5.21667 5.90417 4.97917 5.7125 4.7875C5.52083 4.59583 5.28333 4.5 5 4.5C4.71667 4.5 4.47917 4.59583 4.2875 4.7875C4.09583 4.97917 4 5.21667 4 5.5C4 5.78333 4.09583 6.02083 4.2875 6.2125C4.47917 6.40417 4.71667 6.5 5 6.5ZM11 6.5C11.2833 6.5 11.5208 6.40417 11.7125 6.2125C11.9042 6.02083 12 5.78333 12 5.5C12 5.21667 11.9042 4.97917 11.7125 4.7875C11.5208 4.59583 11.2833 4.5 11 4.5C10.7167 4.5 10.4792 4.59583 10.2875 4.7875C10.0958 4.97917 10 5.21667 10 5.5C10 5.78333 10.0958 6.02083 10.2875 6.2125C10.4792 6.40417 10.7167 6.5 11 6.5Z"
                          fill="#fff"/>
                </svg>Так, надіслати репорт
            </button>
        </div>
    </div>
</div>
