import { NgClass, NgIf, NgStyle } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    inject, Input,
    OnInit,
    signal
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import {
    NgbAccordionBody,
    NgbAccordionButton,
    NgbAccordionCollapse,
    NgbAccordionDirective, NgbAccordionHeader, NgbAccordionItem,
    NgbTooltip
} from '@ng-bootstrap/ng-bootstrap';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject, switchMap } from 'rxjs';
import { IDataList, IGeoAdditional, IGeoSuggestion } from '../../interfaces';
import { AppState, selectUserIsAdmin, selectUserIsStaff } from '../../store';
import { GeolocationService, ToastsService, WebsocketService } from '../../services';
import { WSTAA } from '../../websoket';
import { filter, map } from 'rxjs/operators';
import { Utils } from '../../helpers';
import { FilesPreviewComponent } from '../files-preview/files-preview.component';

@Component({
    selector: 'app-suggestions',
    templateUrl: './suggestions.component.html',
    styleUrls: ['./suggestions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        TranslateModule,
        RouterLink,
        NgClass,
        NgbAccordionBody,
        NgbAccordionButton,
        NgbAccordionCollapse,
        NgbAccordionDirective,
        NgbAccordionHeader,
        NgbAccordionItem,
        NgbTooltip,
        NgStyle,
        FilesPreviewComponent,
    ],
})
export class SuggestionsComponent implements OnInit {
    @Input() taskId!: number;
    @Input() locationId: number | undefined;

    public suggestions = signal<IGeoSuggestion[]>([]);
    public userIsAdmin = signal<boolean>(false);

    public pageSize: number = 100;
    public currentPage: number = 1;

    private wsGetTaskUpdates$!: Observable<any>;
    private destroy = inject(DestroyRef);
    private store = inject(Store<AppState>);
    private selectUserIsAdmin$: Observable<boolean | undefined> = this.store.select(selectUserIsAdmin);
    private updateSuggestionsTrigger$ = new Subject<void>();

    constructor(
        private wsService: WebsocketService,
        private toastsService: ToastsService,
        private geolocationService: GeolocationService,
    ) { }

    ngOnInit(): void {
        this.wsGetTaskUpdates$ = this.wsService.on<any>(WSTAA.ON.GETTASKUPDATES);

        this.wsGetTaskUpdates$
            .pipe(takeUntilDestroyed(this.destroy))
            .subscribe((data: any) => {
                if (data.task_id && data.type === 'suggestions') {
                    this.toastsService.show('Список результатів оновлено', { classname: 'bg-success text-light', delay: 3000 });
                    this.updateSuggestionsTrigger$.next();
                }
            });

        combineLatest([
            this.updateSuggestionsTrigger$.pipe(
                switchMap(() => this.geolocationService.getSuggestions<IDataList<IGeoSuggestion, IGeoAdditional>>(this.currentPage, this.pageSize, '', this.taskId))
            ),
            this.selectUserIsAdmin$
        ])
            .pipe(
                filter(([result, isAdmin]) => !Utils.isNil(isAdmin)),
                map(([result, isAdmin]) => ({ result, isAdmin })),
                takeUntilDestroyed(this.destroy)
            )
            .subscribe(({ result, isAdmin }) => {
                if (result.isSuccess) {
                    this.suggestions.set([...result.data.data]);

                    if (this.locationId) {
                        this.suggestions.update((array) => {
                            const index = array.findIndex((item: IGeoSuggestion) => item.id === this.locationId);

                            const [item] = array.splice(index, 1);
                            return [item, ...array];
                        });
                    }
                }

                this.userIsAdmin.set(Boolean(isAdmin));
            });

        this.updateSuggestionsTrigger$.next();
    }
}
